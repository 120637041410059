<template>
  <div class="fixed inset-0 bg-gradient-to-br from-blue-500 to-purple-600 overflow-hidden">
    <div class="h-full w-full flex flex-col">
      <div class="flex-grow overflow-y-auto p-4 md:p-6 lg:p-8">
        <h2 class="text-2xl font-bold text-white mb-6">Ingredients</h2>
        <div class="bg-white rounded-lg shadow-lg p-4 mb-6">
          <div class="relative">
            <input 
              v-model="searchQuery" 
              @input="searchIngredients" 
              placeholder="Search ingredients..." 
              class="w-full pl-10 pr-4 py-2 border rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
            <svg class="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" width="20" height="20" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M21 21l-6-6m2-5a7 7 0 11-14 0 7 7 0 0114 0z"></path>
            </svg>
          </div>
        </div>
        <div class="space-y-6">
          <div v-for="(category, categoryId) in categorizedIngredients" :key="categoryId" class="bg-white rounded-lg shadow-lg overflow-hidden">
            <h3 class="text-lg font-semibold bg-blue-100 text-blue-800 p-4">Category {{ categoryId }}</h3>
            <div class="divide-y divide-gray-200">
              <details v-for="(subcategory, subcategoryName) in category" :key="subcategoryName" class="group">
                <summary class="flex items-center justify-between p-4 cursor-pointer bg-gray-50 hover:bg-gray-100 transition-colors">
                  <span class="font-medium text-gray-700">{{ subcategoryName }}</span>
                  <span class="transform group-open:rotate-180 transition-transform">
                    <svg class="w-5 h-5 text-gray-500" fill="currentColor" viewBox="0 0 20 20">
                      <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
                    </svg>
                  </span>
                </summary>
                <ul class="bg-white">
                  <li 
                    v-for="ingredient in subcategory" 
                    :key="ingredient.id"
                    @click="selectIngredient(ingredient)"
                    class="p-4 cursor-pointer hover:bg-blue-50 transition-colors"
                  >
                    {{ ingredient.name }}
                  </li>
                </ul>
              </details>
            </div>
          </div>
        </div>
      </div>
      <div class="sticky bottom-0 w-full bg-white shadow-md p-4">
        <button 
          @click="$emit('close')" 
          class="w-full bg-red-500 hover:bg-red-600 text-white font-semibold py-3 rounded-lg transition-colors"
        >
          Close
        </button>
      </div>
    </div>

    <div v-if="selectedIngredient" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
      <div class="bg-white rounded-lg shadow-xl w-full max-w-2xl max-h-[90vh] overflow-y-auto">
        <div class="p-6">
          <div class="flex justify-between items-center mb-4">
            <h3 class="text-xl font-semibold">{{ selectedIngredient.name }}</h3>
            <button 
              @click="selectedIngredient = null"
              class="text-gray-500 hover:text-gray-700 focus:outline-none"
            >
              <svg class="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path>
              </svg>
            </button>
          </div>
          <IngredientDetail 
            :ingredient="selectedIngredient"
            :diets="diets"
            :measurements="measurements"
            @close="selectedIngredient = null"
            @update="updateIngredient"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, computed } from 'vue';
import IngredientDetail from './IngredientDetail.vue';

const diets = [
  { id: 1, name: 'Keto' },
  { id: 2, name: 'Vegetarian' },
  { id: 3, name: 'Vegan' },
  { id: 4, name: 'Paleo' },
  { id: 5, name: 'Atkins' },
  { id: 6, name: 'Gluten-Free' },
  { id: 7, name: 'Dairy-Free' },
];
const measurements = [
  { id: 1, name: 'Grams' },
  { id: 3, name: 'Kilograms' },
  { id: 4, name: 'Litres' },
  { id: 5, name: 'Items' },
  { id: 6, name: 'Gram' },
  { id: 7, name: 'Teaspoon' },
  { id: 8, name: 'Tablespoon' },
  { id: 9, name: 'Pinch' },
  { id: 19, name: 'Milliliters' },
  { id: 20, name: "Clove"}
];

const props = defineProps({
  ingredients: Array,
});

const ingredients = ref(props.ingredients);
const searchQuery = ref('');
const selectedIngredient = ref(null);

const categorizedIngredients = computed(() => {
  const categorized = {};
  ingredients.value.forEach(ingredient => {
    if (!categorized[ingredient.category]) {
      categorized[ingredient.category] = {};
    }
    if (!categorized[ingredient.category][ingredient.subcategory.name]) {
      categorized[ingredient.category][ingredient.subcategory.name] = [];
    }
    categorized[ingredient.category][ingredient.subcategory.name].push(ingredient);
  });
  return categorized;
});

const filteredCategorizedIngredients = computed(() => {
  const filtered = {};
  Object.entries(categorizedIngredients.value).forEach(([categoryId, category]) => {
    filtered[categoryId] = {};
    Object.entries(category).forEach(([subcategoryId, subcategory]) => {
      const filteredSubcategory = subcategory.filter(ingredient =>
        ingredient.name.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
      if (filteredSubcategory.length > 0) {
        filtered[categoryId][subcategoryId] = filteredSubcategory;
      }
    });
    if (Object.keys(filtered[categoryId]).length === 0) {
      delete filtered[categoryId];
    }
  });
  return filtered;
});

const searchIngredients = () => {
  // The filtering is now handled by the filteredCategorizedIngredients computed property
};

const selectIngredient = (ingredient) => {
  selectedIngredient.value = ingredient;
};

const updateIngredient = (updatedIngredient) => {
  const index = ingredients.value.findIndex(i => i.id === updatedIngredient.id);
  if (index !== -1) {
    ingredients.value[index] = updatedIngredient;
  }
  selectedIngredient.value = null;
};

onMounted(() => {
  ingredients.value.sort((a, b) => a.name.localeCompare(b.name));
});
</script>