<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-50">
      <div class="w-full max-w-md mx-auto p-5">
        <h1 class="text-3xl mb-6 text-p-t text-center">Reset Password</h1>
        <form v-if="!resetComplete" class="space-y-6" @submit.prevent="resetPassword">
          <div>
            <label for="new_password" class="block text-sm font-medium text-gray-700">New Password</label>
            <input
              class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-interactive focus:border-interactive"
              id="new_password"
              type="password"
              v-model="newPassword"
              required
            />
          </div>
          <div>
            <label for="confirm_password" class="block text-sm font-medium text-gray-700">Confirm New Password</label>
            <input
              class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-interactive focus:border-interactive"
              id="confirm_password"
              type="password"
              v-model="confirmPassword"
              required
            />
          </div>
          <div>
            <button 
              type="submit" 
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-interactive hover:bg-interactive-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-interactive"
            >
              Reset Password
            </button>
          </div>
          <p v-if="error" class="mt-2 text-sm text-red-600 text-center">{{ error }}</p>
        </form>
        <div v-else class="text-center">
          <p class="text-green-600">Your password has been reset successfully.</p>
          <router-link to="/login" class="mt-4 inline-block text-interactive hover:text-interactive-hover">Go to Login</router-link>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  axios.defaults.baseURL = 'https://mealmatcher.co.uk';
  export default {
    data() {
      return {
        newPassword: '',
        confirmPassword: '',
        error: '',
        resetComplete: false
      };
    },
    methods: {
      async resetPassword() {
        if (this.newPassword !== this.confirmPassword) {
          this.error = 'Passwords do not match.';
          return;
        }
  
        try {
          await axios.post('https://mealmatcher.co.uk/api/reset-password/', {
            uid: this.$route.params.uid,
            token: this.$route.params.token,
            new_password: this.newPassword,
          });
          this.resetComplete = true;
          this.error = '';
        } catch (error) {
          console.error('Error resetting password:', error);
          this.error = 'Failed to reset password. Please try again.';
        }
      }
    }
  }
  </script>
  