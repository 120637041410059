import '/src/assets/main.css';
import { createApp } from 'vue';
import App from '/src/App.vue';
import axios from 'axios';
import router from '/src/router.js';
import store from '/src/store.js';
import VueGtag from 'vue-gtag'
// Axios default config
axios.defaults.baseURL = import.meta.env.VITE_APP_API_BASE_URL; // Use Vite's import.meta.env
const apiUrl = import.meta.env.VITE_API_URL
// List of routes that don't require authentication
const publicRoutes = ['/api/signup/', '/api/login/'];

axios.interceptors.request.use(
    config => {
        if (!publicRoutes.includes(config.url)) {
            const token = localStorage.getItem('access_token');
            if (token) {
                config.headers['Authorization'] = `Bearer ${token}`;
            }
        }
        return config;
    },
    error => Promise.reject(error)
);

axios.interceptors.response.use(
    response => response,
    async error => {
        const originalRequest = error.config;

        if (error.response.status === 401) {
            originalRequest._retry = true;

            try {
                const refreshToken = localStorage.getItem('refresh_token');
                const res = await axios.post('/api/login/refresh/', { refresh: refreshToken });

                if (res.status === 200) {
                    localStorage.setItem('access_token', res.data.access);
                    store.dispatch('refresh', res.data.access);
                    originalRequest.headers['Authorization'] = `Bearer ${res.data.access}`;
                    return axios(originalRequest);
                  } else {
                    // If refresh failed, logout the user
                    store.dispatch('logout');
                    router.push('/login');
                    return Promise.reject(error);
                  }
            } catch (refreshError) {
                console.error('Error refreshing token:', refreshError);
                store.dispatch('logout');
                localStorage.removeItem('user');
                localStorage.removeItem('access_token');
                localStorage.removeItem('refresh_token');
                router.push('/login');
            }
        }

        return Promise.reject(error);
    }
);

async function init() {
    await store.dispatch('initializeStore'); // Ensures auth state is ready
    
    const app = createApp(App);
    
    app.use(router);
    app.use(store);
    
    // Add Google Analytics
    app.use(VueGtag, {
        config: { id: "G-1GCN7EYYN2" }  // Replace with your Google Analytics Measurement ID
    }, router);

    app.mount('#app');
}

init();
