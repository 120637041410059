<template>
  <div class="min-h-screen flex items-center justify-center bg-[#f6fbfe]">
    <div class="w-full max-w-3xl mx-auto p-5">
      <h1 class="text-3xl mb-6 text-p-t text-center">Select Your Plan</h1>
      <div v-if="stripeInfo">
        <stripe-pricing-table 
          :pricing-table-id="pricingTableId"
          :publishable-key="publishableKey"
          :customer-email="stripeInfo.email">
        </stripe-pricing-table>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';
import { useRouter } from 'vue-router';
export default {
  name: 'PricingPage',
  setup() {
    const router = useRouter(); // Use this if using Vue 3 with Composition API
    return { router };
  },
  data() {
    return {
      pricingTableId: 'prctbl_1PsoINRoNo8YhqoJjYMc6zsj',
      publishableKey: 'pk_live_51PfOsBRoNo8YhqoJo3v3DHsFiwhUXjjIY7acUz6eZrpetHmp4mAgVDOqahaDN6PkS3pYunVhoSsNKYYkLoN3pVXm009b8d5iq0',
      stripeInfo: null
    }
  },
  mounted() {
    const script = document.createElement('script');
    script.src = "https://js.stripe.com/v3/pricing-table.js";
    script.async = true;
    document.head.appendChild(script);

    this.getStripeInfo();
    this.checkPaymentStatus(); 
  },
  methods: {
    async getStripeInfo() {
      try {
        const response = await axios.get('/api/get-stripe-info/');
        this.stripeInfo = response.data;
      } catch (error) {
        console.error('Error fetching Stripe info:', error);
      }
    },
    checkPaymentStatus() {
      const urlParams = new URLSearchParams(window.location.search);
      const sessionId = urlParams.get('session_id');
      
      if (sessionId) {
        this.updateUserState();
      }
    },
  }
};
</script>