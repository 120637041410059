<template>
    <!-- Background Image -->
    <div class=" min-h-screen w-full  bg-gradient-to-br from-blue-100 to-purple-100">
      
      <!--<div class="relative">
        <img src="../assets/fruits/kiwi1.png" alt="Decorative Image" class="absolute bottom-16 -right-24 blur-[2px] w-1/6 h-auto z-10 opacity-80">
      </div>-->
      <div class=" flex flex-col  min-h-screen font-main">
        <!-- Progress bar -->
        <div v-if="authenticated && currentStep > 0 && currentStep <= 7" class="w-full h-2">
          <div :style="{ width: progressBarWidth }" class="bg-interactive h-full transition-all duration-300 ease-out"></div>
        </div>
    <div class="flex-grow w-full flex justify-center">
        <div v-if="!authenticated" class="w-full flex justify-center items-center">
          <div class="bg-gray-100 p-10 rounded-lg shadow-lg">
            <h1 class="text-3xl mb-4">Welcome to the Meal Planner</h1>
            <p class="mb-4">To get started please log in or sign up</p>
            <div class="flex justify-between">
              <router-link to="/login" class="bg-s-t text-white rounded px-4 py-2 mr-2">Sign In</router-link>
              <router-link to="/signup" class="bg-interactive text-white rounded px-4 py-2 mr-2">Sign Up</router-link>
            </div>
          </div>
        </div>
        <div v-else-if="loading" class="w-full flex flex-col items-center">
          <div class="w-full flex justify-center items-center">
            <ProgressCircle />
          </div>
        </div>
        <div v-else-if="showRecipe !== null" class="w-full max-w-screen-2xl">
          <RecipeModel :recipe="selectedRecipe" 
        :position="showRecipe" 
        :portions="4" 
        @close="closeRecipe" 
        @update:recipe="updateRecipe"  />
        </div>
        <div v-else class="w-full flex flex-col items-center">
          <div :class="[currentStep <= 5 && currentStep!=0 ? 'w-full 2xl:w-2/3 md:w-4/5 lg:h-2/3 md:h-4/5' : 'w-full']">

            
            <div v-if="currentStep === 0" >
              <Dashmain @next-step="nextStep"
              :hasPendingPlan="hasPendingPlan"
              :mealPlan="activeMealPlan"
              :hasOverview="hasActiveOverview" 
              :hasMealplan="hasActiveMealPlan"
              :is-premium-user="isPremiumUser"
              :allIngredients="allIngredients" 
              :pantryItems="pantryItems"
              :perishableItems="perishableItems"
              :freezerItems="freezerItems"
              @close="goHome"
              @next-step-simple="nextStepSimple"
              @next-step-ingredients="nextStepIngre" 
              @next-step-cheap="startCheap"
              @view-recipe="viewRecipe"
              @clear:list="clearList" 
              @add:item="addItem"
              @remove:item="removeItem" />
            </div>
            <div v-else-if="currentStep === 1">
              <DietSelection :selected-diet="formData.diet" @update:diet="updateDiet" @next-step="nextStep" @previous-step="previousStep"  />
            </div>
            <div v-else-if="currentStep === 10">
              <AllergenSelection :selectedAllergens="formData.allergens" @update:allergens="updateAllergens" @next-step="nextStep" @previous-step="previousStep"  />
            </div>
            <div v-else-if="currentStep === 2">
              <StoreSelection :selectedStore="formData.stores" @update:selectedStores="updateStores" @next-step="nextStep" @previous-step="previousStep"  />
            </div>
            <div v-else-if="currentStep === 3">
              <CalorieGoals :formData="formData" :is-premium-user="isPremiumUser" @update:formData="updateFormData" @next-step="nextStep" @previous-step="previousStep"  />
            </div>
            <div v-else-if="currentStep === 4">
              <InitialSetup :budget="formData.budget" :numDays="formData.num_days"   :repeat="formData.repeat" :portions="formData.portions"  @update:initial="updateInitial"  @next-step="nextStep" @previous-step="previousStep"  />
            </div>
            <div v-else-if="currentStep === 4.5">
              <initialSetupSimple :budget="formData.budget" :numDays="formData.num_days"   :repeat="formData.repeat" :portions="formData.portions" @update:initial="updateInitial"  @next-step="nextStep" @previous-step="previousStep"  />
            </div>
            <div v-else-if="currentStep === 5">
              <AverageDay  :averageDay="formData.initial_structure" :is-premium-user="isPremiumUser" :totalDailyCalories="formData.calorie_goal" @update:average="updateAverageDay"  @next-step="nextStep" @previous-step="previousStep"  />
            </div>
            <div v-else-if="currentStep === 6">
              <WeeklyMealPlan :formData="formData" :is-premium-user="isPremiumUser" :totalDailyCalories="formData.calorie_goal" @finish="searchMeals" @previous-step="previousStep"  />
            </div>
            <div v-else-if="currentStep === 20">
              <IngredientList  
              :selectedStores="selectedStores" 
              :selectedIngredients="selectedIngredients" 
              :allIngredients="allIngredients" 
              @clear:list="clearList" 
              @add:item="addItem" 
              @remove:item="removeItem"  />
            </div>
            
            <div v-else-if="currentStep === 30">
              <MealPlanOverview :formData="formData" :is-premium-user="isPremiumUser" :totalDailyCalories="formData.calorie_goal" @finish="searchMeals" @previous-step="previousStep"  />
            </div>
            <div v-else-if="currentStep === 40">
              <div v-if="hasPendingPlan" class="w-full flex flex-col items-center">
                <div class="w-full flex justify-center items-center mb-4">
                  <ProgressCircle />
                </div>
                <h3 class="text-gray-600 text-center font-semibold text-md pt-4">We're still crafting your tailored plan, hang tight!</h3>
                <p class="text-gray-700 text-center font-medium px-4">
                  This usually takes about 3-5 minutes. We'll update automatically once your plan is ready.
                </p>
              </div>
              <div v-else-if="hasActiveOverview" class="w-full flex flex-col h-full items-center md:p-8">
                <MealPlanOverview :overview="activeOverview" @select-meal-plan="viewFullMealPlan" @search-again="redoSearch" @cancel-plan="cancelPlan" />
              </div>
              <div v-else-if="hasActiveMealPlan" class="w-full flex flex-col h-full items-center ">
                <Dashboard :mealPlan="activeMealPlan" @open-modal="viewRecipe" @close="goHome" @update:recipe="updateRecipe"/>
              </div>
            </div>
            <div v-else-if="currentStep === 50" class= " container mx-auto px-4 py-8 max-w-5xl">
              <DietSelection :selected-diet="formData.diet" @update:diet="updateDiet" @next-step="nextStepCheap" @previous-step="previousStep"  />
            </div>
            <div v-else-if="currentStep === 51" class= " container mx-auto px-4 py-8 max-w-5xl">
              <StoreSelection :selectedStore="formData.stores" @update:selectedStores="updateStores" @next-step="nextStepCheap" @previous-step="previousStep"  />
            </div>
            <div v-else-if="currentStep === 52" class= "container mx-auto px-4 py-8 max-w-5xl">
              <CheapMealSettings 
                :mealtypes="mealTypes" 
                :pantry="pantryToggle" 
                :allIngredients="allIngredients"
                @toggle:pantry="togglePantry" 
                @update:meal="updateMealtypes" 
                @update:ingredient="updateIngredient"
                @update:portions="updatePortions"
                @finish="searchCheap" 
                @previous-step="previousStep"
              />
              
            </div>
            <div v-else-if="currentStep === 53">
              <div class="w-full flex justify-center items-center">
                <ProgressCircle />
                
               </div>
               <h3 class="text-gray-600 text-center font-semibold text-md pt-4">Just crunching the numbers</h3>
                <p class="text-gray-700 text-center font-medium px-4">
                  Should not take much more than 30 seconds. We'll update automatically once your plan is ready.
                </p>
            </div>
            <div v-else-if="currentStep === 54" class= "container mx-auto px-4 py-8 max-w-5xl">
              <CostEffectiveRecipes
                :recipes="cheapResults"
                @back="cheapResults = [], currentStep = 50"
              />
            </div>
          </div>
        </div>
        </div>
      </div>
    </div>
    </template>
    
<script setup>
import { ref, computed, onMounted, onUnmounted ,defineAsyncComponent  } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
const DietSelection = defineAsyncComponent(() => import('./Meal-plan-components/diet.vue'));
const CalorieGoals = defineAsyncComponent(() => import('./Meal-plan-components/macros.vue'));
const AllergenSelection = defineAsyncComponent(() => import('./Meal-plan-components/allergen.vue'));
const WeeklyMealPlan = defineAsyncComponent(() => import('./Meal-plan-components/choose-schedule.vue'));
const MealPlanOverview = defineAsyncComponent(() => import('./Meal-plan-components/overview.vue'));
const ProgressCircle = defineAsyncComponent(() => import('./Meal-plan-components/progress-circle.vue'));
const Dashboard = defineAsyncComponent(() => import('./Meal-plan-components/dashboard.vue'));
const Dashmain = defineAsyncComponent(() => import('./Meal-plan-components/empty-dash.vue'));
const StoreSelection = defineAsyncComponent(() => import('./Meal-plan-components/store.vue'));
const AverageDay = defineAsyncComponent(() => import('./Meal-plan-components/averageDay.vue'));
const InitialSetup = defineAsyncComponent(() => import('./Meal-plan-components/initialSetup.vue'));
const initialSetupSimple = defineAsyncComponent(() => import('./Meal-plan-components/initialSetupSimple.vue'));
const IngredientList = defineAsyncComponent(() => import('./Ingredient_list_components/ingredients_list.vue'));
const CheapMealSettings = defineAsyncComponent(() => import('./cheap_meal/CheapMealSettings.vue'));
const RecipeModel = defineAsyncComponent(() => import('./Recipes-components/RecipeModelDash.vue'));
import CostEffectiveRecipes from './cheap_meal/CostEffectiveRecipes.vue';

import { useRouter } from 'vue-router';
const router = useRouter();
const store = useStore();
const authenticated = computed(() => store.state.authenticated);
const isPremiumUser = computed(() => store.getters.isPremiumUser)
const isSubscribed = computed(() => store.getters.isSubscribed)
const simple = ref(true)
const selectedStores = ref(['Tesco']);
const selectedIngredients = ref([{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }])
const pantryItems = ref([{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }]);
const perishableItems = ref([{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }]);  
const freezerItems = ref([{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }]);  
const allIngredients = ref([]);
const hasActiveMealPlan = ref(false);
const activeMealPlan = ref(null);
const hasActiveOverview = ref(false);
const activeOverview = ref(null);
const hasPendingPlan = ref(false);
const specific_ingredient = ref('');
const loading = ref(true);
const mealImages = ref([]);
const mealTypes = ref([{ name: 'Breakfast', id: '1', selected: true }, { name: 'Lunch', id: '2', selected: true }, { name: 'Dinner', id: '3', selected: true }, { name: 'Snack', id: '4', selected: false }]);
const currentStep = ref(0);
const progressBarWidth = computed(() => `${(currentStep.value / 6) * 100}%`);
const pantryToggle = ref(true);
const portionsSize = ref(1);
const cheapResults = ref([]);
const showRecipe = ref(null);
const selectedRecipe = ref(null);
const viewRecipe = (recipe, pos) => {
  selectedRecipe.value = recipe;
  console.log('Selected Recipe:', recipe);
  showRecipe.value = pos;
};
const closeRecipe = () => {
  showRecipe.value = null;
  selectedRecipe.value = null;
};
if (!isSubscribed.value) {
  router.push('/select-plan')
}
const goHome = () => {
  currentStep.value = 0;
};
const updateRecipe = (updatedRecipe, position) => {
  console.log('Updated Recipe:', updatedRecipe);
  // Update the local state
  selectedRecipe.value = updatedRecipe;
  activeMealPlan.value.meal_plan[position[0]][position[1]] = updatedRecipe;
};
const formData = ref({
  diet: { id: 1, name: 'Standard', icon: 'fas fa-utensils' },
  calorie_goal: '2000',
  protein_goal: null,
  carb_goal: null,
  fat_goal: null,
  efficiency_goal: '10',
  allergens: [],
  stores: ["Tesco", "Aldi"],
  budget: 40,
  num_days: 4,
  repeat: "never",
  portions: 1,
  initial_structure: [
    { name: 'Breakfast', id: '1', calories: null, cuisine: 'random', showCalories: false, estimated_calories: 500},
    { name: 'Lunch', id: '2', calories: null, cuisine: 'random', showCalories: false, estimated_calories: 500 },
    { name: 'Dinner', id: '3', calories: null, cuisine: 'random', showCalories: false, estimated_calories: 500  },
  ],
  daily_structure: Array.from({ length: 7 }, () => [
    { name: 'Breakfast', id: '1', calories: null, cuisine: 'random', showCalories: false, estimated_calories: 500  },
    { name: 'Lunch', id: '2', calories: null, cuisine: 'random', showCalories: false, estimated_calories: 500  },
    { name: 'Dinner', id: '3', calories: null, cuisine: 'random', showCalories: false, estimated_calories: 500  },
  ]),
  redo: "0",
});

const togglePantry = (binary) => {
  pantryToggle.value = binary;
};
const  updateIngredient = (ingredient) => {
  specific_ingredient.value = ingredient;
};
const updatePortions = (portions) => {
  portionsSize.value = portions;
};
const nextStepIngre = () => {
  window.scrollTo(0, 0);
  currentStep.value = 20;
};
const nextStepSimple = () => {
  window.scrollTo(0, 0);
  if (hasActiveMealPlan.value === true || hasActiveOverview.value === true || hasPendingPlan.value === true) {
    currentStep.value = 40;
    
  } else {
    currentStep.value++;
  }
};
const nextStep = () => {
  window.scrollTo(0, 0);
  if (hasActiveMealPlan.value === true || hasActiveOverview.value === true || hasPendingPlan.value === true) {
    currentStep.value = 40;
  } else if (currentStep.value === 0 && isPremiumUser.value === true) {
    simple.value = false;
    currentStep.value++;
  } else if (simple.value && currentStep.value === 1) {
    currentStep.value = 4.5;
  } else if (simple.value && currentStep.value === 4.5) {
    currentStep.value = 5;
  } else if (simple.value && currentStep.value === 5) {
    currentStep.value = 6;
    searchMeals();
  } else {
    currentStep.value++;
  }
};
const nextStepCheap = () => {
  window.scrollTo(0, 0);
  currentStep.value++;
};
const startCheap = () => {
  window.scrollTo(0, 0);
  if (cheapResults.value.length > 0) {
    currentStep.value = 54;
  } else {
    currentStep.value = 50;
  }
  
};

const checkForUpdates = () => {
  axios.get('api/active-meal-plan/', {
    headers: {
      'Authorization': `Bearer ${store.state.token}`
    }
  })
  .then(async response => {
    hasActiveMealPlan.value = response.data.active_meal_plan;
    if (hasActiveMealPlan.value) {
      console.log('Active meal plan:', response.data.plan);
      activeMealPlan.value = response.data.plan;
    }
    hasActiveOverview.value = response.data.active_overview;
    if (hasActiveOverview.value) {
      activeOverview.value = response.data.overview;
    }
    hasPendingPlan.value = response.data.pending_plan;
    loading.value = false;
    if (!hasPendingPlan.value) {
      // If the plan is no longer pending, stop polling
      clearInterval(pollingInterval);
    }
  })
  .catch(error => {
    console.error('Error checking for updates:', error);
  });
};




const getListByType = (listType) => {
  switch (listType) {
    case 'shopping':
      return selectedIngredients;
    case 'pantry':
      return pantryItems;
    case 'perishables':
      return perishableItems;
    case 'freezer':
      return freezerItems;
    default:
      throw new Error(`Invalid list type: ${listType}`);
  }
};


window.scrollTo(0, 0);
  
    
let pollingInterval;

onMounted(async () => {
  try {
    const response = await axios.get('/api/ingredients/all');
    allIngredients.value = response.data.map(ingredient => ({
      ...ingredient,
      measurements: ingredient.measurements.map(m => ({ id: m.id, name: m.name }))
    }));
    } catch (error) {
      console.error('Error loading ingredients:', error);
    }
    // Update the existing function
    try {
      const userListResponse = await axios.get('/api/ingredients/get_user_list/');
      
      // Helper function to process ingredient list
      const processIngredientList = (listData) => {
        if (listData && listData.ingredients && listData.ingredients.length > 0) {
          return listData.ingredients.map(ingredient => {
            
            const fullIngredientData = allIngredients.value.find(i => i.id === ingredient.id);
            if (!fullIngredientData) {
              console.error(`Ingredient with id ${ingredient.id} not found in allIngredients`);
              return null;
            }
            return {
              name: fullIngredientData.name,
              selected: fullIngredientData,
              measurement: fullIngredientData.measurements.find(m => m.id === ingredient.measurement_id),
              measurements: fullIngredientData.measurements,
              amount: ingredient.amount,
              suggestions: [],
              subcategory: fullIngredientData.subcategory.name
            };
          }).filter(Boolean); // Remove any null values
        }
        return [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      };

      // Process shopping list
      if (userListResponse.data.shopping) {
        selectedIngredients.value = processIngredientList(userListResponse.data.shopping, allIngredients.value);
        selectedStores.value = userListResponse.data.shopping.stores || ['Tesco'];
      } else {
        selectedIngredients.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
        selectedStores.value = ['Tesco'];
      }

      // Process pantry list
      if (userListResponse.data.pantry) {
        pantryItems.value = processIngredientList(userListResponse.data.pantry, allIngredients.value);
      } else {
        pantryItems.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      }


      // Process perishables list
      if (userListResponse.data.perishables) {
        perishableItems.value = processIngredientList(userListResponse.data.perishables, allIngredients.value);
      } else {
        perishableItems.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      }
      if (userListResponse.data.freezer) {
        freezerItems.value = processIngredientList(userListResponse.data.freezer, allIngredients.value);
      } else {
        freezerItems.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      }
    } catch (error) {
      console.error('Error loading data:', error);
      selectedIngredients.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      pantryItems.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      perishableItems.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      freezerItems.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null, subcategory: null }];
      selectedStores.value = ['Tesco'];
    }
  window.scrollTo(0, 0);
  if (authenticated.value) {
    startPolling();
  } else {
    loading.value = false;
  } 
});
const previousStep = () => {
  if (currentStep.value > 0) {
    if (simple.value && currentStep.value === 6) {
      currentStep.value = 5;
    } else if (simple.value && currentStep.value === 5) {
      currentStep.value = 4.5;
    } else if (simple.value && currentStep.value === 4.5) {
      currentStep.value = 1;
    } else if (simple.value && currentStep.value === 1) {
      currentStep.value = 0;
      if (isPremiumUser.value) {
        simple.value = true;
      }
    } else {
      currentStep.value--;
    }
  }
};
const removeItem = async (listType, index) => {
  const list = getListByType(listType);
  const item = list.value[index];
  
  try {
    await updateList(item.selected.id, item.measurement.id, item.amount, listType, 'remove');
    list.value.splice(index, 1);
    // If the list is empty after removal, add an empty item
    if (list.value.length === 0) {
          list.value.push({ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null });
    }
    } catch (error ) {
    console.error('Error removing item:', error);
    }
};

const addItem = async (listType, item) => {
  const list = getListByType(listType);
  if (item.selected) {
    const measurement = item.measurement || item.measurements[0]; 
    const amount = item.amount || 1;
    await updateList(item.selected.id, measurement.id, amount, listType, 'add');
  }
};

const clearList = async (listType) => {
  const list = getListByType(listType);
  list.value = [{ name: '', suggestions: [], selected: null, measurement: null, measurements: [], amount: null }];
  try {
    await axios.post('/api/ingredients/clear_user_list/', {
      list_type: listType
    });
  } catch (error) {
    console.error('Error clearing ingredients:', error);
  }
};

const updateList = async (ingredientId, measurementId, amount, listType, action) => {
  try {
    await axios.post('/api/ingredients/update_list/', {
      ingredient_id: ingredientId,
      measurement_id: measurementId,
      amount,
      list_type: listType,
      action
    });
  } catch (error) {
    console.error('Error updating list:', error);
  }
};
const updateDiet = (selectedDiet) => {
  formData.value.diet = selectedDiet;
};

const updateFormData = (data) => {
  Object.assign(formData.value, data);
};

const updateAllergens = (allergens) => {
  formData.value.allergens = allergens;
};

const updateStores = (stores) => {
  formData.value.stores = stores;
};

const updateInitial = (budget, numDays, repeat, portions) => {
  formData.value.budget = budget;
  formData.value.num_days = numDays;
  formData.value.repeat = repeat;
  formData.value.portions = portions;

};

const updateMealtypes = (mealtypes) => {
  mealTypes.value = mealtypes;
};
const updateAverageDay = (averageDay) => {
  formData.value.initial_structure = averageDay;
  formData.value.daily_structure = Array.from({ length: formData.value.num_days }, () => {
    return averageDay.map(meal => ({
      ...meal,
      // Ensuring new objects are created for each meal
      calories: meal.calories,
      cuisine: "random", // Default cuisine to random for now
      showCalories: meal.showCalories,
      drinks: meal.name === 'Alcoholic Drinks' ? meal.drinks : null

    }));
  });
};
    
const viewFullMealPlan = () => {
  axios.post('api/activate-meal-plan/', {
    meal_plan_id: activeOverview.value.meal_plan,
    headers: {
      'Authorization': `Bearer ${store.state.token}`
    }
  })
  .then(response => {
    store.commit("updateMealTokens", response.data.tokens); // Update the meal tokens (if any
    checkForUpdates();
    currentStep.value = 40;
  })
  .catch(error => {
    console.error('Error activating meal plan:', error);
  });
};
    
const redoSearch = () => {
  hasActiveOverview.value = false;
  formData.value = activeOverview.value.search_criteria;
  formData.value.redo = activeOverview.value.data;
  activeOverview.value = null;
  searchMeals();
};

const cancelPlan = () => {
  currentStep.value = 0; // New step for loading state
  axios.post('api/cancel-plan-search/')
    .then(response => {
      window.location.reload(); // Refresh the page to show the saved overview
    })
    .catch(error => {
      console.error(error);
      currentStep.value = 9; // New step for error state
      error.value = error.response ? error.response.data.message : 'An unexpected error occurred';
    });

};
const searchMeals = () => {
  hasPendingPlan.value = true;
  currentStep.value = 40;
  console.log('Searching meals...');
  axios.post('api/search-by-nutrition/', formData.value)
    .then(response => {
      hasPendingPlan.value = true;
      startPolling();
    })
    .catch(error => {
      console.error(error);
      window.location.reload();
      error.value = error.response ? error.response.data.message : 'An unexpected error occurred';
    });
};
    



const searchCheap = async () => {
  currentStep.value++;

  try {
    const response = await axios.post('api/search_cheap/', {
      pantry: pantryToggle.value,
      meal_types: mealTypes.value,
      diet: formData.value.diet.name,
      stores: formData.value.stores,
      portions: portionsSize.value,
      specific_ingredient: specific_ingredient.value
    }, {
      headers: {
        'Authorization': `Bearer ${store.state.token}`
      }
    });
    cheapResults.value = response.data.top;
  } catch (err) {
    console.error(err);
  } finally {
    currentStep.value = 54;
  }
};

const startPolling = () => {
  checkForUpdates();
  pollingInterval = setInterval(checkForUpdates, 2000);
};
onUnmounted(() => {
  if (pollingInterval) {
    clearInterval(pollingInterval);
  }
});
</script>