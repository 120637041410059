<template>
    <div class="min-h-screen bg-gradient-to-br from-blue-100 to-purple-100">
      <div class="max-w-4xl mx-auto px-4 py-4">
        <div class=" flex justify-around items-center md:justify-between">
        <h1 class="text-xl lg:text-3xl font-medium text-center text-gray-800 mb-2">Community</h1>
  
        <div v-if="authenticated" class="mb-6">
          <button
            @click="openCreatePostModal"
            class="bg-interactive text-white font-medium rounded-md px-4 py-2 hover:bg-blue-700 transition duration-300"
          >
            Create Post
          </button>
        </div>
        </div>
  
        <div class="space-y-4">
          <div
            v-for="post in posts"
            :key="post.id"
            class="bg-white shadow-md rounded-lg overflow-hidden"
          >
            <div class="py-2 px-3">
              <div class="flex items-center justify-between mb-2">
                <h2 class="text-lg lg:text-xl  font-medium text-gray-800 truncate	">{{ post.title }}</h2>
                <span class="text-sm text-gray-500">{{ formatDate(post.createdAt) }}</span>
              </div>
              <p class="text-gray-600 mb-4 truncate	">{{ post.content }}</p>
              <div class="flex items-center justify-between">
                <div class="flex items-center space-x-4">
                  <button
                    class="flex items-center text-gray-500 hover:text-blue-600"
                    @click="upvotePost(post.id)"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M3.293 9.707a1 1 0 010-1.414l6-6a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414L11 5.414V17a1 1 0 11-2 0V5.414L4.707 9.707a1 1 0 01-1.414 0z" clip-rule="evenodd" />
                    </svg>
                    {{ post.upvotes }}
                  </button>
                  <button
                    class="flex items-center text-gray-500 hover:text-blue-600"
                    @click="showComments(post.id)"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" class="h-5 w-5 mr-1" viewBox="0 0 20 20" fill="currentColor">
                      <path fill-rule="evenodd" d="M18 10c0 3.866-3.582 7-8 7a8.841 8.841 0 01-4.083-.98L2 17l1.338-3.123C2.493 12.767 2 11.434 2 10c0-3.866 3.582-7 8-7s8 3.134 8 7zM7 9H5v2h2V9zm8 0h-2v2h2V9zM9 9h2v2H9V9z" clip-rule="evenodd" />
                    </svg>
                    {{ post.commentCount }}
                  </button>
                </div>
                <span class="text-sm text-gray-500">Posted by {{ post.author }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
  
      <!-- Create Post Modal -->
      <div v-if="showCreatePostModal" class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
        <div class="bg-white rounded-lg p-6 w-full max-w-lg">
          <h2 class="text-2xl font-medium text-gray-800 mb-4">Create a New Post</h2>
          <input
            v-model="newPost.title"
            type="text"
            placeholder="Title"
            class="w-full p-2 mb-4 border rounded"
          />
          <textarea
            v-model="newPost.content"
            placeholder="Content"
            class="w-full p-2 mb-4 border rounded h-32"
          ></textarea>
          <div class="flex justify-end space-x-2">
            <button
              @click="closeCreatePostModal"
              class="px-4 py-2 text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              @click="submitPost"
              class="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700"
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue';
  import { useStore } from 'vuex';
  
  const store = useStore();
  const authenticated = computed(() => store.state.authenticated);
  
  const posts = ref([]);
  const showCreatePostModal = ref(false);
  const newPost = ref({ title: '', content: '' });
  
  onMounted(async () => {
    // Fetch posts from API
    // This is a placeholder. Replace with actual API call.
    posts.value = [
      {
        id: 1,
        title: 'First Post',
        content: 'This is the content of the first post.',
        author: 'User1',
        createdAt: '2024-09-11T12:00:00Z',
        upvotes: 10,
        commentCount: 5
      },
      // Add more sample posts here
    ];
  });
  
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  
  const openCreatePostModal = () => {
    showCreatePostModal.value = true;
  };
  
  const closeCreatePostModal = () => {
    showCreatePostModal.value = false;
    newPost.value = { title: '', content: '' };
  };
  
  const submitPost = async () => {
    // Submit new post to API
    // This is a placeholder. Replace with actual API call.
    console.log('Submitting post:', newPost.value);
    closeCreatePostModal();
  };
  
  const upvotePost = async (postId) => {
    // Upvote post API call
    console.log('Upvoting post:', postId);
  };
  
  const showComments = (postId) => {
    // Navigate to post details/comments page
    console.log('Showing comments for post:', postId);
  };
  </script>