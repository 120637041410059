import { createStore } from 'vuex';


export default createStore({
    state: {
        authenticated: false, 
        user: {
          meal_tokens: 0,
          is_premium: false,
          subscription: "inactive",
        },
        token: localStorage.getItem('access_token') || null,
    },
    mutations: {
        setAuthenticated(state, value) {
            state.authenticated = value;  // Updates the authentication state
        },
        setUser(state, user) {
            state.user = user;
          },
        setToken(state, token) {
            state.token = token;

        },
        updateMealTokens(state, tokens) {
            state.user.meal_tokens = tokens;
            this.commit('updateUserInLocalStorage');
        },
        updateUserInLocalStorage(state) {
          localStorage.setItem('user', JSON.stringify(state.user));
        }
    },
    actions: {
        initializeStore({ commit }) {
            const token = localStorage.getItem('access_token');
            const userString = localStorage.getItem('user');
        
            if (token) {
              commit('setAuthenticated', true);
              commit('setToken', token);
            } else {
              commit('setAuthenticated', false);
            }
        
            if (userString) {
              try {
                const user = JSON.parse(userString);
                commit('setUser', user);
              } catch (e) {
                console.error('Failed to parse user data from localStorage:', e);
                localStorage.removeItem('user');
                localStorage.removeItem('access_token');
              }
            }
        
            
        },
        update({ commit }, user) {
            commit('setUser', user);
            commit('updateUserInLocalStorage');
        },
        
        login({ commit }, Data) {

          commit('setAuthenticated', true);
          commit('setUser', Data.user);
          commit('setToken', Data.access); 
          localStorage.setItem('refresh_token', Data.refresh);
        },
        refresh({ commit }, refresh) {
            commit('setToken', refresh);
        },
        logout({ commit }) {
            commit('setAuthenticated', false);
            commit('setUser', null);
            commit('setToken', null);
            localStorage.removeItem('user');
            localStorage.removeItem('access_token');
            localStorage.removeItem('refresh_token');
        }
    },
    getters: {
        isAuthenticated: state => state.authenticated,
        isPremiumUser: state => state.user && state.user.is_premium && (state.user.subscription_status === 'active' || state.user.subscription_status === 'trialing'),
        isSubscribed: state => state.user && (state.user.subscription_status === 'active' || state.user.subscription_status === 'trialing'),
    }
    
});
