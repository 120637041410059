<template>
  <div class="bg-white p-2 rounded-lg">
    <div class="flex justify-between items-center cursor-pointer" @click="toggleExpanded">
      <h3 class="text-lg font-medium">Filters</h3>
      <div class="flex items-center">
        <span v-if="!isExpanded" class="text-sm text-gray-600 mx-4">
          {{ selectedFiltersText }}
        </span>
        <svg 
          :class="{'transform rotate-180': isExpanded}"
          class="w-5 h-5 transition-transform duration-200" 
          xmlns="http://www.w3.org/2000/svg" 
          viewBox="0 0 20 20" 
          fill="currentColor"
        >
          <path fill-rule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" />
        </svg>
      </div>
    </div>
    
    <div v-if="isExpanded" class="mt-4">
      <div class="mb-6">
        <h4 class="text-lg font-medium mb-2">Meal Types</h4>
        <div class="flex flex-wrap gap-2">
          <button
            v-for="mealType in mealTypes"
            :key="mealType.id"
            @click="toggleMealType(mealType.id)"
            :class="[
              'px-3 py-1 rounded-full text-sm transition-colors duration-200',
              filters.mealTypes.includes(mealType.id)
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            ]"
          >
            {{ mealType.name }}
          </button>
        </div>
      </div>
      
      <div class="mb-6">
        <h4 class="text-lg font-medium mb-2">Cuisine</h4>
        <div class="flex flex-wrap gap-2">
          <button
            v-for="cuisineType in cuisineTypes"
            :key="cuisineType.id"
            @click="toggleCuisine(cuisineType.id)"
            :class="[
              'px-3 py-1 rounded-full text-sm transition-colors duration-200',
              filters.cuisine.includes(cuisineType.id)
                ? 'bg-blue-500 text-white'
                : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
            ]"
          >
            {{ cuisineType.name }}
          </button>
        </div>
      </div>
      
      <button 
        @click="applyFilters" 
        class="w-full px-4 py-2 text-sm font-medium text-white bg-blue-500 rounded-lg hover:bg-blue-600 transition-colors duration-200"
      >
        Apply Filters
      </button>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, defineEmits, defineProps } from 'vue';
import axios from 'axios';
import { useStore } from 'vuex';

const props = defineProps(['mealTypes']);
const emit = defineEmits(['apply-filters', "cuisineTypes"]);
const store = useStore();

const filters = ref({ mealTypes: [], cuisine: [] });
const cuisineTypes = ref([]);
const isExpanded = ref(false);

const selectedFiltersText = computed(() => {
  const mealTypesCount = filters.value.mealTypes.length;
  const cuisineCount = filters.value.cuisine.length;
  const parts = [];
  
  if (mealTypesCount > 0) {
    parts.push(`${mealTypesCount} meal type${mealTypesCount > 1 ? 's' : ''}`);
  }
  if (cuisineCount > 0) {
    parts.push(`${cuisineCount} cuisine${cuisineCount > 1 ? 's' : ''}`);
  }
  
  return parts.length > 0 ? parts.join(', ') : 'No filters applied';
});

onMounted(async () => {
  try {
    const response = await axios.get('api/infoforrecipe/', {
      headers: {
        'Authorization': `Bearer ${store.state.token}`
      }
    });
    cuisineTypes.value = response.data.cuisine;
    emit('cuisineTypes', cuisineTypes.value);
  } catch (error) {
    console.error('Error fetching cuisine types:', error);
  }
});

const toggleExpanded = () => {
  isExpanded.value = !isExpanded.value;
};

const toggleMealType = (id) => {
  const index = filters.value.mealTypes.indexOf(id);
  if (index > -1) {
    filters.value.mealTypes.splice(index, 1);
  } else {
    filters.value.mealTypes.push(id);
  }
};

const toggleCuisine = (id) => {
  const index = filters.value.cuisine.indexOf(id);
  if (index > -1) {
    filters.value.cuisine.splice(index, 1);
  } else {
    filters.value.cuisine.push(id);
  }
};

const applyFilters = () => {
  emit('apply-filters', filters.value);
  isExpanded.value = false;
};
</script>