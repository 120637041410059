<template>
    <div >
      <!-- Desktop Sidebar -->
      <div class="hidden md:block  h-full w-52 bg-page-bg border-r border-gray-200 z-50 overflow-y-auto">
        <div class=" h-screen pb-8 pt-5">
          <SidebarContent />
        </div>
      </div>
  
      <!-- Mobile Sidebar -->
      <div class="md:hidden block w-full h-12 bg-page-bg border-b border-gray-200 z-50 flex justify-between items-center">
        <router-link to="/" class="flex items-center space-x-2  p-2" @click="emitLinkClicked">
          <img src="../assets/logo4.png" alt="Logo" class="h-12">
          <h2 class="text-xl font-medium text-gray-900">Meal Matcher</h2>
        </router-link>
        <!-- Toggle button -->
        <button 
          @click="toggleSidebar" 
          class=" z-50 p-4 text-lg text-gray-800 rounded-full  transition-colors focus:outline-none"
        >
          <i :class="['fas', isOpen ? 'fa-times' : 'fa-bars']"></i>
        </button>
  
        <!-- Sidebar content (shown when isOpen is true) -->
        <div
          v-show="isOpen"
          class="fixed inset-y-0 left-0 w-48 bg-page-bg border-l border-gray-400 shadow-lg z-50 transition-transform duration-300 ease-in-out overflow-y-auto"
        >
          <div class="py-4">
            <SidebarContent @linkClicked="closeSidebar" />
          </div>
        </div>
  
        <!-- Overlay -->
        <div 
          v-if="isOpen" 
          class="fixed inset-0 bg-black bg-opacity-50 z-40" 
          @click="closeSidebar"
        ></div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref } from 'vue';

  import SidebarContent from './SidebarContent.vue';
  
 
  const isOpen = ref(false);
  
  const toggleSidebar = () => {
    isOpen.value = !isOpen.value;
  };
  
  const closeSidebar = () => {
    isOpen.value = false;
  };
  </script>
  
  <style scoped>
  @import '@fortawesome/fontawesome-free/css/all.css';
  </style>