<template>
  <div class="about-page  bg-gradient-to-b from-orange-50 to-white text-grey-800 py-8 px-8 font-main">
    <div class="container mx-auto">
      <h1 class="text-3xl md:text-4xl font-bold mb-12 text-center text-orange-600">Our Story</h1>
      
      <!-- Introduction Section -->
      <div class="mb-16 text-center w-full">
        <p class="text-xl md:text-2xl leading-relaxed  mx-auto">
          Welcome to our meal planning family! We're here to bring simplicity, and nourishment to your kitchen.
        </p>
        <p class="pt-2 text-xl md:text-2xl leading-relaxed  mx-auto">
          Whether you're a busy professional, a parent juggling responsibilities, or simply someone who wants to eat more healthily without the stress, we're here to support you.
        </p>
      </div>
      
      <!-- Mission Section -->
      <div class="flex flex-col md:flex-row text-center mb-16">
        <div id="scroller" class="scroller md:w-1/2 w-full  ">
          <div class="scroller__inner">
          <img src="../assets/food_pic1.jpg" alt="Our Mission" class="rounded-xl shadow-lg h-80 w-auto">
          <img src="../assets/food_pic2.jpg" alt="Our Mission" class="rounded-xl shadow-lg h-80 w-auto ">
          <img src="../assets/food_pic3.jpg" alt="Our Mission" class="rounded-xl shadow-lg h-80 w-auto">
          <img src="../assets/food_pic4.jpg" alt="Our Mission" class="rounded-xl shadow-lg h-80 w-auto ">
          <img src="../assets/food_pic5.jpg" alt="Our Mission" class="rounded-xl shadow-lg h-80 w-auto ">
          <img src="../assets/food_pic6.jpg" alt="Our Mission" class="rounded-xl shadow-lg h-80 w-auto ">
          <img src="../assets/food_pic7.jpg" alt="Our Mission" class="rounded-xl shadow-lg h-80 w-auto ">

        </div>
        </div>
        <div class="md:w-1/2 md:pl-12">
          <h2 class="text-2xl font-semibold mb-4 text-orange-600">Our Mission</h2>
          <p class="text-lg leading-relaxed mb-2">
            Our mission is to empower you to take control of your nutrition and your kitchen. We believe that everyone deserves access to healthy, delicious, and affordable meals without the hassle. By providing you with tailored meal plans, easy-to-follow recipes, and smart shopping lists, we take the guesswork out of meal planning so you can focus on enjoying your food and your life.
          </p>
          <p>Everything we need is in the shops already, its just putting it together can be difficult. There is no need for overly expesnsive meal delivery servies, we're here to help.</p>
        </div>
      </div>
      
      <!-- Team Section -->
      <div class="flex flex-col text-center items-center mb-16 mx-auto">
        <!-- Team Section 
        <div class="md:w-1/2 mb-8 md:mb-0 flex justify-center align-center mx-auto">
          <img src="../assets/photo_me.jpg" alt="Our Team" class="rounded-full shadow-lg h-72 w-72">
        </div>-->
        <div class="md:w-1/2 md:pr-12">
          <h2 class="text-2xl font-semibold mb-4 text-orange-600">Who We Are</h2>
          
          <p class="text-lg leading-relaxed">
            Our journey began during university, where I experienced firsthand the challenges of eating healthily, diversely, and on a budget while meeting nutritional goals. The time-consuming process of manual meal planning often led to frustration—whether it was dealing with food waste, eating the exact same meals, or struggling to find supermarket items not designed for families. These challenges inspired the creation of our service, designed to simplify meal planning and make it both personalised and enjoyable. Our mission is to help everyone, especially those who love to cook, make the most of their meals without the stress.
          </p>
          
        </div>
      </div>
      
      <!-- How It Works Section -->
      <div class="mb-16">
        <h2 class="text-2xl font-semibold mb-8  text-orange-600">How It Works</h2>
        <div class="grid grid-cols-1 md:grid-cols-3 gap-8">
          <div class="bg-white p-6 rounded-lg shadow-md">
            <h3 class="text-xl font-semibold mb-3">Personalise</h3>
            <p>Input your dietary preferences, budget, and nutritional goals. Whether you're vegan, gluten-free, or looking to bulk up on protein, we've got you covered.</p>
          </div>
          <div class="bg-white p-6 rounded-lg shadow-md">
            <h3 class="text-xl font-semibold mb-3">Plan</h3>
            <p>Get customised meal plans and smart shopping lists that save you time and money. Our plans are designed to minimise food waste and maximise ingredient usage across multiple meals.</p>
          </div>
          <div class="bg-white p-6 rounded-lg shadow-md">
            <h3 class="text-xl font-semibold mb-3">Enjoy</h3>
            <p>Cook delicious meals with ease using our step-by-step recipes. Track your progress, and watch as your kitchen becomes a space of creativity and wellness.</p>
          </div>
        </div>
      </div>
      
      <!-- Join Us Section -->
      <div class="text-center flex flex-col justify-center items-center mb-16 mx-auto">

        <p class="text-lg leading-relaxed max-w-3xl mx-auto mb-8">
          Be part of our journey towards healthier, happier eating. Let's make meal planning a joyful experience together!
        </p>
        <button @click="startPlanning" class="w-3/5 md:w-40 lg:w-52 xl:w-56 h-12 text-md md:text-base lg:text-lg xl:text-xl shadow-in-out bg-interactive hover:bg-interactive-hover hover:border-gray-400 text-white rounded-full transition-colors flex items-center justify-center">
      Get Started
    </button>
      </div>
      
      <!-- Contact Section -->
      <div class="text-center mt-12 lg:mt-20">

        <div class="container mx-auto px-4 max-w-6xl">
          <p class="text-center text-grey-600 mb-2">We value your feedback and are committed to continuously improving our service based on your needs.</p>
          <p class="text-center text-orange-500 text-xl font-semibold">info@mealmatcher.co.uk</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import axios from 'axios';
import "swiper/swiper-bundle.css";
import { onMounted, computed } from 'vue';



onMounted(() => {
  const scroller = document.getElementById('scroller');
  
  if (!window.matchMedia('(prefers-reduced-motion: reduce)').matches) {
    addAnimation();
  }

  function addAnimation() {
    if (scroller) { // Ensure the element exists
      scroller.setAttribute("data-animated", true);
      const scrollerInner = scroller.querySelector('.scroller__inner');
      if (scrollerInner) { // Ensure the inner container exists
        const scrollerContent = Array.from(scrollerInner.children);

        // Clone each item and append it to the scrollerInner for continuous scrolling effect
        scrollerContent.forEach(item => {
          const clone = item.cloneNode(true);
          clone.setAttribute("aria-hidden", true);
          scrollerInner.appendChild(clone);
        });
      } else {
        console.error('No .scroller__inner element found inside #scroller');
      }
    } else {
      console.error('No element found with ID "scroller"');
    }
  }
});
const router = useRouter();
const authenticated = computed(() => store.state.authenticated);
const startPlanning = () => {
  if (authenticated){
    router.push("/uk-meal-planner")
  }else{
    router.push('/signup');
  }
};
</script>

<style>
  @import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:wght@400;600;700&display=swap');
  .about-page {
    font-family: 'Source Serif 4', serif;
  }
  .about-content {
    max-width: 800px;
    margin: 0 auto;
  }

  .scroller[data-animated ="true"]{
    overflow: hidden;

    -webkit-mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent );
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent );
  }
  .scroller[data-animated ="true"] .scroller__inner {
    width:max-content;
    flex-wrap: nowrap;
    animation: scroll 20s linear infinite;
  }
  .scroller__inner {
   display: flex;
   flex-wrap: nowrap; /* Already set correctly */
   width: max-content; /* This should allow the content to expand beyond the parent's width */
   padding-block: 1rem;
   gap: 1rem;
 }
  @keyframes scroll{
    to {
      transform: translate(calc(-50% - 0.5rem));
    }
  }
</style>
