<template>
    <div class="h-full flex flex-col justify-between">
      <router-link to="/" class="flex flex-col items-center space-y-1 mb-6 p-2" @click="emitLinkClicked">
        <img src="../assets/logo4.png" alt="Logo" class="h-12">
        <h2 class="text-2xl font-semibold text-p-t">Meal Matcher</h2>
      </router-link>
  
      <nav>
        <ul class="space-y-4">
          <li v-for="link in navLinks" :key="link.to">
            <router-link 
              :to="link.to" 
              class="block py-2 px-4 text-lg hover:bg-gray-100 transition-colors"
              :class="{ 'text-interactive font-medium bg-zinc-50 shadow-inner': $route.path === link.to }"
              @click="emitLinkClicked"
            >
              {{ link.text }}
            </router-link>
          </li>
        </ul>
      </nav>
  
      <div class="mt-8 p-3">
        <template v-if="!authenticated">
          <router-link 
            to="/login" 
            class="block w-full text-center bg-gray-600   border border-1 border-gray-700 text-white py-2 px-4 rounded-full mb-4 hover:bg-opacity-90 transition-colors"
            @click="emitLinkClicked"
          >
            Log in
          </router-link>
          <router-link 
            to="/signup" 
            class="block w-full text-center bg-interactive border border-1 border-gray-700 text-white py-2 px-4 rounded-full hover:bg-interactive-hover transition-colors"
            @click="emitLinkClicked"
          >
            Get started
          </router-link>
        </template>
        <template v-else>
          <router-link 
            to="/profile" 
            class="flex items-center justify-center w-full text-center text-gray-800 border border-gray-500 py-2 px-4 rounded-full mb-4 hover:bg-opacity-90 transition-colors"
            @click="emitLinkClicked"
          >
            <ion-icon name="person-circle-outline" class="text-2xl mr-2"></ion-icon>
            {{ user.username }}
          </router-link>
          <!--<div class="flex items-center justify-center space-x-2 mb-4">
            <span :class="{ 'font-semibold': isPremiumUser }">{{ user.meal_tokens }}</span>
            <img :src="tokenImage" :class="{ 'h-6 w-6': isPremiumUser, 'h-5 w-5': !isPremiumUser }">
            <transition name="float-fade">
              <div v-if="showAnimation" class="absolute text-red-500 text-sm">-1</div>
            </transition>
          </div>-->
          <button 
            @click="logout" 
            class="block w-full text-center bg-gray-600 text-white py-2  border border-1 border-gray-800 px-4 rounded-full hover:bg-opacity-90 transition-colors"
          >
            Logout
          </button>
        </template>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, computed, watch } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
  
  const store = useStore();
  const router = useRouter();
  const showAnimation = ref(false);
  
  const authenticated = computed(() => store.state.authenticated);
  const user = computed(() => store.state.user);
  const isPremiumUser = computed(() => store.getters.isPremiumUser);

  const navLinks = [
    { to: '/uk-meal-planner', text: 'Dashboard' },
    { to: '/community', text: 'Community'},
    { to: '/recipes', text: 'Recipes' },
    { to: '/how-to-use', text: 'How To Use' },
    { to: '/about', text: 'About' },
  ];
  
  const logout = () => {
    localStorage.removeItem('access_token');
    localStorage.removeItem('refresh_token');
    store.dispatch('logout');
    router.push('/');
    emitLinkClicked();
  };
  
  const emitLinkClicked = () => {
    emit('linkClicked');
  };
  
  const emit = defineEmits(['linkClicked']);
  
  // Watch for changes in meal tokens
  watch(() => user.value.meal_tokens, (newValue, oldValue) => {
    if (newValue < oldValue) {
      showAnimation.value = true;
      setTimeout(() => {
        showAnimation.value = false;
      }, 500);
    }
  });
  </script>
  
  <style scoped>
  .float-fade-enter-active, .float-fade-leave-active {
    transition: all 0.5s ease;
  }
  .float-fade-enter-from, .float-fade-leave-to {
    opacity: 0;
    transform: translateY(-20px);
  }
  </style>