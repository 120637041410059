<template>
    <div class="how-it-works flex pt-6 scroll-container">
   
    <!-- Main Content -->
    <div class=" md:mr-1/4 w-full md:w-4/5 pl-4 mt-16 md:mt-0 relative">
      <div class="absolute inset-0 z-0 bg-repeat-y bg-opacity-10" :style="backgroundStyle"></div>
      
      <div class="relative z-10">
      <h1 class="text-xl lg:text-3xl font-medium text-gray-700  mb-8 text-center">How Our Meal Planner Works</h1>
    
<section class="mb-16 px-6" id="choose-plan">
  <h2 class="text-2xl font-medium mb-6">Choose Your Plan</h2>
  <div class="flex flex-col space-y-8">
    <div class="plan-card bg-white rounded-lg shadow-md p-6" id="shopping-plan">
      <h3 class="text-xl font-semibold text-gray-700 mb-4">Shopping List Plan</h3>
      <p class="text-xl mb-4">£2.00 / month</p>
      <ul class="space-y-4">
        <li>
          <h4 class="font-semibold">Acces to Shopping List Generator</h4>
          <p>Create up to 5 unique meal plans each month, perfect for trying out our service or for those who prefer parly planning partly spontanuity.</p>
          <p>These meal plans will generated aiming for 2000kcals a day, with no macro consideration.</p>
        </li>
      </ul>
    </div>
    
    <div class="plan-card bg-white rounded-lg shadow-md p-6 border-2 border-blue-500" id="premium-plan">
      <h3 class="text-xl font-semibold text-gray-700 mb-4">Meal Plan Subscription</h3>
      <p class="text-xl mb-4">£7.00 / month</p>
      <ul class="space-y-4">
        <li>
          <h4 class="font-semibold">Generate 15 meal plans a month</h4>
          <p>Create up to 15 unique meal plans each month, offering greater flexibility and variety in your meal planning.</p>
        </li>
        <li>
          <h4 class="font-semibold">Choose between 800 and 4000 calories a day</h4>
          <p>Tailor your meal plans to specific calorie goals, supporting various nutritional needs from weight loss to muscle gain.</p>
          <p>This will be set to each day of the plan no matter different days schedules, but you can vary it between plans.</p>
        </li>
        <li>
          <h4 class="font-semibold">Specify one macro target (Protein/Fats/Carbs)</h4>
          <p>Fine-tune your nutrition by setting a specific macronutrient goal, great for hitting a protein goal.</p>
        </li>
        <li>
          <h4 class="font-semibold">All cuisines available</h4>
          <p>Explore a world of flavors with access to recipes from various international cuisines, expanding your culinary horizons.</p>
        </li>
        <li>
          <h4 class="font-semibold">Plan up to 4 servings per meal</h4>
          <p>Ideal for families or meal preppers, allowing you to plan meals for up to four people.</p>
          <p class="text-gray-400 text-sm">Note: All meals will be the same for each 4 people, we optmise what ingredients to buy.</p>
        </li>
        <li>
          <h4 class="font-semibold">Set a budget goal</h4>
          <p>Control your grocery spending by setting a budget target for your meal plans, helping you save money while eating well.</p>
        </li>
        <li>
          <h4 class="font-semibold">Plan up to 7 days at once</h4>
          <p>Create comprehensive weekly meal plans. Plan the entire weekly shop!</p>
        </li>
        <li>
          <h4 class="font-semibold">Change your schedule every day</h4>
          <p>Enjoy maximum flexibility by customising your meal schedule for each day of the week, accommodating varying daily routines. Maybe one day OMAD, or a work dinner out.</p>
        </li>
      </ul>
    </div>
  </div>
</section>
  

<section class="mb-16" id="meal-plan-creation-process">
    <h2 class="text-2xl font-medium mb-6">Our Simple Process</h2>
    <div class="space-y-8">
      <div id="update-pantry" class="setup-step">
            <div class="mb-4 h-64 bg-gray-200">
                <!-- Placeholder for pantry update image -->
                <p class="text-center py-24">Image: Update Pantry</p>
            </div>
            <h3 class="text-xl font-semibold mb-2">1. Update Your Pantry</h3>
            <p>Before searching for your meal plan, it's crucial to update your pantry with items you already have. This includes household essentials like salt, pepper, and cooking oil, as well as any perishables in your fridge or items in your freezer. Don't forget to include any dry goods or tinned items you have stocked up. This step ensures your meal plan takes into account what you already have, reducing waste and saving you money.</p>
        </div>
        <div id="select-plan" class="setup-step">
            <div class="mb-4 h-64 bg-gray-200">
                <!-- Placeholder for step 1 image -->
                <p class="text-center py-24">Image: Select Plan</p>
            </div>
            <h3 class="text-xl font-semibold mb-2">1. Choose Your Plan Type</h3>
            <p>Select between the simple plan, which provides basic meal planning features for a quick and straightforward experience, or the advanced plan, which offers additional customisation options for users who want more control over their meal plans.</p>
        </div>
        <div id="select-diet" class="setup-step">
            <div class="mb-4 h-64 bg-gray-200">
                <!-- Placeholder for step 2 image -->
                <p class="text-center py-24">Image: Select Diet</p>
            </div>
            <h3 class="text-xl font-semibold mb-2">2. Select Diet</h3>
            <p>Choose the type of diet that suits your lifestyle and nutritional goals. We support a variety of diets including:</p>
            <ul class="list-disc list-inside">
                <li><strong>Anything:</strong> No specific dietary restrictions, allowing a wide variety of foods.</li>
                <li><strong>Keto:</strong> A low-carb, high-fat diet designed to promote ketosis, a metabolic state where your body burns fat for fuel instead of carbohydrates.</li>
                <li><strong>Vegetarian:</strong> A diet that excludes meat and fish but may include dairy and eggs. Perfect for those who want to reduce meat consumption or follow a plant-based diet.</li>
                <li><strong>Vegan:</strong> A plant-based diet that excludes all animal products, including dairy, eggs, and honey. Ideal for those committed to a completely animal-free diet.</li>
                <li><strong>Paleo:</strong> Focuses on whole foods that were presumably available to humans during the Paleolithic era, including lean meats, fish, fruits, vegetables, nuts, and seeds.</li>
                <li><strong>Atkins:</strong> A low-carb diet that emphasises protein and fats while limiting carbohydrates to help lose weight and improve health.</li>
                <li><strong>Gluten-free:</strong> Excludes foods containing gluten, a protein found in wheat, barley, and rye. Essential for those with celiac disease or gluten sensitivity.</li>
                <li><strong>Dairy-free:</strong> Eliminates all dairy products, suitable for individuals with lactose intolerance or those avoiding dairy for personal or health reasons.</li>
            </ul>
            <p>Choosing the right diet helps us tailor your meal plan to include recipes that meet your dietary preferences and nutritional needs.</p>
        </div>
        <div id="select-store" class="setup-step">
            <div class="mb-4 h-64 bg-gray-200">
                <!-- Placeholder for step 3 image -->
                <p class="text-center py-24">Image: Select Store</p>
            </div>
            <h3 class="text-xl font-semibold mb-2">3. Select Store</h3>
            <p>Choose your preferred grocery store for shopping. Currently, you can select from:</p>
            <ul class="list-disc list-inside">
                <li>Tesco</li>
                <li>Aldi (coming soon)</li>
                <li>Sainsbury's (coming soon)</li>
                <li>Morrisons (coming soon)</li>
                <li>Asda (coming soon)</li>
            </ul>
            <p>This ensures that your meal plan includes ingredients available at your chosen store.</p>
        </div>
        <div id="select-calories-macro" class="setup-step">
            <div class="mb-4 h-64 bg-gray-200">
                <!-- Placeholder for step 4 image -->
                <p class="text-center py-24">Image: Select Calories and Macro</p>
            </div>
            <h3 class="text-xl font-semibold mb-2">4. Select Calories and Macro</h3>
            <p>For those who have chosen the advanced plan, you can specify your daily calorie intake, which can range from 800 to 4000 calories. Additionally, you can choose one macronutrient (protein, fat, or carbohydrate) to focus on, with a range from 10g to 300g. This feature provides detailed nutritional customisation to meet specific dietary goals and enhance the effectiveness of your meal plan.</p>
        </div>
        <div id="initial-setup" class="setup-step">
            <div class="mb-4 h-64 bg-gray-200">
                <!-- Placeholder for step 5 image -->
                <p class="text-center py-24">Image: Initial Setup</p>
            </div>
            <h3 class="text-xl font-semibold mb-2">5. Initial Setup</h3>
            <p>Configure the initial setup of your meal plan, including:</p>
            <ul class="list-disc list-inside">
                <li>Number of days: up to 7 days</li>
                <li>Budget: £10-£100</li>
                <li>Meal repetition frequency: no limit, once every other day, or never</li>
                <li>Number of servings: up to 4</li>
            </ul>
            <p>This helps us create a plan that fits your schedule and budget.</p>
        </div>
        <div id="set-typical-day" class="setup-step">
            <div class="mb-4 h-64 bg-gray-200">
                <!-- Placeholder for step 6 image -->
                <p class="text-center py-24">Image: Set Typical Day</p>
            </div>
            <h3 class="text-xl font-semibold mb-2">6. Set a Typical Day</h3>
            <p>Choose which meals you typically have in a day. This includes breakfast, lunch, dinner, and any snacks.</p>
            <p>Users with the advanced plan can fine-tune their meal plan on a day-by-day basis in the next step.</p>
        </div>
        <div id="fine-tune-plan" class="setup-step">
            <div class="mb-4 h-64 bg-gray-200">
                <!-- Placeholder for step 7 image -->
                <p class="text-center py-24">Image: Fine Tune Plan</p>
            </div>
            <h3 class="text-xl font-semibold mb-2">7. Fine Tune the Plan</h3>
            <p>Users with the advanced plan can make adjustments to their meal plan, such as changing meals on specific days, removing meals, or adding new ones. This provides the flexibility to adapt the plan as needed.</p>
        </div>
        
        <div id="overview-plan" class="setup-step">
            <div class="mb-4 h-64 bg-gray-200">
                <!-- Placeholder for step 8 image -->
                <p class="text-center py-24">Image: Overview</p>
            </div>
            <h3 class="text-xl font-semibold mb-2">8. Overview</h3>
            <p>Review your meal plan to ensure it meets your preferences and nutritional goals. You can see a summary of your daily meals, calorie intake, macronutrients, and budget.</p>
            <p>If your meal plan does not align with the selected parameters, you have the opportunity to regenerate it without using a token, ensuring you get a meal plan that fits your needs.</p>
        </div>
        <div id="pantry-update" class="setup-step">
            <div class="mb-4 h-64 bg-gray-200">
                <!-- Placeholder for pantry update image -->
                <p class="text-center py-24">Image: Pantry Update</p>
            </div>
            <h3 class="text-xl font-semibold mb-2">9. Pantry Update</h3>
            <p>Once you select your plan from the overview, your pantry will be automatically updated. New items left over from your meal plan will be added, quantities of used items will be adjusted, and untouched items will remain as they were. This ensures your pantry always reflects an accurate inventory of your kitchen, making future meal planning even more efficient.</p>
        </div>
    </div>
</section>
  

  
      
  
<section id="meal-tokens" class="mb-16">
    <h2 class="text-3xl font-semibold mb-6">Meal Tokens</h2>
    <div class="mb-4 flex items-center">
        <p>A meal token is used when generating a meal plan.</p>
    </div>
    <p>Each month your meal tokens are reset to 15. This is due to how much processing power it takes to generate, we sadly have to put a limit on them.</p>
    <p>Our goal is to provide you with a meal plan that fits your lifestyle. If a meal plan is generated outside of the selected parameters, you will have the opportunity to redo the plan.</p>
    <p>For example, if you request 2000 calories a day and the plan gives an average of 2750 calories, which is over 10% off, you can search again without using a token. This will be displayed in the overview section.</p>
</section>


<section class="mb-16" id="daily-updates">
  <h2 class="text-3xl font-semibold mb-6">Daily Updates for Accuracy</h2>
  <p class="mb-4">We understand the importance of up-to-date information when it comes to meal planning. That's why we commit to daily updates of our system:</p>
  <ul class="list-disc pl-5 mb-4">
    <li>Prices are refreshed daily to reflect current market rates</li>
    <li>Nutritional information is updated to ensure accuracy</li>
    <li>New recipes and meal options are regularly added to keep your plans fresh and exciting</li>
  </ul>
  <p>With our commitment to daily updates, you can trust that your meal plans are always based on the most current and accurate information available.</p>
</section>


      <section class="mb-16" id="dashboard">
        <h2 class="text-3xl font-semibold mb-6">Explaining the meal plan dashboard</h2>
        <p class="mb-4">The dashboard is shown after a succseful generation. The process works exemptioanly well on both mobile and desktop with our responsive design.</p>
        <p>Section 1: The meal plan. </p>
        <p>It is shown with tabs for each day at the top, then displayed are daily avergae of macros and the meals </p>
        <p>Each meal with have a breith descitption and how long on average it takes to prep and cook.</p>
        <p>Section 2: The shopping list. </p>
        <p>Shown in a seperate section on the phone or on the right on desktop, this tells you what items to buy from your selected stores. it has been done in the most cost effective way and categorised.  </p>
        <p>There is an option to exclude non perishables. </p>
        <p>Non perishables can be edited, but they include seasonings (Salt, pepper), grains and sauces. This is because you may not need to buy them every plan </p>
        <p>Section 3: The leftovers list. </p>
        <p>It shows how much of each ingredient is left. This is becuse the plan works with exact calcualtions, so if there are a couple grams leftover, it would make mos sense to put it in the recipe.  </p>
        <p>There is an option to exclude non perishables here aswell</p>
      </section>
      

      <section class="mb-16" id="shopping-list-generation-tool">
    <h2 class="text-3xl font-semibold mb-6">Shopping List Generation Tool</h2>
    <p class="text-lg mb-6">Generate efficient shopping lists based on your ingredients and preferred stores.</p>
    <div class="space-y-8">
      <div id="select-stores" class="setup-step">
        <div class="mb-4 h-64 bg-gray-200">
          <!-- Placeholder for store selection image -->
          <p class="text-center py-24">Image: Store Selection</p>
        </div>
        <h3 class="text-xl font-semibold mb-2">1. Select Stores</h3>
        <p>Choose your preferred grocery stores for shopping. Tesco is always selected as the primary store. Click on additional store icons to include them in your selection.</p>
        <ul class="list-disc list-inside mt-2">
          <li>Tesco (always selected)</li>
          <li>Aldi</li>
          <li>M&S</li>
          <li>Sainsbury's</li>
          <li>Asda (coming soon)</li>
          <li>Morrisons (coming soon)</li>
        </ul>
      </div>

      <div id="add-ingredients" class="setup-step">
        <div class="mb-4 h-64 bg-gray-200">
          <!-- Placeholder for ingredient addition image -->
          <p class="text-center py-24">Image: Add Ingredients</p>
        </div>
        <h3 class="text-xl font-semibold mb-2">2. Add Ingredients</h3>
        <p>Search for ingredients and add them to your list. For each ingredient, specify:</p>
        <ul class="list-disc list-inside mt-2">
          <li>Amount: Enter the quantity needed</li>
          <li>Measurement: Choose the appropriate unit (e.g., grams, items, litres)</li>
        </ul>
      </div>

      <div id="manage-list" class="setup-step">
        <div class="mb-4 h-64 bg-gray-200">
          <!-- Placeholder for list management image -->
          <p class="text-center py-24">Image: Manage List</p>
        </div>
        <h3 class="text-xl font-semibold mb-2">3. Manage Your List</h3>
        <p>Easily manage your ingredient list:</p>
        <ul class="list-disc list-inside mt-2">
          <li>Add more ingredients with the "Add Ingredient" button</li>
          <li>Remove ingredients using the "X" button next to each item</li>
          <li>Modify amounts or measurements as needed</li>
        </ul>
      </div>

      <div id="submit-list" class="setup-step">
        <div class="mb-4 h-64 bg-gray-200">
          <!-- Placeholder for submission image -->
          <p class="text-center py-24">Image: Submit List</p>
        </div>
        <h3 class="text-xl font-semibold mb-2">4. Submit Your List</h3>
        <p>Once you've added all desired ingredients and specified their amounts, click the "Submit" button. The system will process your list and generate optimised shopping recommendations.</p>
      </div>

      <div id="review-results" class="setup-step">
        <div class="mb-4 h-64 bg-gray-200">
          <!-- Placeholder for results review image -->
          <p class="text-center py-24">Image: Review Results</p>
        </div>
        <h3 class="text-xl font-semibold mb-2">5. Review Results</h3>
        <p>After submission, you'll see:</p>
        <ul class="list-disc list-inside mt-2">
          <li>A shopping list organised by store</li>
          <li>Any leftover ingredients not used in full</li>
          <li>Total amount spent on ingredients</li>
          <li>Total amount of ingredients used</li>
        </ul>
      </div>

      <div id="modify-resubmit" class="setup-step">
        <div class="mb-4 h-64 bg-gray-200">
          <!-- Placeholder for modification image -->
          <p class="text-center py-24">Image: Modify and Resubmit</p>
        </div>
        <h3 class="text-xl font-semibold mb-2">6. Modify and Resubmit</h3>
        <p>If you need to make changes, click "Back to Selection" to return to the ingredient list. Modify your selections as needed and submit again.</p>
      </div>

      <div id="clear-list" class="setup-step">
        <div class="mb-4 h-64 bg-gray-200">
          <!-- Placeholder for clear list image -->
          <p class="text-center py-24">Image: Clear List</p>
        </div>
        <h3 class="text-xl font-semibold mb-2">7. Clear List</h3>
        <p>To start over, use the "Clear" button to reset your ingredient list and store selections.</p>
      </div>
    </div>
  </section>
  <section id="get-started" class="mb-16">
        <h2 class="text-3xl font-semibold mb-6">Ready to Get Started?</h2>
        <p class="mb-4">Join thousands of satisfied users who have simplified their meal planning with our tool. Whether you're looking to eat healthier, save money, or just take the stress out of daily meal decisions, we're here to help.</p>
        <button class="bg-green-500 text-white px-8 py-3 rounded-lg text-xl hover:bg-green-600">Sign Up Now</button>
      </section>
    </div>
    </div>
   <!-- Index Section (Desktop) -->
   <div class="hidden md:block w-1/5 fixed right-0 top-0 h-screen overflow-y-auto p-6 bg-gray-100">
      <h2 class="text-2xl font-bold mb-4">Contents</h2>
      <ul>
        <li v-for="(section, index) in sections" :key="index" class="mb-2">
          <a @click.prevent="scrollToSection(section.id)" 
             :class="['block py-2 px-4 rounded transition-colors duration-200 cursor-pointer', 
                      currentSection === section.id ? 'bg-blue-100 text-blue-700' : 'hover:bg-gray-200']">
            {{index+1}}. {{ section.title }}
          </a>
          <ul v-if="section.subsections" class="ml-4 mt-1">
            <li v-for="(subsection, subIndex) in section.subsections" :key="subIndex">
              <a @click.prevent="scrollToSection(subsection.id)" 
                 :class="['block py-1 px-4 rounded transition-colors duration-200 cursor-pointer', 
                          currentSection === subsection.id ? 'bg-blue-50 text-blue-600' : 'hover:bg-gray-200']">
                {{index+1}}.{{subIndex+1}} {{ subsection.title }}
              </a>
            </li>
          </ul>
        </li>
      </ul>
    </div>

    <!-- Mobile Section Indicator -->
    <div class="md:hidden fixed top-16 left-0 right-0 bg-white z-10 p-4 shadow-md">
      <div class="relative">
        <select v-model="currentSection" @change="scrollToSection(currentSection)" class="w-full p-2 border rounded appearance-none">
          <option v-for="section in flattenedSections" :key="section.id" :value="section.id">
            {{ section.title }}
          </option>
        </select>
        <div class="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
          <svg class="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
            <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
          </svg>
        </div>
      </div>
    </div>
  </div>
  </template>
  
 
<script>
import background from '../assets/background3.webp'
export default {
  name: 'HowItWorks',
  data() {
    return {
      currentSection: '',
      sections: [
        {
          id: 'choose-plan',
          title: 'Choose Your Plan',
          subsections: [
            { id: 'shopping-plan', title: 'Shopping List Plan' },
            { id: 'premium-plan', title: 'Meal Planner Plan' }
          ]
        },
        {
            id: 'meal-plan-creation-process',
        title: 'Meal Plan Creation',
        subsections: [
          { id: 'update-pantry', title: 'Update Your Pantry' },
            { id: 'select-plan', title: 'Select Plan' },
            { id: 'select-diet', title: 'Select Diet' },
            { id: 'select-store', title: 'Select Store' },
            { id: 'select-calories-macro', title: 'Select Calories and Macro' },
            { id: 'initial-setup', title: 'Initial Setup' },
            { id: 'set-typical-day', title: 'Set a Typical Day' },
            { id: 'fine-tune-plan', title: 'Fine Tune the Plan' },
            { id: 'overview-plan', title: 'Overview' },
            { id: 'pantry-update', title: 'Pantry Auto Updates' }

            
        ]
        },
        {
          id: 'meal-tokens',
          title: 'Meal tokens'
        },
        {
          id: 'daily-updates',
          title: 'Daily Updates'
        },
        {
          id: 'dashboard',
          title: 'Meal Plan Dashboard'
        },
        { id: 'shopping-list-generation-tool',
         title: 'Shopping List Generation Tool'
         },
        {
          id: 'get-started',
          title: 'Get Started'
        }
      ]
    }
  },
  computed: {
    flattenedSections() {
      return this.sections.reduce((acc, section) => {
        acc.push({ id: section.id, title: section.title });
        if (section.subsections) {
          acc.push(...section.subsections);
        }
        return acc;
      }, []);
    },
    backgroundStyle() {
      return {
        backgroundImage: `url(${background})`,
        opacity: 0.2
      }
    }
  },

  mounted() {
    window.addEventListener('scroll', this.onScroll)
  },
  beforeUnmount() {
    window.removeEventListener('scroll', this.onScroll)
  },
  methods: {
    onScroll() {
      const sections = document.querySelectorAll('section, .setup-step')
      let currentSection = ''

      sections.forEach(section => {
        const sectionTop = section.offsetTop
        const sectionHeight = section.clientHeight
        if (window.pageYOffset >= sectionTop - 100 && window.pageYOffset < sectionTop + sectionHeight - 100) {
          currentSection = section.id
        }
      })

      this.currentSection = currentSection
    },
    scrollToSection(sectionId) {
      const element = document.getElementById(sectionId)
      if (element) {
        const yOffset = -80; // Adjust this value to account for navbar height
        const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset
        window.scrollTo({top: y, behavior: 'smooth'})
      }
    }
  },
  head() {
    return {
      title: 'How Our Meal Planner Works - Comprehensive Guide',
      meta: [
        { hid: 'description', name: 'description', content: 'Learn how our meal planner works, from choosing your plan to creating customised meal plans. Explore features for both basic and premium users.' },
        { hid: 'keywords', name: 'keywords', content: 'meal planner, meal planning, diet plans, nutrition, healthy eating, customised meals' }
      ]
    }
}
}
</script>
  
<style scoped>
.index-section {
  top: 4rem;
  height: calc(100vh - 4rem);
  overflow-y: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer 10+ */
}

.index-section::-webkit-scrollbar {
  display: none; /* Chrome, Safari, Opera */
}

.main-content {
  margin-right: 20%;
}

@media (max-width: 768px) {
  .main-content {
    margin-left: 0;
  }
}

html {
  scroll-behavior: smooth;
}
.main-content {
  margin-left: 20%;
}

.scroll-container {
  overflow-y: auto;
  scrollbar-width: none;
  -ms-overflow-style: none;
}

.scroll-container::-webkit-scrollbar {
  width: 0;
  height: 0;
}
  </style>