<!-- Login.vue -->

<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-50">
    <div class="w-full max-w-md mx-auto p-5">
      <h1 class="text-3xl mb-6 text-p-t text-center">Welcome Back</h1>
      <form class="space-y-6" @submit.prevent="login">
        <!-- Username -->
        <div>
          <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
          <input
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-interactive focus:border-interactive"
            id="username"
            type="text"
            v-model="username"
            required
          />
        </div>

        <!-- Password -->
        <div>
          <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
          <input
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-interactive focus:border-interactive"
            id="password"
            type="password"
            v-model="password"
            required
          />
        </div>

        <!-- Submit Button -->
        <div>
          <button 
            type="submit" 
            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-interactive hover:bg-interactive-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-interactive"
          >
            Login
          </button>
        </div>

        <!-- Forgot Password Link -->
        <div class="text-sm text-right">
          <router-link 
            to="/forgot-password"  class="font-medium text-interactive hover:text-interactive-hover">
            Forgot your password?
          </router-link>
        </div>
        
        <!-- Error Message -->
        <p v-if="error" class="mt-2 text-sm text-red-600 text-center">{{ error }}</p>
      </form>
      <!-- Signup Button -->
      <div class="mt-6">
        <router-link 
          to="/signup" 
          class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-interactive bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-interactive"
        >
          Don't have an account? Sign up
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      username: '',
      password: '',
      error: ''
    };
  },
  methods: {
    async login() {
      try {
        const response = await axios.post('api/login/', {
          username: this.username,
          password: this.password
        });
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        // Dispatch login action with user and roles
        this.$store.dispatch('login', response.data);
        
        this.$router.push('/uk-meal-planner'); 
      } catch (error) {
        console.error('Error during login:', error);
        this.error = 'Failed to login. Please check your credentials.';
      }
    }
  }
}
</script>