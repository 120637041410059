<template>
    <div class="bg-white shadow overflow-hidden sm:rounded-lg">
      <div class="px-4 py-5 sm:px-6">
        <h3 class="text-lg leading-6 font-medium text-gray-900">{{ ingredient.name }}</h3>
      </div>
      <div class="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl class="sm:divide-y sm:divide-gray-200">
          <!-- Existing ingredient details -->
          <div v-for="(value, key) in ingredientDetails" :key="key" class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">{{ formatLabel(key) }}</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              {{ formatValue(value, key) }}
            </dd>
          </div>
  
          <!-- Measurements -->
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Measurements</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div class="flex flex-wrap gap-2">
                <button
                  v-for="measurement in measurements"
                  :key="measurement.id"
                  @click="toggleMeasurement(measurement)"
                  :class="[
                    'px-3 py-1 rounded-full text-sm font-medium',
                    ingredient.measurements.some(m => m.id === measurement.id)
                      ? 'bg-orange-200 text-orange-800'
                      : 'bg-gray-200 text-gray-800'
                  ]"
                >
                  {{ measurement.name }}
                </button>
              </div>
            </dd>
          </div>
  
          <!-- Diets -->
          <div class="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt class="text-sm font-medium text-gray-500">Diets</dt>
            <dd class="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div class="flex flex-wrap gap-2">
                <button
                  v-for="diet in diets"
                  :key="diet.id"
                  @click="toggleDiet(diet)"
                  :class="[
                    'px-3 py-1 rounded-full text-sm font-medium',
                    ingredient.diets.some(d => d.id === diet.id)
                      ? 'bg-orange-200 text-orange-800'
                      : 'bg-gray-200 text-gray-800'
                  ]"
                >
                  {{ diet.name }}
                </button>
              </div>
            </dd>
          </div>
  
          <!-- Supermarket Items -->
          <div class="py-4 sm:py-5 sm:px-6">
            <dt class="text-sm font-medium text-gray-500 mb-4">Supermarket Items</dt>
            <dd class="mt-1 text-sm text-gray-900">
              <div v-for="item in ingredientSupermarketItems" :key="item.id" class="mb-4 p-4 border rounded-lg">
                <h4 class="font-medium mb-2">{{ item.supermarket_item.name }} ({{ item.supermarket_item.supermarket.name }})</h4>
                <div class="grid grid-cols-2 gap-2">
                  <div v-for="(value, key) in formatSupermarketItem(item)" :key="key" class="flex justify-between">
                    <span class="text-gray-600">{{ key }}:</span>
                    <span class="font-medium">{{ value }}</span>
                  </div>
                </div>
                <div class="mt-2">
                  <label class="inline-flex items-center">
                    <input type="checkbox" v-model="item.use_default" @change="updateSupermarketItem(item)" class="form-checkbox h-5 w-5 text-blue-600">
                    <span class="ml-2 text-gray-700">Use as default</span>
                  </label>
                </div>
              </div>
            </dd>
          </div>
        </dl>
      </div>
      <div class="px-4 py-3 bg-gray-50 text-right sm:px-6">
        <button
          @click="saveChanges"
          class="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Save Changes
        </button>
        <button
          @click="$emit('close')"
          class="ml-3 inline-flex justify-center py-2 px-4 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          Cancel
        </button>
      </div>
      <div v-if="message" class="px-4 py-3 text-center" :class="{ 'text-green-600': !error, 'text-red-600': error }">
        {{ message }}
      </div>
    </div>
  </template>
  
  <script setup>
import { ref, computed, onMounted } from 'vue';
import axios from 'axios';

const props = defineProps(['ingredient', 'measurements', 'diets']);
const emit = defineEmits(['update', 'close']);

const ingredient = ref({ ...props.ingredient });
const ingredientSupermarketItems = ref([]);
const message = ref('');
const error = ref(false);

const ingredientDetails = computed(() => ({
  default_serving_size: ingredient.value.default_serving_size,
  default_calories_per_serving: ingredient.value.default_calories_per_serving,
  default_fat_per_serving: ingredient.value.default_fat_per_serving,
  default_saturated_fat_per_serving: ingredient.value.default_saturated_fat_per_serving,
  default_carbohydrate_per_serving: ingredient.value.default_carbohydrate_per_serving,
  default_sugar_per_serving: ingredient.value.default_sugar_per_serving,
  default_fibre_per_serving: ingredient.value.default_fibre_per_serving,
  default_protein_per_serving: ingredient.value.default_protein_per_serving,
  default_salt_per_serving: ingredient.value.default_salt_per_serving,
}));

const formatLabel = (key) => {
  return key.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');
};

const formatValue = (value, key) => {
  if (typeof value === 'number') {
    return `${value.toFixed(2)} ${key.includes('serving_size') ? 'g' : ''}`;
  }
  return value;
};

const formatSupermarketItem = (item) => {
  return {
    'Price': `£${item.price.toFixed(2)}`,
    'Total Weight': `${item.total_weight}g`,
    'Serving Size': `${item.serving_size}g`,
    'Calories per Serving': item.calories_per_serving,
    'Protein per Serving': `${item.protein_per_serving}g`,
    'Carbs per Serving': `${item.carbohydrate_per_serving}g`,
    'Fat per Serving': `${item.fat_per_serving}g`,
    'Serving Price': `£${item.serving_price.toFixed(2)}`,
    'Number of Servings': item.number_of_servings,
    'Best Unit': item.best_unit,
    'Confidence': `${item.confidence}%`
  };
};

const toggleMeasurement = (measurement) => {
  const index = ingredient.value.measurements.findIndex(m => m.id === measurement.id);
  if (index > -1) {
    ingredient.value.measurements.splice(index, 1);
  } else {
    ingredient.value.measurements.push(measurement);
  }
};

const toggleDiet = (diet) => {
  const index = ingredient.value.diets.findIndex(d => d.id === diet.id);
  if (index > -1) {
    ingredient.value.diets.splice(index, 1);
  } else {
    ingredient.value.diets.push(diet);
  }
};

const loadSupermarketItems = async () => {
  try {
    const response = await axios.get(`/api/ingredients/${ingredient.value.id}/supermarket_items/`);
    ingredientSupermarketItems.value = response.data;
  } catch (err) {
    console.error('Error loading supermarket items:', err);
    message.value = 'Failed to load supermarket items. Please try again.';
    error.value = true;
  }
};
  
  const saveChanges = async () => {
    try {
      const response = await axios.put(`/api/ingredients/${ingredient.value.id}/`, ingredient.value);
      message.value = 'Changes saved successfully.';
      error.value = false;
      emit('update', response.data);
      setTimeout(() => {
        message.value = '';
        emit('close');
      }, 2000);
    } catch (err) {
      console.error('Error updating ingredient:', err);
      message.value = 'Failed to update ingredient. Please try again.';
      error.value = true;
    }
  };
  onMounted(loadSupermarketItems);
  </script>