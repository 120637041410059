<template>
  <div class="landing-page font-main bg-page-bg bg-[radial-gradient(#aaaaaa44_1px,transparent_1px)] [background-size:22px_22px] relative">
    
    <section class="hero relative pt-12 2xl:pt-20 flex items-center justify-start overflow-hidden pb-20 mb-12">
      
      <!-- Background Image -->
      <img src="../assets/home/bg1.jpg" alt="" class="absolute inset-0 w-full h-full object-cover z-0" />
      
      <!-- Grid Overlay -->
      <div class="absolute inset-0 bg-grid-pattern z-10 pointer-events-none"></div>



      <!-- Curved bottom -->


    <!--
      <img src="../assets/home/hero-bg3.webp" alt="Hero Background" class="absolute inset-0 w-full h-full object-cover object-center z-0 blur-20">
    <div class="right-decor absolute right-0 top-0 bottom-0 w-1/3 opacity-70">
        <div class="food-image-2 absolute right-0 bottom-0">
          <img src="../assets/progress-pictures/paella.png" alt="Prepared meal" class="w-1/2 ">
        </div>
        <div class="food-image-3 absolute bottom-0 left-96">
          <img src="../assets/progress-pictures/full-english.png" alt="Prepared meal" class="w-1/2 ">
        </div>
        <div class="food-image-4 absolute top-2/3 right-4">
          <img src="../assets/progress-pictures/spag-bowl.png" alt="Prepared meal" class="w-1/3 ">
        </div>

  </div>-->

<div class="container relative z-20 text-center px-6 w-full ">

<div class="w-full xl:w-full relative mx-auto">

<h1 class="text-4xl md:text-6xl 2xl:text-[120px] font-main mb-6 text-gray-700 drop-shadow-lg relative py-4 animate-fade-in-up">
  Personalised Meal Plans For Your<br><em class="text-interactive text-4xl md:text-6xl 2xl:text-[140px] "> Health Goals </em>
</h1>
<p class="text-lg md:text-xl 2xl:text-3xl md:w-2/3 text-gray-600 mb-14 mx-auto drop-shadow-lg animate-fade-in">
  Bridging the gap between UK supermaket items and the meals you want to eat. Hit your macros, save money and reduce waste with our smart meal planning algorithm.
</p>
<div class="flex flex-col md:flex-row justify-center gap-6 2xl:mt-32 mt-12 animate-fade-in-up">
  <div class="flex flex-col items-center">
    <button @click="startPlanning" class="w-3/5 md:w-48 lg:w-56 xl:w-64 h-14 text-md md:text-base lg:text-xl xl:text-2xl shadow-in-out bg-interactive border border-s-t hover:bg-interactive-hover hover:border-gray-400 text-white rounded-lg transition-colors flex items-center justify-center">
      Start Planning
    </button>
    <div class="hidden md:flex text-green-600 pt-4">
      <svg class="w-5 h-5 mr-2 text-green-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
      </svg>
      <span class="text-sm text-green-600">7 day free trial</span>
    </div>
  </div>
  <div class="flex flex-col items-center">
    <button @click="scrollToDemo" class="w-3/5 md:w-48 lg:w-56 xl:w-64 h-14 text-md md:text-base lg:text-xl xl:text-2xl shadow-in-out bg-white border border-gray-800 hover:bg-gray-100 hover:border-gray-700 text-black rounded-lg transition-colors flex items-center justify-center">
      Try Generate a Meal
    </button>
    <div class=" md:hidden flex text-green-700 pt-4">
      <svg class="w-5 h-5 mr-2 text-green-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
      </svg>
      <span class="text-sm text-green-600">7 day free trial</span>
    </div>
    <div class=" flex text-green-700 pt-4 ">
      <svg class="w-5 h-5 mr-2 text-green-800" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7"></path>
      </svg>
      <span class="text-sm text-green-600">Supported mobile and desktop</span>
    </div>
    <div class="flex text-green-700 pt-4">
      <span class="text-sm"></span>
    </div>
  </div>
</div>

      <div class="mt-4 text-s-t animate-fade-in h-40">
        <p class="text-2xl text-gray-400 mb-2"> Generated {{ mealPlanCount }} mealplans so far. </p>
      </div>

      </div>
    </div>
    <!-- Construction 
  <div class="absolute right-0 top-1/2 transform -translate-y-1/2 w-1/3 h-2/3 z-30">
    <img src="../assets/home/construction.svg" alt="Construction Animation" class="w-full h-full object-contain">
    <div class="absolute top-0 left-0 w-full h-full flex items-center justify-center text-slate-200">
      <svg class="animate-spin -ml-1 mr-3 h-5 w-5 text-gray-800" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
        <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
      <path class="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
    </svg>
      <span class="text-2xl font-bold text-black ">Animation in Progress!</span>
    </div>
    
  </div>Animation Image -->
  <div class = "absolute -bottom-5 z-20 inset-x-0">
    <div class="my-8 flex justify-center items-center space-x-6 animate-fade-in z-20 ">
      <a href="#" class="text-gray-400 hover:text-interactive transition-colors z-20 ">
        <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
          <path fill-rule="evenodd" d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z" clip-rule="evenodd" />
        </svg>
      </a>
      <a href="#" class="text-gray-400 hover:text-interactive transition-colors z-20">
        <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
          <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
        </svg>
      </a>
      <a href="https://www.instagram.com/mealmatcher_uk/" class="text-gray-400 hover:text-interactive transition-colors z-20">
        <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
          <path fill-rule="evenodd" d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z" clip-rule="evenodd" />
        </svg>
      </a>
      <a href="#" class="text-gray-400 hover:text-interactive transition-colors z-20">
        <svg class="w-8 h-8" fill="currentColor" viewBox="0 0 24 24" aria-hidden="true">
          <path fill-rule="evenodd" d="M12 2C6.477 2 2 6.484 2 12.017c0 4.425 2.865 8.18 6.839 9.504.5.092.682-.217.682-.483 0-.237-.008-.868-.013-1.703-2.782.605-3.369-1.343-3.369-1.343-.454-1.158-1.11-1.466-1.11-1.466-.908-.62.069-.608.069-.608 1.003.07 1.531 1.032 1.531 1.032.892 1.53 2.341 1.088 2.91.832.092-.647.35-1.088.636-1.338-2.22-.253-4.555-1.113-4.555-4.951 0-1.093.39-1.988 1.029-2.688-.103-.253-.446-1.272.098-2.65 0 0 .84-.27 2.75 1.026A9.564 9.564 0 0112 6.844c.85.004 1.705.115 2.504.337 1.909-1.296 2.747-1.027 2.747-1.027.546 1.379.202 2.398.1 2.651.64.7 1.028 1.595 1.028 2.688 0 3.848-2.339 4.695-4.566 4.943.359.309.678.92.678 1.855 0 1.338-.012 2.419-.012 2.747 0 .268.18.58.688.482A10.019 10.019 0 0022 12.017C22 6.484 17.522 2 12 2z" clip-rule="evenodd" />
        </svg>
      </a>
    </div>
</div>
</section>


<section class="how-it-works relative py-20 font-main z-20  ">

  <div class="container mx-auto">
    <div class="text-center text-interactive uppercase mb-4 tracking-wider">How It Works</div>
    
    <div class="container mx-auto px-4">
      <h2 class="text-4xl md:text-5xl mb-12 text-center font-bold text-gray-800 leading-tight">
        Your Journey to Effortless Meal Planning
      </h2>
      <p class="text-center text-xl md:text-2xl text-gray-600 mb-16 max-w-3xl mx-auto">
        1. Take control of your nutrition with our intuitive, step-by-step process.
      </p>
      
    <div class="relative w-full">
      <div 
      ref="journeyCarousel" 
      class="journey-carousel overflow-x-auto snap-x snap-mandatory scrollbar-hide"
      @scroll="handleJourneyScroll"
    >
        <div class="flex justify-center">
          <div class="flex w-full md:grid md:grid-cols-3 2xl:grid-cols-6 mx-auto content-start justify-center">
            <div class="journey-item w-full  sm:w-3/4 md:w-1/3 2xl:w-1/6 flex-shrink-0 snap-center px-2 md:px-3 content-start flex items-start justify-start">
            <div class=" rounded-lg p-6 relative mt-8">
              
              <div class="phone-border mb-1 w-48 md:w-60 h-auto mx-auto rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden">
                <div class="absolute -top-5 left-0 w-12 h-12 bg-gray-800 rounded-full flex items-center justify-center text-white text-xl font-bold shadow-md">
                1
              </div>
                <img src="../assets/home/pantry.webp" alt="Favorite Shops" class="w-full h-full object-cover">
              </div>
              <div class="px-4 rounded-lg h-30 flex flex-col justify-start">
                <h3 class="text-lg 2xl:text-xl font-semibold text-gray-800 mb-2">Update Your Pantry</h3>
                <p class="text-gray-600 text-md 2xl:text-lg">Keep track of what you have on hand. Our system considers your pantry items when planning meals and other tools!</p>
              </div>
            </div>
          </div>
            <div class="journey-item w-full sm:w-3/4 md:w-1/3 2xl:w-1/6 flex-shrink-0 snap-center px-2 md:px-3 content-start flex items-start justify-start">
            <div class=" rounded-lg  p-6 relative mt-8">
              
              <div class="phone-border mb-1 w-48 md:w-60 h-auto mx-auto rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden">
                <div class="absolute -top-5 left-0 w-12 h-12 bg-gray-800 rounded-full flex items-center justify-center text-white text-xl font-bold shadow-md">
                2
              </div>
                <img src="../assets/home/diet_setup.webp" alt="Dietary Preferences" class="w-full h-full object-cover">
              </div>
              <div class="px-4 rounded-lg h-30 flex flex-col justify-start">
                <h3 class="text-lg 2xl:text-xl font-semibold text-gray-800 mb-2">Dietary Preferences</h3>
                <p class="text-gray-600 text-md 2xl:text-lg">Customise your meal plans based on your dietary needs and restrictions.</p>
              </div>
            </div>
          </div>

            <div class="journey-item w-full  sm:w-3/4 md:w-1/3 2xl:w-1/6 flex-shrink-0 snap-center px-2 md:px-3 content-start flex items-start justify-start">
            <div class=" rounded-lg p-6 relative mt-8">
              
              <div class="phone-border mb-1 w-48 md:w-60 h-auto mx-auto rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden">
                <div class="absolute -top-5 left-0 w-12 h-12 bg-gray-800 rounded-full flex items-center justify-center text-white text-xl font-bold shadow-md">
                3
              </div>
                <img src="../assets/home/stores.webp" alt="Favorite Shops" class="w-full h-full object-cover">
              </div>
              <div class="px-4 rounded-lg h-30 flex flex-col justify-start">
                <h3 class="text-lg 2xl:text-xl font-semibold text-gray-800 mb-2">Your Favorite Shops</h3>
                <p class="text-gray-600 text-md 2xl:text-lg">Select your preferred stores for the best deals and products.</p>
              </div>
            </div>
          </div>
          

          <div class="journey-item w-full  sm:w-3/4 md:w-1/3 2xl:w-1/6 flex-shrink-0 snap-center px-2 md:px-3 flex items-start justify-start">
            <div class=" rounded-lg  p-6 relative mt-8">
              
              <div class="phone-border mb-1 w-48 md:w-60 h-auto mx-auto rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden">
                <div class="absolute -top-5 left-0 w-12 h-12 bg-gray-800 rounded-full flex items-center justify-center text-white text-xl font-bold shadow-md">
                4
              </div>
                <img src="../assets/home/macros.webp" alt="Calorie Goals" class="w-full h-full object-cover">
              </div>
              <div class="px-4 rounded-lg h-30 flex flex-col justify-start">
                <h3 class="text-lg 2xl:text-xl font-semibold text-gray-800 mb-2">Calorie Goals</h3>
                <p class="text-gray-600 text-md 2xl:text-lg">Set and track your daily calorie intake for a balanced diet.</p>
              </div>
            </div>
          </div>
          <div class="journey-item w-full  sm:w-3/4 md:w-1/3 2xl:w-1/6 flex-shrink-0 snap-center px-2 md:px-3 flex items-start justify-start">
            <div class=" rounded-lg  p-6 relative mt-8">
              
              <div class="phone-border mb-1 w-48 md:w-60 h-auto mx-auto rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden">
                <div class="absolute -top-5 left-0 w-12 h-12 bg-gray-800 rounded-full flex items-center justify-center text-white text-xl font-bold shadow-md">
                5
              </div>
                <img src="../assets/home/plan_setup.webp" alt="Your Schedule" class="w-full h-full object-cover">
              </div>
              <div class="px-4 rounded-lg h-30 flex flex-col justify-start">
                <h3 class="text-lg 2xl:text-xl font-semibold text-gray-800 mb-2">Plan Settings</h3>
                <p class="text-gray-600 text-md 2xl:text-lg">Decide length of plan, for how many people, and how often meals can repeat.</p>
              </div>
            </div>
            </div>
            <div class="journey-item w-full  sm:w-3/4 md:w-1/3 2xl:w-1/6 flex-shrink-0 snap-center px-2 md:px-3 flex items-start justify-start">
            <div class=" rounded-lg  p-6 relative mt-8">
              
              <div class="phone-border mb-1 w-48 md:w-60 h-auto mx-auto rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden">
                <div class="absolute -top-5 left-0 w-12 h-12 bg-gray-800 rounded-full flex items-center justify-center text-white text-xl font-bold shadow-md">
                6
              </div>
                <img src="../assets/home/week_plan.webp" alt="Calorie Goals" class="w-full h-full object-cover">
              </div>
              <div class="px-4 rounded-lg h-30 flex flex-col justify-start">
                <h3 class="text-lg 2xl:text-xl font-semibold text-gray-800 mb-2">Your Schedule</h3>
                <p class="text-gray-600 text-md 2xl:text-lg">Plan your meals and shopping trips according to your weekly schedule.</p>
              </div>
            </div>
          </div>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Scroll Indicator -->
    <div class="flex justify-center mt-1 md:hidden">
        <div v-for="(_, index) in 6" :key="index" 
             class="w-2 h-2 mx-1 rounded-full transition-all duration-300"
             :class="[activeJourneyItem === index ? 'bg-blue-500 w-4' : 'bg-gray-300']">
        </div>
      </div>
  </div>

    <!-- Divider -->
    <div class="relative my-4 md:my-8 xl:my-16">
      <!-- Desktop SVGs -->
      <div class="hidden md:block">
        <svg width="100%" height="100%" id="svg" preserveAspectRatio="none" viewBox="0 0 1440 390" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150 h-[50vw] min-h-[390px] max-h-[600px]">
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="50%" x2="100%" y2="50%">
            <stop offset="5%" stop-color="#8ed1fc"></stop>
            <stop offset="95%" stop-color="#f78da7"></stop>
          </linearGradient>
        </defs>
        <path d="M 0,400 L 0,100 C 90.2775119617225,96.67942583732057 180.555023923445,93.35885167464114 273,100 C 365.444976076555,106.64114832535886 460.0574162679425,123.24401913875599 562,128 C 663.9425837320575,132.755980861244 773.2153110047848,125.66507177033492 867,116 C 960.7846889952152,106.33492822966508 1039.0813397129186,94.0956937799043 1132,91 C 1224.9186602870814,87.9043062200957 1332.4593301435407,93.95215311004785 1440,100 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient1)" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
        <defs>
          <linearGradient id="gradient2" x1="0%" y1="50%" x2="100%" y2="50%">
            <stop offset="5%" stop-color="#8ed1fc"></stop>
            <stop offset="95%" stop-color="#f78da7"></stop>
          </linearGradient>
        </defs>
        <path d="M 0,400 L 0,233 C 81.70334928229664,238.42583732057415 163.40669856459328,243.85167464114832 256,236 C 348.5933014354067,228.14832535885168 452.07655502392345,207.0191387559809 561,213 C 669.9234449760766,218.9808612440191 784.287081339713,252.07177033492826 883,265 C 981.712918660287,277.92822966507174 1064.7751196172248,270.6937799043062 1155,262 C 1245.2248803827752,253.30622009569376 1342.6124401913876,243.1531100478469 1440,233 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient2)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
      </svg>

      <svg width="100%" height="100%" id="svg" preserveAspectRatio="none" viewBox="0 0 1440 390" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150 h-[50vw] min-h-[390px] max-h-[600px]" style="transform: rotate(180deg);">
        <defs>
          <linearGradient id="gradient3" x1="100%" y1="50%" x2="0%" y2="50%">
            <stop offset="5%" stop-color="#8ed1fc"></stop>
            <stop offset="95%" stop-color="#f78da7"></stop>
          </linearGradient>
        </defs>
        <path d="M 0,400 L 0,100 C 81.13875598086125,99.8755980861244 162.2775119617225,99.75119617224881 273,110 C 383.7224880382775,120.24880382775119 524.0287081339713,140.87081339712918 612,141 C 699.9712918660287,141.12918660287082 735.6076555023925,120.76555023923446 817,115 C 898.3923444976075,109.23444976076554 1025.5406698564593,118.06698564593302 1137,118 C 1248.4593301435407,117.93301435406698 1344.2296650717703,108.96650717703349 1440,100 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient3)" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
        <defs>
          <linearGradient id="gradient4" x1="100%" y1="50%" x2="0%" y2="50%">
            <stop offset="5%" stop-color="#8ed1fc"></stop>
            <stop offset="95%" stop-color="#f78da7"></stop>
          </linearGradient>
        </defs>
        <path d="M 0,400 L 0,233 C 80.61244019138758,207.05741626794259 161.22488038277515,181.11483253588517 246,189 C 330.77511961722485,196.88516746411483 419.7129186602871,238.5980861244019 536,264 C 652.2870813397129,289.4019138755981 795.9234449760766,298.49282296650716 902,281 C 1008.0765550239234,263.50717703349284 1076.5933014354068,219.4306220095694 1160,207 C 1243.4066985645932,194.5693779904306 1341.7033492822966,213.7846889952153 1440,233 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient4)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
      </svg>
      </div>
        <!-- Mobile SVGs -->
        <div class="md:hidden">
          <svg width="100%" height="100%" id="svg" preserveAspectRatio="none" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150 h-[50vw] min-h-[390px] max-h-[600px]">
            <defs>
              <linearGradient id="gradient1" x1="0%" y1="50%" x2="100%" y2="50%">
                <stop offset="5%" stop-color="#8ed1fc"></stop>
                <stop offset="95%" stop-color="#f78da7"></stop>
              </linearGradient>
            </defs>
            <path d="M 0,400 L 0,250 C 90.2775119617225,248.33971291866028 180.555023923445,246.67942583732057 273,250 C 365.444976076555,253.32057416267943 460.0574162679425,261.62200956937804 562,264 C 663.9425837320575,266.377990430622 773.2153110047848,262.832535885167 867,258 C 960.7846889952152,253.167464114833 1039.0813397129186,247.04784688995216 1132,245.5 C 1224.9186602870814,243.95215311004784 1332.4593301435407,246.97607655502392 1440,250 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient1)" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
            <defs>
              <linearGradient id="gradient2" x1="0%" y1="50%" x2="100%" y2="50%">
                <stop offset="5%" stop-color="#8ed1fc"></stop>
                <stop offset="95%" stop-color="#f78da7"></stop>
              </linearGradient>
            </defs>
            <path d="M 0,400 L 0,316.5 C 81.70334928229664,319.21291866028707 163.40669856459328,321.92583732057416 256,318 C 348.5933014354067,314.07416267942584 452.07655502392345,303.50956937799043 561,306.5 C 669.9234449760766,309.49043062200957 784.287081339713,326.03588516746413 883,332.5 C 981.712918660287,338.96411483253587 1064.7751196172248,335.34688995215313 1155,331 C 1245.2248803827752,326.65311004784687 1342.6124401913876,321.5765550239234 1440,316.5 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient2)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
          </svg>

          <svg width="100%" height="100%" id="svg" preserveAspectRatio="none" viewBox="0 0 1440 400" xmlns="http://www.w3.org/2000/svg" class="transition duration-300 ease-in-out delay-150 h-[50vw] min-h-[390px] max-h-[600px]" style="transform: rotate(180deg);">
            <defs>
              <linearGradient id="gradient3" x1="100%" y1="50%" x2="0%" y2="50%">
                <stop offset="5%" stop-color="#8ed1fc"></stop>
                <stop offset="95%" stop-color="#f78da7"></stop>
              </linearGradient>
            </defs>
            <path d="M 0,400 L 0,250 C 81.13875598086125,249.93779904306222 162.2775119617225,249.8755980861244 273,255 C 383.7224880382775,260.1244019138756 524.0287081339713,270.4354066985646 612,270.5 C 699.9712918660287,270.5645933014354 735.6076555023925,260.38277511961724 817,257.5 C 898.3923444976075,254.61722488038276 1025.5406698564593,259.03349282296654 1137,259 C 1248.4593301435407,258.96650717703346 1344.2296650717703,254.48325358851675 1440,250 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient3)" fill-opacity="0.53" class="transition-all duration-300 ease-in-out delay-150 path-0"></path>
            <defs>
              <linearGradient id="gradient4" x1="100%" y1="50%" x2="0%" y2="50%">
                <stop offset="5%" stop-color="#8ed1fc"></stop>
                <stop offset="95%" stop-color="#f78da7"></stop>
              </linearGradient>
            </defs>
            <path d="M 0,400 L 0,316.5 C 80.61244019138758,303.52870813397127 161.22488038277515,290.5574162679426 246,294.5 C 330.77511961722485,298.4425837320574 419.7129186602871,319.29904306220095 536,332 C 652.2870813397129,344.70095693779905 795.9234449760766,349.24641148325356 902,340.5 C 1008.0765550239234,331.75358851674644 1076.5933014354068,309.7346889952153 1160,303.5 C 1243.4066985645932,297.2653110047847 1341.7033492822966,306.8325358851675 1440,316.5 L 1440,400 L 0,400 Z" stroke="none" stroke-width="0" fill="url(#gradient4)" fill-opacity="1" class="transition-all duration-300 ease-in-out delay-150 path-1"></path>
          </svg>
        </div>

      <div class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
        <div class="relative w-80 h-80 md:w-[384px] md:h-[384px] bg-white rounded-full shadow-lg overflow-hidden">
          <div ref="parallaxImage" class="absolute inset-0">
            <img src="../assets/home/algorithm.webp" alt="Code" class="object-none w-auto h-auto max-w-none max-h-none" style="margin: -50%;">
          </div>
          <div class="absolute inset-0 -top-1/4 flex items-center justify-center text-white text-xl 2xl:text-2xl font-bold">
            <p class="bg-black bg-opacity-70 rounded-full px-2">2. Our Algorithm Takes Over</p>
          </div>
        </div>
      </div>
    </div>
    <!-- Your Personalized Plan Section -->
    <div class="container mx-auto px-6 relative overflow-visible">
      <div class="flex items-center justify-center text-gray-800 text-3xl 2xl:text-4xl font-semibold mb-4 md:mb-12"><p>3. What we deliver</p></div>
      <div class="hidden md:block text-center text-interactive uppercase mb-4 pt-12">Interactive Dashboard</div>
      <div class="dashboard-container w-full md:w-3/5 2xl:w-3/5 mx-auto relative" ref="containerRef">
      <!-- Desktop Phones -->
      <div 
        class="hidden md:block absolute -left-32 bottom-1/3 transform origin-bottom-left transition-transform duration-1000 z-20" 
        :style="[ { height: '50%' }]"
        ref="leftPhoneRef"
      >
        <div class="phone-border w-full h-full rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden flex items-center justify-center">
          <img src="../assets/home/shoppinglist.webp" alt="Recipe" class="w-full h-full object-cover">
        </div>
        <svg class="absolute bg-white bg-opacity-40 text-md -top-16 left-0 w-full h-auto" viewBox="0 0 100 20" preserveAspectRatio="xMidYMin meet">
          <defs>
            <filter id="turbulence">
              <feTurbulence type="turbulence" baseFrequency="0.02" numOctaves="2" result="turbulence"/>
              <feDisplacementMap in2="turbulence" in="SourceGraphic" scale="2" xChannelSelector="R" yChannelSelector="G"/>
            </filter>
          </defs>
          <text x="50" y="15" font-size="8" fill="#FF4500" text-anchor="middle" >Interactive Shopping Lists</text>
        </svg>
      </div>

      <div 
        class="hidden md:block absolute -right-32 bottom-1/3 transform origin-bottom-right transition-transform duration-1000 z-20" 
        :style="[ { height: '50%' }]"
        ref="rightPhoneRef"
      >
        <div class="phone-border w-full h-full rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden flex items-center justify-center">
          <img src="../assets/home/recipe.webp" alt="Shopping List" class="w-full h-full object-cover">
        </div>
        <svg class="absolute bg-white bg-opacity-40 text-md -top-16 right-0 w-full h-auto" viewBox="0 0 100 20" preserveAspectRatio="xMidYMin meet">
          <defs>
            <filter id="turbulence2">
              <feTurbulence type="turbulence" baseFrequency="0.02" numOctaves="2" result="turbulence"/>
              <feDisplacementMap in2="turbulence" in="SourceGraphic" scale="2" xChannelSelector="R" yChannelSelector="G"/>
            </filter>
          </defs>
          <text x="50" y="15" font-size="10" fill="#4CAF50" text-anchor="middle" >Recipe Guides</text>
        </svg>
      </div>
      
     

      <!-- Mobile view -->
    <div class="md:hidden relative">
      <div class="flex overflow-x-scroll snap-x snap-mandatory" ref="scrollContainer" @scroll="handleDeliverScroll">
        <!-- Shopping List Phone -->
        <div class="flex-shrink-0 w-full snap-center">
          <div class="phone-border w-48 h-auto mx-auto rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden">
            <img src="../assets/home/shoppinglist.webp" alt="Shopping List" class="w-full h-full object-cover">
          </div>
          <p class=" text-center mt-2 text-sm text-interactive uppercase">Interactive Shopping Lists</p>
          <div class="feature">
          <svg class="w-12 h-12 mx-auto text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
            <h3 class="text-xl font-semibold mt-2">Smart Shopping Lists</h3>
            <p class="text-gray-600 mt-1">Automatically generated lists based on your meal plans. Telling you what specific items to buy from selected stores</p>
          </div>
        </div>

        <!-- Dashboard -->
        <div class="flex-shrink-0 w-full snap-center">
          <div class="phone-border w-48 h-auto mx-auto rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden">
            <img src="../assets/home/phonedash.webp" alt="Recipe" class="w-full h-full object-cover">
          </div>
          <p class="text-center mt-2 text-sm text-interactive uppercase">Detailed Dashboard</p>
          <div class="feature">
          <svg class="w-12 h-12 mx-auto text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path></svg>
          <h3 class="text-xl font-semibold mt-2">Nutrition Tracking</h3>
          <p class="text-gray-600 mt-1">Monitor your macros and nutritional intake effortlessly</p>
        </div>
        </div>

        <!-- Recipe Phone -->
        <div class="flex-shrink-0 w-full snap-center">
          <div class="phone-border w-48 h-auto mx-auto rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden">
            <img src="../assets/home/recipe.webp" alt="Recipe" class="w-full h-full object-cover">
          </div>
          <p class="text-center mt-2 text-sm text-interactive uppercase">Recipe Guides</p>
          <div class="feature">
          <svg class="w-12 h-12 mx-auto text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>
          <h3 class="text-xl font-semibold mt-2">Indepth Recipes</h3>
          <p class="text-gray-600 mt-1">Follow along with your meal plan on phone or desktop!</p>
        </div>
        </div>
      </div>

      <!-- Scroll Indicator -->
      <div class="flex justify-center mt-1">
        <div v-for="(_, index) in 3" :key="index" 
             class="w-2 h-2 mx-1 rounded-full transition-all duration-300"
             :class="[activeDeliverSlider === index ? 'bg-orange-500 w-4' : 'bg-gray-300']">
        </div>
      </div>
      
    </div>

    <!-- Desktop view -->
    <div class="hidden md:block dashboard-frame relative z-10">
      <img src="../assets/home/dash2.webp" alt="UK Meal Planning Dashboard" class="w-full rounded-lg shadow-2xl">
    </div>
      
      <!-- Dashboard features remain unchanged -->
      <div class="hidden md:flex dashboard-features mt-8 grid grid-cols-1 md:grid-cols-3 gap-6 text-center">
        <div class="feature">
          <svg class="w-12 h-12 mx-auto text-blue-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 3h2l.4 2M7 13h10l4-8H5.4M7 13L5.4 5M7 13l-2.293 2.293c-.63.63-.184 1.707.707 1.707H17m0 0a2 2 0 100 4 2 2 0 000-4zm-8 2a2 2 0 11-4 0 2 2 0 014 0z"></path></svg>
          <h3 class="text-xl font-semibold mt-2">Smart Shopping Lists</h3>
          <p class="text-gray-600 mt-1">Automatically generated lists based on your meal plans. Telling you what specific items to buy from selected stores</p>
        </div>
        <div class="feature">
          <svg class="w-12 h-12 mx-auto text-purple-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"></path></svg>
          <h3 class="text-xl font-semibold mt-2">Nutrition Tracking</h3>
          <p class="text-gray-600 mt-1">Monitor your macros and nutritional intake effortlessly</p>
        </div>
        <div class="feature">
          <svg class="w-12 h-12 mx-auto text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"></path></svg>
          <h3 class="text-xl font-semibold mt-2">Indepth Recipes</h3>
          <p class="text-gray-600 mt-1">Follow along with your meal plan on phone or desktop!</p>
        </div>
      </div>
    </div>
  </div>
  </div>
</section>


  <section class="common-challenges py-16" id="common-challenges">
  <h2 class="text-center text-interactive uppercase mb-4">Common Challenges, Our Solution</h2>
  <div class="container mx-auto px-4 flex justify-center">
    
    
    <div class="grid md:grid-cols-2 lg:grid-cols-4 xl:w-4/5 gap-8">
      <!-- Meal Monotony Card -->
      <div class="challenge-card bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
        <div class="p-6">
          <svg class="w-12 h-12 text-interactive mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6V4m0 2a2 2 0 100 4m0-4a2 2 0 110 4m-6 8a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4m6 6v10m6-2a2 2 0 100-4m0 4a2 2 0 110-4m0 4v2m0-6V4"></path>
          </svg>
          <h3 class="text-xl font-semibold text-gray-800 mb-3">Meal Monotony</h3>
          <p class="text-gray-600 mb-4">Break free from repetitive meals with our diverse, tailored recipes.</p>
          <div class="flex items-center justify-between text-sm">
            <span class="text-interactive font-medium">75% increased in meal variety within a month</span>
          </div>
        </div>
      </div>

      <!-- Budget Constraints Card -->
      <div class="challenge-card bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
        <div class="p-6">
          <svg class="w-12 h-12 text-green-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 8h6m-5 0a3 3 0 110 6H9l3 3m-3-6h6m6 1a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <h3 class="text-xl font-semibold text-gray-800 mb-3">Budget Constraints</h3>
          <p class="text-gray-600 mb-4">Eat healthy without breaking the bank using our cost-effective meal plans.</p>
          <div class="flex items-center justify-between text-sm">
            <span class="text-green-500 font-medium">£32 avg. weekly savings</span>
          </div>
        </div>
      </div>

      <!-- Food Waste Card -->
      <div class="challenge-card bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
        <div class="p-6">
          <svg class="w-12 h-12 text-blue-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M19 7l-.867 12.142A2 2 0 0116.138 21H7.862a2 2 0 01-1.995-1.858L5 7m5 4v6m4-6v6m1-10V4a1 1 0 00-1-1h-4a1 1 0 00-1 1v3M4 7h16"></path>
          </svg>
          <h3 class="text-xl font-semibold text-gray-800 mb-3">Food Waste</h3>
          <p class="text-gray-600 mb-4">Optimise ingredient usage and significantly reduce your food waste.</p>
          <div class="flex items-center justify-between text-sm">
            <span class="text-blue-500 font-medium">30% avg. waste reduction</span>
          </div>
        </div>
      </div>

      <!-- Time Management Card -->
      <div class="challenge-card bg-white rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-xl">
        <div class="p-6">
          <svg class="w-12 h-12 text-purple-500 mb-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
          </svg>
          <h3 class="text-xl font-semibold text-gray-800 mb-3">Time Management</h3>
          <p class="text-gray-600 mb-4">Save time with our automated meal plans and shopping lists.</p>
          <div class="flex items-center justify-between text-sm">
            <span class="text-purple-500 font-medium">3.5 hours saved weekly</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="single-meal-generator py-20 bg-gray-100" id="demo-section">
    <div class="container mx-auto px-4">
      <h2 class="text-4xl md:text-5xl mb-8 text-center font-semibold text-gray-700">Try Our Meal Generator</h2>
      <p class="text-xl text-center text-gray-600 mb-12">Get a taste of our meal planning capabilities. Generate a single meal based on your preferences!</p>
      
      <div class="flex flex-col md:flex-row gap-8 max-w-6xl mx-auto">
        <!-- Left column: Input form -->
        <div class="w-full md:w-1/2 bg-white rounded-lg shadow-lg p-8">
          <form @submit.prevent="generateMeal" class="space-y-6">
            <div>
              <label for="calories" class="block text-lg font-medium text-gray-700">Target Calories</label>
              <p class="text-sm text-gray-500">200-1500</p>
              <input type="number" id="calories" v-model="calories" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
            </div>
            
            <div>
              <label for="diet" class="block text-lg font-medium text-gray-700">Diet Preference</label>
              <select id="diet" v-model="diet" class="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500">
                <option value="any">Any</option>
                <option value="vegetarian">Vegetarian</option>
                <option value="vegan">Vegan</option>
                <option value="keto">Keto</option>
                <option value="paleo">Paleo</option>
                <option value="atkins">Atkins</option>
              </select>
            </div>
            
            <div>
              <button 
                type="submit" 
                class="w-full py-3 px-6 rounded-lg text-lg font-semibold transition-colors duration-300"
                :class="[
                  isButtonDisabled 
                    ? 'bg-gray-400 text-gray-600 cursor-not-allowed' 
                    : 'bg-interactive text-white hover:bg-interactive-hover'
                ]"
                :disabled="isButtonDisabled"
              >
                {{ isButtonDisabled ? `Wait ${cooldownTime}s to go again` : 'Generate Meal' }}
              </button>
            </div>
          </form>
        </div>
        
        <!-- Right column: Results display -->
        <div class="w-full md:w-1/2 bg-white rounded-lg shadow-lg p-8">
          <div v-if="!generatedMeal" class="flex flex-col items-center justify-center h-full">
            <img src="../assets/home/questionmark.webp" alt="Question Mark" class="w-32 h-32 mb-4">
            <p class="text-xl text-gray-500 text-center">Enter your preferences and generate a meal to see the results here!</p>
          </div>
          
          <div v-else>
            <img :src="generatedMeal.image" alt="Generated Meal" class="w-full h-48 object-cover rounded-lg mb-4">
            <h3 class="text-2xl font-semibold text-gray-800 mb-4">{{ generatedMeal.name }}</h3>
            
            <div class="mb-6">
              <h4 class="text-lg font-medium text-gray-700 mb-2">Macros:</h4>
              <div class="grid grid-cols-4 gap-4">
                <div class="text-center">
                  <p class="text-sm text-gray-500">Calories</p>
                  <p class="font-semibold">{{ generatedMeal.calories }}</p>
                </div>
                <div class="text-center">
                  <p class="text-sm text-gray-500">Protein</p>
                  <p class="font-semibold">{{ generatedMeal.macros.protein }}g</p>
                </div>
                <div class="text-center">
                  <p class="text-sm text-gray-500">Carbs</p>
                  <p class="font-semibold">{{ generatedMeal.macros.carbs }}g</p>
                </div>
                <div class="text-center">
                  <p class="text-sm text-gray-500">Fat</p>
                  <p class="font-semibold">{{ generatedMeal.macros.fat }}g</p>
                </div>
              </div>
            </div>
            
            <div>
              <h4 class="text-lg font-medium text-gray-700 mb-2">Shopping List:</h4>
              <ul class="list-disc list-inside space-y-1">
                <li v-for="ingredient in generatedMeal.ingredients" :key="ingredient" class="text-gray-600">{{ ingredient }}</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="shopping-list-generator py-20">
  <div class="container mx-auto px-4">
    <h2 class="text-4xl md:text-5xl mb-12 text-center font-bold text-gray-800 leading-tight">
      Smart Shopping List Assistant
    </h2>
    <p class="text-center text-xl md:text-2xl text-gray-600 mb-16 max-w-3xl mx-auto">
      Effortlessly plan your shopping and find the best deals across your favorite stores.
    </p>

    <div class="flex flex-col md:flex-row items-center justify-center gap-8 md:gap-8">
      <!-- Input Section -->
      <div class=" flex flex-col items-center">
        <h3 class="text-2xl font-semibold mb-6">Input Your Details</h3>
        <div class="phone-border mb-4 w-64 md:w-80 h-auto rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden">
          <img src="../assets/home/shopping_list.webp" alt="Shopping list Input" class="w-full h-full object-cover">
        </div>
        <ul class="list-disc list-inside text-gray-600 text-sm md:text-base">
          <li>Choose your favorite stores</li>
          <li>Add your shopping list items</li>
        </ul>
      </div>
      
      <!-- Fun Arrow with Loop -->
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" fill="currentColor" version="1.1" id="Capa_1" width="800px" height="800px" viewBox="0 0 375.01 375.01" xml:space="preserve" class="w-32 h-32 md:w-40 md:h-40 text-interactive transform md:rotate-0 rotate-90">
      <g>
        <g>
          <path d="M330.254,210.966c-56.916,1.224-110.16,25.704-167.076,28.764c-16.524,0.612-33.048-1.224-45.9-8.568    c23.256-4.283,45.288-12.239,61.812-27.54c17.749-15.911,19.584-45.287,8.568-66.095c-10.404-19.584-36.72-20.196-55.08-15.3    C89.125,132.63,59.75,184.65,84.229,221.369c-26.928,1.836-53.856,0-80.172,1.225c-5.508,0.611-5.508,8.567,0.612,8.567    c26.928,1.836,59.364,4.284,91.188,2.448c1.836,1.225,3.672,3.061,5.508,4.284c64.872,45.288,159.732-11.628,229.5-13.464    C338.821,223.817,338.821,210.354,330.254,210.966z M89.737,196.277c-6.732-25.091,15.3-46.511,35.496-56.916    c20.196-10.404,48.96-10.404,55.692,15.912c7.956,30.6-18.36,48.959-43.452,56.916c-11.628,3.672-22.644,6.12-34.272,7.344    C96.47,213.413,92.186,206.069,89.737,196.277z"/>
          <path d="M371.869,211.577c-8.567-5.508-16.523-11.016-24.479-16.523c-6.732-4.896-13.464-10.404-21.42-12.24    c-6.12-1.836-12.24,7.344-6.732,11.627c6.732,4.896,14.076,9.18,20.809,13.464c4.896,3.061,9.792,6.732,14.075,9.792    c-4.896,2.448-9.792,4.284-14.688,6.732c-3.672,1.836-7.956,3.672-11.628,5.508c-1.224,0.612-2.448,1.836-3.061,3.06    c-1.836,2.448-0.611,1.225,0,0.612c-2.447,1.836-2.447,7.956,1.837,7.344l0,0c1.224,0.612,2.447,0.612,4.283,0.612    c4.284-1.224,9.181-3.06,13.464-4.896c9.181-3.673,18.36-7.345,26.929-12.24C376.153,220.758,376.153,214.025,371.869,211.577z"/>
        </g>
      </g>
    </svg>  

      <!-- Output Section -->
      <div class=" flex flex-col items-center">
        <h3 class="text-2xl font-semibold mb-6">Optimised Shopping List</h3>
        <div class="phone-border mb-4 w-64 md:w-80 h-auto rounded-3xl border-8 border-gray-800 bg-gray-200 overflow-hidden">
          <img src="../assets/home/shopping_results.webp" alt="Shopping List Output" class="w-full h-full object-cover">
        </div>
        <ul class="list-disc list-inside text-gray-600 text-sm md:text-base">
          <li>Comprehensive list of items to purchase</li>
          <li>Best price suggestions and store options</li>
        </ul>
      </div>
    </div>

    <!-- Additional Engaging Elements -->
    <div class="flex flex-col items-center mt-16">
      <h3 class="text-2xl font-semibold mb-4">Why Use Our Tool?</h3>
      <ul class="grid grid-cols-1 md:grid-cols-3 gap-8 text-gray-600 text-sm md:text-base">
        <li class="flex items-center">
          <svg class="w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          Save time by quickly compiling your shopping list.
        </li>
        <li class="flex items-center">
          <svg class="w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          Get the best deals from multiple stores in one place.
        </li>
        <li class="flex items-center">
          <svg class="w-6 h-6 mr-2 text-green-500" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
          </svg>
          Reduce waste with a well-planned shopping list.
        </li>
      </ul>
    </div>
  </div>
</section>

  <section class="plans py-16 md:py-24 " id="plans">
    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
      <div class="text-center mb-12">
        <h2 class="text-center text-interactive uppercase mb-4">Choose Your Plan</h2>
        <p class="mt-4 text-xl text-gray-600">Select the option that best fits your needs</p>
      </div>
      
      <div class="mt-12 space-y-4 sm:space-y-0 sm:grid sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 lg:gap-8">
        <!-- Shopping List Plan -->
        <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 bg-white">
          <div class="p-6">
            <div class="flex items-center justify-between">
              <h3 class="text-lg font-medium text-gray-900">Shopping List</h3>
              <span class="px-2 py-1 text-xs font-semibold text-green-800 bg-green-100 rounded-full">New</span>
            </div>
            <p class="mt-4 text-sm text-gray-500">For those who want to optimise their grocery shopping</p>
            <p class="mt-8">
              <span class="text-4xl font-extrabold text-gray-900">£2</span>
              <span class="text-base font-medium text-gray-500">/month</span>
            </p>
            <a href="/uk-meal-planner" class="mt-8 block w-full bg-interactive border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-interactive-hover">Get started</a>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h4 class="text-sm font-medium text-gray-900 tracking-wide uppercase">What's included</h4>
            <ul class="mt-6 space-y-4">
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Advanced shopping list generation</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Price comparison across stores</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- Meal Planner Pro -->
        <div class="border border-black rounded-lg shadow-sm divide-y divide-gray-200 bg-white">
          <div class="p-6">
            <div class="flex items-center justify-between">
              <h3 class="text-lg font-medium text-gray-900">Meal Planner Pro</h3>
              <span class="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-indigo-100 text-indigo-800">Popular</span>
            </div>
            
            <p class="mt-4 text-sm text-gray-500">Comprehensive meal planning for health enthusiasts</p>
            <p class="mt-8">
              <span class="text-4xl font-extrabold text-gray-900">£7</span>
              <span class="text-base font-medium text-gray-500">/month</span>
            </p>
            <a href="/uk-meal-planner" class="mt-8 block w-full bg-interactive border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-interactive-hover">Get started</a>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h4 class="text-sm font-medium text-gray-900 tracking-wide uppercase">What's included</h4>
            <ul class="mt-6 space-y-4">
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">All features from Shopping List plan</span>
              </li>
              <li class="flex space-x-3">
              <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
              </svg>
              <span class="text-sm text-gray-500">Generate 15 entire meal plans per month</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Plan up to 7 days at once</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Choose between 800 and 4000 calories a day</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Specify one macro target (Protien/Fats/Carbs)</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Plan for 1 to 4 people</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Change your schedule everyday</span>
              </li>
            </ul>
          </div>
        </div>

        <!-- Personal Trainer Plan -->
        <div class="border border-gray-200 rounded-lg shadow-sm divide-y divide-gray-200 bg-white">
          <div class="p-6">
            <h3 class="text-lg font-medium text-gray-900">Personal Trainer</h3>
            <p class="mt-4 text-sm text-gray-500">Expert meal planning taken care of for all of your clients!</p>
            <p class="mt-8">
              <span class="text-4xl font-extrabold text-gray-900">£80</span>
              <span class="text-base font-medium text-gray-500">/month</span>
            </p>
            <a href="#" class="mt-8 block w-full bg-indigo-600 border border-transparent rounded-md py-2 text-sm font-semibold text-white text-center hover:bg-indigo-700 curser-disabled">Coming Soon</a>
          </div>
          <div class="pt-6 pb-8 px-6">
            <h4 class="text-sm font-medium text-gray-900 tracking-wide uppercase">What's included</h4>
            <ul class="mt-6 space-y-4">
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">All features from Meal Planner Pro</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Have up to 20 meal plans managed at once</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Automatically email clients there plans and shopping lists</span>
              </li>
              <li class="flex space-x-3">
                <svg class="flex-shrink-0 h-5 w-5 text-green-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                  <path fill-rule="evenodd" d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z" clip-rule="evenodd" />
                </svg>
                <span class="text-sm text-gray-500">Save hours of personalised planning</span>
              </li>
              
            </ul>
          </div>
        </div>
      </div>
    </div>
  </section>

<section class="faq py-16 font-main relative overflow-hidden w-full md:w-4/5 2xl:w-3/5 mx-auto" id="faq">
    <div class="container mx-auto px-4">
      <div class="flex justify-between gap-6">
        <div class="w-full">
          <div class="content">
            <div class="meta text-lg text-interactive uppercase mb-2">FAQ'S</div>
            <h2 class="text-4xl md:text-6xl mb-4 text-gray-800">Frequently Asked Questions</h2>
            <div class="accordion space-y-4">
              <div v-for="(faq, i) in faqs" :key="i" class="accordion-item border border-gray-300 rounded-lg overflow-hidden">
                <input 
                  type="checkbox" 
                  :id="'accordion-item-' + i" 
                  class="accordion-item-toggle hidden" 
                  v-model="faq.open"
                />
                <label 
                  :for="'accordion-item-' + i" 
                  class="accordion-item-title cursor-pointer block text-xl xl:text-2xl text-gray-800 bg-white p-6"
                >
                  {{ faq.title }}
                </label>
                <div v-show="faq.open" class="accordion-item-content p-6 text-lg bg-white">
                  <p class="text-gray-600">{{ faq.description }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="contact-us py-16 w-full md:w-4/5 2xl:w-3/5 mx-auto" id="contact">
  <div class="container mx-auto px-4 max-w-6xl">
    <h2 class="text-center text-interactive uppercase mb-4">Contact Us</h2>
    <p class="text-center text-gray-600 mb-8">Have any questions or need further assistance? Reach out to us at:</p>
    <h2 class="text-center text-interactive uppercase mb-4">info@mealmatcher.co.uk</h2>
  </div>
</section>

  </div>
</template>


<script setup>
import axios from 'axios';
import "swiper/swiper-bundle.css";
import { ref, onMounted, onUnmounted, nextTick, computed } from 'vue';
import { useRouter } from 'vue-router';



const router = useRouter();
const authenticated = computed(() => store.state.authenticated);
const hiddenElements = ref([]);
const navigationPrevRef = ref(null);
const navigationNextRef = ref(null);
const calories = ref(850);
const diet = ref('any');
const generatedMeal = ref(null);
const mealPlanCount = ref(0);
const displayCount = ref(0);
const activeSlider = ref(null);
const cooldownTime = ref(10)
let cooldownInterval = null

const containerRef = ref(null)
const leftPhoneRef = ref(null)
const rightPhoneRef = ref(null)
const isButtonDisabled = ref(false)
const scrollProgress = ref(0)

const updateScrollProgress = (entries) => {
  const [entry] = entries
  if (entry.isIntersecting) {
    const containerHeight = entry.target.clientHeight
    const containerTop = entry.boundingClientRect.top
    const windowHeight = window.innerHeight
    const visibleHeight = Math.min(containerHeight, windowHeight - containerTop)
    scrollProgress.value = visibleHeight / containerHeight
  }
}

onMounted(() => {
  const observer = new IntersectionObserver(updateScrollProgress, {
    threshold: [...Array(101).keys()].map(x => x / 100),
    rootMargin: "0px 0px -50px 0px"  // Start animation 200px before the bottom of the viewport
  })
  
  if (containerRef.value) {
    observer.observe(containerRef.value)
  }
})

onUnmounted(() => {
  // Clean up the observer if needed
})

const rotateAmount = computed(() => {
  // Start at 15 degrees (slightly tilted) and rotate to 90 degrees
  return 25 + (-20 * scrollProgress.value)
})

const leftPhoneStyle = computed(() => ({
  transform: `translate(-30%, 0%) rotate(-${rotateAmount.value}deg)`
}))

const rightPhoneStyle = computed(() => ({
  transform: `translate(30%, 0%) rotate(${rotateAmount.value}deg)`
}))




const activeDeliverSlider = ref(1);  // Start with dashboard active
const scrollContainer = ref(null);

const scrollToMiddle = () => {
  if (scrollContainer.value) {
    const slideWidth = scrollContainer.value.offsetWidth;
    const middleIndex = 1; // Assuming the middle index is 1
    scrollContainer.value.scrollLeft = middleIndex * slideWidth;
  }
};

const handleDeliverScroll = (event) => {
  const scrollPosition = event.target.scrollLeft;
  const slideWidth = event.target.offsetWidth;
  activeDeliverSlider.value = Math.round(scrollPosition / slideWidth);
};

onMounted(() => {
  scrollToMiddle(); // Ensure this is called after the component is mounted
});



const scrollToDemo = () => {
  const demoSection = document.getElementById('demo-section');
  if (demoSection) {
    demoSection.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }
};


const parallaxImage = ref(null)

const handleScroll = () => {
  if (parallaxImage.value) {
    const rect = parallaxImage.value.getBoundingClientRect()
    const windowHeight = window.innerHeight

    if (rect.top < windowHeight && rect.bottom > 0) {
      const scrollPercentage = (windowHeight - rect.top) / (windowHeight + rect.height)
      const moveDistance = 200 // maximum pixels to move
      parallaxImage.value.style.transform = `translateY(${scrollPercentage * moveDistance}px)`
    }
  }
}


onMounted(() => {
  window.addEventListener('scroll', handleScroll)
})

onUnmounted(() => {
  window.removeEventListener('scroll', handleScroll)
  if (cooldownInterval) {
    clearInterval(cooldownInterval);
  }
})



const activeJourneyItem = ref(0);  // Start with the first (leftmost) item
const journeyCarousel = ref(null);

const scrollToStart = () => {
  if (journeyCarousel.value) {
    journeyCarousel.value.scrollLeft = 0;
  }
};

const handleJourneyScroll = (event) => {
  const scrollPosition = event.target.scrollLeft;
  const itemWidth = event.target.offsetWidth;
  activeJourneyItem.value = Math.round(scrollPosition / itemWidth);
};

onMounted(() => {
  scrollToStart();
});



const faqs = ref([
        // Add your FAQ items here
        {
          title: "What is this website about?",
          description:
            "This website is a UK meal planner designed to help you organise your meals efficiently and healthily. It provides recipes, ingredient lists, and more to assist you in your meal planning journey. For those that love to cook.",
          open: false,
        },
        {
          title: "How do I use the meal planner?",
          description:
            "You can start by signing up for an account. Once logged in, navigate to the Meal Planner section where you can create your meal plans, and generate shopping lists.",
          open: false,
        },
        {
          title: "Can I customise my meal plans?",
          description:
            "Absolutely, customise your meal plans according to your dietary preferences and requirements. The planner becomes a whole lot more powerful when you upgrade.",
          open: false,
        },
        {
          title: "Is there a mobile app available?",
          description:
            "Currently, our meal planner is available as a web application. We are working on developing a mobile app to enhance your meal planning experience on the go.",
          open: false,
        },
        {
          title: "What about leftovers?",
          description:
            "This is taken into account, and tried to minimise during generation. But nevertheless there will always be leftovers, which we tell you about.",
          open: false,
        },
        {
          title: "How can I contact support?",
          description:
            "If you need assistance, you can contact our support team at info@mealmatcher.co.uk. We are here to help you with any questions or issues you may have.",
          open: false,
        },
])

onMounted(() => {
  nextTick(() => {
    hiddenElements.value = document.querySelectorAll('.hidden');
    
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('show');
        } else {
          entry.target.classList.remove('show');
        }
      });
    });

    document.querySelectorAll('.timeline-item').forEach(el => observer.observe(el));
  });
  
  fetchMealPlanCount();
  setInterval(fetchMealPlanCount, 600000);
  
  const hiddenElement = document.querySelectorAll('.hi');
  hiddenElement.forEach((el) => observer.observe(el));
});

const generateMeal = async () => {
  try {
    startCooldown();
    const response = await axios.post('api/generate_meal/', { calories: calories.value, diet: diet.value });
    generatedMeal.value = {
      name: response.data.name,
      image: response.data.image,
      ingredients: response.data.ingredients,
      calories: response.data.calories,
      macros: {
        protein: response.data.protien,
        carbs: response.data.carbs,
        fat: response.data.fat
      }
    };
    
  } catch (error) {
    console.error('Failed to generate meal', error);
  }
};


const startPlanning = () => {
  if (authenticated){
    router.push("/uk-meal-planner")
  }else{
    router.push('/signup');
  }
};
const startCooldown = () => {
  isButtonDisabled.value = true;
  cooldownTime.value = 30;
  cooldownInterval = setInterval(() => {
    cooldownTime.value--;
    if (cooldownTime.value <= 0) {
      clearInterval(cooldownInterval);
      isButtonDisabled.value = false;
    }
  }, 1000);
}
const fetchMealPlanCount = async () => {
  try {
    const response = await axios.get(`api/meal-plan-count/`);
    mealPlanCount.value = response.data.count;
    animateCount(displayCount.value, mealPlanCount.value);
  } catch (error) {
    console.error('Failed to fetch meal plan count', error);
  }
};

const animateCount = (start, end) => {
  const duration = 1000;
  const difference = end - start;
  if (difference === 0) {
    return;
  }
  const stepTime = duration / difference;
  let currentCount = start;
  const timer = setInterval(() => {
    currentCount++;
    displayCount.value = currentCount;
    if (currentCount >= end) {
      clearInterval(timer);
    }
  }, stepTime);
};

</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Source+Serif+4:wght@400;600;700&display=swap');
.timeline-item {
  opacity: 0;
  transform: translateY(50px);
  transition: opacity 0.5s, transform 0.5s;
}

.timeline-item.show {
  opacity: 1;
  transform: translateY(0);
}

.fade-enter-active, .fade-leave-active {
  transition: opacity 0.5s ease;
}
.fade-enter-from, .fade-leave-to {
  opacity: 0;
}
.plans {
    position: relative;
  }
/* Inner shadow */
.head-shadow {
  text-shadow: -2px 4px 5px rgba(0, 0, 0, 0.3);
}

.shadow-inner-custom {
  box-shadow: inset 0 4px 8px rgba(255, 255, 255, 0.3);
}
.shadow-in-out {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3), inset 0 4px 8px rgba(255, 255, 255, 0.3);
}
/* Drop shadow */
.drop-shadow-custom {
  box-shadow: 0 5px 5px rgba(0, 0, 0, 0.3);
}
.snap-x {
  scroll-snap-type: x mandatory;
  -webkit-overflow-scrolling: touch;
}
.snap-center {
  scroll-snap-align: center;
}
.container {
  max-width: 100%;
}
.hero, .features, .how-it-works, .testimonials, .call-to-action {
  text-align: center;
}
.feature {
  display: inline-block;
  margin: 20px;
}
footer {
  text-align: center;
  padding: 20px;
}
button {
  transition: all 0.3s;
}
.parallax-image {
  transform: translateY(0);
  transition: transform 0.3s ease-out;
  will-change: transform;
}
@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translateY(-20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 1s ease-out;
}

.animate-fade-in-up {
  animation: fadeInUp 1s ease-out;
}

.animate-fade-in-down {
  animation: fadeInDown 1s ease-out;
}@keyframes float {
  0%, 100% { transform: translateY(0); }
  50% { transform: translateY(-20px); }
}

.animate-float {
  animation: float 3s ease-in-out infinite;
}

.hero-section {
  min-height: 100vh;
}

.left-decor, .right-decor {
  pointer-events: none;
}

.food-image-1, .food-image-2 {
  width: 80%;
  overflow: hidden;
}

.testimonial-bubble {
  max-width: 150px;
}

.icon-1, .icon-2 {
  opacity: 0.7;
}

.animated-ingredient {
  opacity: 0.8;
}
.phone-carousel {
    display: flex;
    overflow-x: auto;
    scroll-snap-type: x mandatory;
    -webkit-overflow-scrolling: touch;
    scrollbar-width: none;
    -ms-overflow-style: none;
    padding: 1rem 0;
  }

  .phone-carousel::-webkit-scrollbar {
    display: none;
  }

  .phone-container {
    flex: 0 0 80%;
    scroll-snap-align: center;
    margin-right: 1rem;
    max-width: 300px; /* Add this line to limit max width */
  }


  .phone-frame {
    position: relative;
    width: 100%;
    padding-bottom: 200%; /* This creates a 1:2 aspect ratio */
    background: #000;
    border-radius: 36px;
    box-shadow: 0 25px 50px -12px rgba(0, 0, 0, 0.25);
    overflow: hidden;
  }

  .phone-screen {
    position: absolute;
    top: 2%;
    left: 2%;
    width: 96%;
    height: 96%;
    object-fit: cover;
    border-radius: 32px;
  }

  @media (min-width: 768px) {
    .phone-carousel {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
      gap: 2rem;
      justify-content: center;
    }

    .phone-container {
      flex: none;
      margin-right: 0;
      width: 80%;
      max-width: 250px; /* Adjust this value as needed */
      justify-self: center;
    }

    .phone-frame {
      padding-bottom: 200%; /* Keep the same aspect ratio */
    }
  }

  @media (min-width: 1024px) {
    .phone-container {
      max-width: 220px; /* Further reduce size on larger screens */
    }
  }
  .parallax-circle {
  transition: transform 0.1s ease-out;
}
.relative {
  position: relative;
}

.algorithm-image {
  object-fit: contain;
  width: auto;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.phone-slider {
  width: 48px; /* Show a small part of the phone */
  overflow: hidden;
  cursor: pointer;
}

@media (min-width: 768px) {
  .phone-slider {
    display: none;
  }
}

.overflow-hidden {
  overflow: hidden;
}
.bg-grid-pattern {
  background-image: linear-gradient(to right, #8585851a 1px, transparent 1px),
                    linear-gradient(to bottom, #74747417 1px, transparent 1px);
  background-size: 35px 35px; /* Adjust the size of the grid */
  min-height: 100vh; /* Full screen height */
  z-index: 10;
}
.hero::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100px; /* Adjust height for curve depth */
    background: #fffdfc; /* No background color */
    border-radius: 100% 100% 0 0 / 100%; /* Create the curve */
    transform: translateY(50%);
    z-index: 10;
    pointer-events: none; /* Ensure it doesn't interfere with interactions */
}
@keyframes loopArrow {
  0%, 100% { transform: translateX(0) rotate(0deg); }
  50% { transform: translateX(10px) rotate(10deg); }
}
.animate-bounce {
  animation: loopArrow 2s infinite;
}
</style>
