<template>
    <div class="container mx-auto px-4 py-8 max-w-5xl">
      <h2 class="text-2xl font-bold mb-4">Cost-Effective Recipes</h2>
      <div v-if="props.loading" class="text-center">
        <p>Loading recipes...</p>
      </div>
      <div v-else-if="props.error" class="text-red-500 text-center">
        {{ props.error }}
      </div>
      <div v-else-if="props.recipes.length === 0" class="text-center">
        <p>No recipes found. Try adjusting your search criteria.</p>
      </div>
      <ul v-else class="space-y-4">
        <li v-for="recipe in props.recipes" :key="recipe[0]" class="border-b pb-4">
          <div class="flex justify-between items-start">
            <div>
              <h3 class="text-xl font-semibold">{{ recipe[1].name }}</h3>
              <p class="text-md text-gray-600">Cost: £{{ recipe[1].cost.toFixed(2) }}</p>
              <p class="text-sm mt-2">
                <span class="font-medium">Ingredients to buy:</span>
                {{ recipe[1].shopping_list.filter(item => item[1] !== 'Leftovers').map(item => item[0][0]).join(', ') }}
              </p>
            </div>
            <button @click="toggleDetails(recipe[0])" class="text-blue-500 hover:text-blue-700 flex items-center">
              {{ expandedRecipes[recipe[0]] ? 'Hide Details' : 'View Details' }}
              <i class="fas" :class="expandedRecipes[recipe[0]] ? 'fa-chevron-up' : 'fa-chevron-down'"></i>
            </button>
          </div>
          <div v-if="expandedRecipes[recipe[0]]" class="mt-4">
            <h4 class="font-medium">Full Shopping List:</h4>
            <ul class="list-disc list-inside">
              <li v-for="item in recipe[1].shopping_list" :key="item[0]">
                {{ item[0][0] }} ({{ item[1] }})
              </li>
            </ul>
          </div>
        </li>
      </ul>
      <button @click="emit('back')" class="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600">
        Back to Settings
      </button>
    </div>
  </template>
  
  <script setup>
  import { ref, defineProps, defineEmits } from 'vue';
  
  const props = defineProps({
    recipes: {
      type: Array,
      required: true
    },
    loading: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: null
    }
  });
  
  const emit = defineEmits(['back']);
  
  const expandedRecipes = ref({});
  
  const toggleDetails = (id) => {
    expandedRecipes.value[id] = !expandedRecipes.value[id];
  };
  </script>