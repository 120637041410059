<template>
    <div class=" min-h-screen bg-[#fffff8] w-full flex justify-center">
        <RecipeList />
    </div>
</template>

<script setup>
import { useStore } from 'vuex';
import RecipeList from './Recipes-components/RecipeList.vue';


const store = useStore();
</script>

<style scoped>
/* Your component-specific styles go here */
</style>