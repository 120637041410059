<!-- ForgotPassword.vue -->
<template>
    <div class="min-h-screen flex items-center justify-center bg-gray-50">
      <div class="w-full max-w-md mx-auto p-5">
        <h1 class="text-3xl mb-6 text-p-t text-center">Forgot Password</h1>
        <form v-if="!resetRequested" class="space-y-6" @submit.prevent="requestReset">
          <div>
            <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
            <input
              class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-interactive focus:border-interactive"
              id="email"
              type="email"
              v-model="email"
              required
            />
          </div>
          <div>
            <button 
              type="submit" 
              class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-interactive hover:bg-interactive-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-interactive"
            >
              Request Password Reset
            </button>
          </div>
          <p v-if="error" class="mt-2 text-sm text-red-600 text-center">{{ error }}</p>
        </form>
        <div v-else class="text-center">
          <p class="text-green-600">Password reset link has been sent to your email.</p>
          <p class="mt-4">Didn't receive the email? <button @click="resetRequested = false" class="text-interactive hover:text-interactive-hover">Try again</button></p>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import axios from 'axios';
  
  export default {
    data() {
      return {
        email: '',
        error: '',
        resetRequested: false
      };
    },
    methods: {
      async requestReset() {
        try {
          await axios.post('api/request-password-reset/', { email: this.email });
          this.resetRequested = true;
          this.error = '';
        } catch (error) {
          console.error('Error requesting password reset:', error);
          this.error = 'Failed to request password reset. Please try again.';
        }
      }
    }
  }
  </script>