<template>
  <div class="bg-white shadow-xl  rounded-lg w-full   ">
    <div class="md:hidden sticky top-0 bg-white z-10 p-4 flex items-center justify-between border-b">
      <h2 class="text-2xl font-bold text-gray-800 truncate">{{ recipe.name }}</h2>
      <button @click="$emit('close')" class=" mr-3 text-gray-400 hover:text-gray-600 transition duration-300">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
      </button>
    </div>
    <div>
      <div class="md:flex md:items-start md:m-6 bg-slate-50 drop-shadow">
        <img :src="recipe.image" :alt="recipe.name" class="w-full md:w-1/3 h-64 object-cover  mb-4 md:mb-0  shadow-md" v-if="recipe.image" />
        <div class="flex-grow md:ml-6 md:mr-2 px-2 md:p-0">
          <div class="hidden md:flex w-full justify-between items-start">
              <h2 class=" text-3xl font-bold text-gray-800 mb-2">{{ recipe.name }}</h2>
              <button @click="$emit('close')" class=" flex-shrink-0 m-3 mr-1 text-gray-400 hover:text-gray-600 transition duration-300">
                <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
            <div class="flex items-center mb-2">
              <div class="flex items-center">
                <template v-for="n in 5" :key="n">
                  <svg @click="rateRecipe(n)" class="w-6 h-6 mr-1 cursor-pointer" :class="{'text-yellow-400': n <= (userRating || Math.round(recipe.average_rating)), 'text-gray-300': n > (userRating || Math.round(recipe.average_rating))}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                    <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                  </svg>
                </template>
              </div>
            <span class="text-sm font-medium text-gray-600 ml-2">
              {{ (userRating || recipe.average_rating).toFixed(1) }} ({{ recipe.ratings.length }} ratings)
            </span>
          </div>

          <div class="flex flex-wrap text-sm text-gray-600">
            <span class="mr-4 mb-2 flex items-center">
              <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path></svg>
              {{ preptime + cooktime }} mins
            </span>
            <span class="mr-4 mb-2 flex items-center">
              <svg class="w-4 h-4 mr-1" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M13 10V3L4 14h7v7l9-11h-7z"></path></svg>
              {{ recipe.complexity }}
            </span>
            <span class="mr-4 mb-2 flex items-center">
              <i class="fas fa-globe-americas mr-1"></i>
              {{ recipe.cuisine.name }}
            </span>
            <span class="mr-4 mb-2 flex items-center">
              <i class="fas fa-utensils mr-1"></i>
              {{ formatMealTypes }}
            </span>

          </div>
          <div >
            <div class="flex flex-wrap gap-2 mb-2">
              <span 
                v-for="(diet, index) in diets" 
                :key="index" 
                class="px-2 py-1 rounded-full text-sm font-semibold text-white bg-green-600 bg-opacity-60"
              >
                {{ diet }}
              </span>
            </div>
          </div>

          <p class="text-gray-700 mb-4">{{ recipe.description }}</p>

          <div class="flex justify-between text-sm text-gray-600 mb-4">
            <span>Calories: <strong>{{ Math.round(calories) }}</strong></span>
            <span>Protein: <strong>{{ Math.round(protein/4) }}g</strong></span>
            <span>Carbs: <strong>{{ Math.round(carbs/4) }}g</strong></span>
            <span>Fat: <strong>{{ Math.round(fat/9) }}g</strong></span>
          </div>
          
        </div>
      </div>


      <div class="px-6 md:flex md:space-x-6">
        <div class="md:w-1/2 mb-6">
          <h3 class="text-xl font-semibold text-gray-800 mb-4">Ingredients</h3>
          <ul class="space-y-3">
            <li v-for="(ingredient, index) in ingredients" :key="index" class="flex items-center">
              <input
                type="checkbox"
                :id="'ingredient-' + index"
                class="form-checkbox h-5 w-5 text-green-500 rounded border-gray-300 focus:ring-green-500 mr-3"
              >
              <label :for="'ingredient-' + index" class="text-gray-700 cursor-pointer flex-grow">
                <span class="font-medium">{{ ingredient.ingredient.name }}</span>
                <span class="text-gray-500 ml-2">
                  {{ ingredient.quantity }} {{ ingredient.unit.name }}
                </span>
              </label>
            </li>
          </ul>
        </div>

        <div class="md:w-1/2 mb-6 md:min-h-2/3">
        <h3 class="text-lg font-semibold text-gray-800 mb-3">Instructions</h3>
        <ol class="space-y-4 list-decimal list-inside">
          <li v-for="(instruction, index) in instructions" :key="index">
            <span class="text-gray-700" v-html="instruction.text"></span>
          </li>
        </ol>
      </div>
      </div>

      <div class="px-6 mb-6">
        <h3 class="text-lg font-semibold text-gray-800 mb-3">Comments</h3>
        <div v-if="recipe.comments && recipe.comments.length" class="space-y-4">
          <div v-for="comment in recipe.comments" :key="comment.id" class="border-b border-gray-200 pb-4">
            <div class="flex items-center mb-2">
              <span class="font-semibold text-gray-800 mr-2">{{ comment.user.username }}</span>
              <span class="text-sm text-gray-600">{{ formatDate(comment.created_at) }}</span>
            </div>
            <p class="text-gray-700">{{ comment.content }}</p>
          </div>
        </div>
        <p v-else class="text-gray-600">No comments yet.</p>
      </div>
      
    </div>
    <!-- Sticky bottom buttons -->
  <div class="sticky hidden md:flex bottom-0 left-0 right-0 border-t bg-slate-50 w-full border-stone-200 justify-around items-center py-4">
    <button @click="$emit('close')"  type="button" class="px-4 py-2 border border-stone-300 rounded-md shadow-sm text-md font-medium text-stone-700 bg-white hover:bg-stone-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 transition duration-300 ease-in-out">
      Close
    </button>
    <button @click="printRecipe" type="button" class="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-md font-medium text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 transition duration-300 ease-in-out">
      Print
    </button>
  </div>
  </div>
  
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits, computed } from 'vue';
import { useStore } from 'vuex'; // Assuming you're using Vuex for state management

const props = defineProps({
  recipe: Object,
  portions: Number,
});

const emit = defineEmits(['close']);

const store = useStore();
const isPremiumUser = computed(() => store.getters.isPremiumUser);
const instructions = ref([]);
const ingredients = ref([]);
const cooktime = ref(0);
const preptime = ref(0);
const fat = ref(0);
const protein = ref(0);
const carbs = ref(0);
const calories = ref(0);
const diets = ref([]);

const image = ref('');
const userRating = ref(null);

const fetchRecipeData = async () => {
  try {
    const response = await fetch(`api/recipe/${props.recipe.id}/details/`, {
      headers: {
        'Authorization': `Bearer ${store.state.token}`
      }
    });
    
    if (!response.ok) {
      if (response.status === 401) {
        console.error('Unauthorized access. Please log in.');
        // Implement redirect to login or show login modal here
        return;
      }
      throw new Error('Network response was not ok');
    }

    const data = await response.json();
    console.log(data);
    instructions.value = data.instructions;
    cooktime.value = data.cook_time;
    preptime.value = data.prep_time;
    userRating.value = data.user_rating;
    image.value = data.image;
    fat.value = data.Fat;
    protein.value = data.Protein;
    carbs.value = data.Carbs;
    calories.value = data.Calories;
    ingredients.value = data.ingredients;
    diets.value = data.Diets;
  } catch (error) {
    console.error('Error fetching instructions:', error);
    instructions.value = 'Failed to load instructions';
  }
};

const rateRecipe = async (rating) => {
  if (!isPremiumUser.value) {
    alert('Only premium users can rate recipes. Upgrade your account to unlock this feature!');
    return;
  }

  try {
    const response = await fetch(`api/recipes/${props.recipe.id}/rate/`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${store.state.token}` // Assuming you store the token in Vuex
      },
      body: JSON.stringify({ rating })
    });

    if (!response.ok) {
      if (response.status === 403) {
        throw new Error('You do not have permission to rate this recipe.');
      }
      throw new Error('Failed to submit rating');
    }

    const data = await response.json();
    userRating.value = rating;
    // Update the recipe's average rating and rating count
    props.recipe.average_rating = data.new_average_rating;
    props.recipe.ratings.length = data.total_ratings;
  } catch (error) {
    console.error('Error rating recipe:', error);
    alert(error.message);
  }
};
const formatDate = (dateString) => {
  return new Date(dateString).toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
};
const printRecipe = () => {
    window.print();
  };
const formatMealTypes = computed(() => {
  return props.recipe.meal_types.map(type => type.name).join(', ');
});
onMounted(() => {
  window.scrollTo(0, 0);
  fetchRecipeData();
});
</script>
<style>
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@700&family=Lato:wght@400;700&display=swap');

.font-serif {
  font-family: 'Playfair Display', serif;
}

body {
  font-family: 'Lato', sans-serif;
}
</style>
