<template>
  <div class="min-h-screen flex items-center justify-center bg-gray-50">
    <div class="w-full max-w-md mx-auto p-5">
      <h1 class="text-3xl mb-6 text-p-t text-center">Sign Up</h1>
      <form class="space-y-6" @submit.prevent="handleSignup">
        <!-- Name -->
        <div>
          <label for="name" class="block text-sm font-medium text-gray-700">Name</label>
          <input
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-interactive focus:border-interactive"
            id="name"
            type="text"
            v-model="user.name"
            required
          />
          <p v-if="errors.name" class="mt-1 text-sm text-red-600">{{ errors.name }}</p>
        </div>

        <!-- Email -->
        <div>
          <label for="email" class="block text-sm font-medium text-gray-700">Email</label>
          <input
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-interactive focus:border-interactive"
            id="email"
            type="email"
            v-model="user.email"
            required
          />
          <p v-if="errors.email" class="mt-1 text-sm text-red-600">{{ errors.email }}</p>
        </div>

        <!-- Username -->
        <div>
          <label for="username" class="block text-sm font-medium text-gray-700">Username</label>
          <input
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-interactive focus:border-interactive"
            id="username"
            type="text"
            v-model="user.username"
            required
          />
          <p v-if="errors.username" class="mt-1 text-sm text-red-600">{{ errors.username }}</p>
        </div>

        <!-- Phone -->
        <div>
          <label for="phone" class="block text-sm font-medium text-gray-700">Phone Number</label>
          <div class="mt-1 flex rounded-md shadow-sm">
            <input
              class="flex-grow block w-full px-3 py-2 border border-gray-300 rounded-l-md focus:outline-none focus:ring-interactive focus:border-interactive"
              id="phone"
              type="tel"
              v-model="user.phone"
              required
            />
            <button 
              type="button" 
              @click="sendVerificationCode" 
              :disabled="!canSendVerification"
              class="inline-flex items-center px-3 py-2 border border-l-0 border-gray-300 rounded-r-md bg-gray-50 text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-1 focus:ring-interactive"
            >
              {{ verificationSent ? 'Resend' : 'Verify' }}
            </button>
          </div>
          <p v-if="errors.phone" class="mt-1 text-sm text-red-600">{{ errors.phone }}</p>
        </div>

        <!-- Verification Code -->
        <div v-if="verificationSent">
          <label for="verificationCode" class="block text-sm font-medium text-gray-700">Verification Code</label>
          <input
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-interactive focus:border-interactive"
            id="verificationCode"
            type="text"
            v-model="user.verificationCode"
            required
          />
          <p v-if="errors.verificationCode" class="mt-1 text-sm text-red-600">{{ errors.verificationCode }}</p>
        </div>

        <!-- Password -->
        <div>
          <label for="password" class="block text-sm font-medium text-gray-700">Password</label>
          <input
            class="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-interactive focus:border-interactive"
            id="password"
            type="password"
            v-model="user.password"
            required
          />
          <p v-if="errors.password" class="mt-1 text-sm text-red-600">{{ errors.password }}</p>
        </div>

        <!-- Submit Button -->
        <div>
          <button 
            type="submit" 
            class="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-interactive hover:bg-interactive-hover focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-interactive"
          >
            Sign Up
          </button>
        </div>

        <!-- General Error Message -->
        <p v-if="errors.general" class="mt-2 text-sm text-red-600 text-center">{{ errors.general }}</p>
      </form>
    </div>
  </div>
</template>

<script>
import axios from 'axios';

export default {
  data() {
    return {
      user: {
        name: '',
        email: '',
        username: '',
        phone: '',
        verificationCode: '',
        password: '',
      },
      verificationSent: false,
      errors: {},
      canSendVerification: true,
      resendTimer: null,
    };
  },
  methods: {
    async sendVerificationCode() {
      if (this.user.phone) {
        try {
          const response = await axios.post('api/send-verification-code/', { phone: this.user.phone });
          this.verificationSent = true;
          this.errors.phone = '';
          this.startResendTimer();
        } catch (error) {
          console.error('Error sending verification code:', error);
          if (error.response && error.response.data && error.response.data.error) {
            this.errors.phone = error.response.data.error;
          } else {
            this.errors.phone = 'Failed to send verification code. Please try again.';
          }
          this.verificationSent = false;
        }
      } else {
        this.errors.phone = 'Phone number is required to send verification code.';
      }
    },
    startResendTimer() {
      this.canSendVerification = false;
      clearTimeout(this.resendTimer);
      this.resendTimer = setTimeout(() => {
        this.canSendVerification = true;
      }, 60 * 1000); // 1 minute
    },
    async handleSignup() {
      this.errors = {};
      try {
        // Check email availability
        const emailResponse = await axios.post('api/check-email/', { email: this.user.email });
        if (!emailResponse.data.available) {
          this.errors.email = 'Email is already in use.';
        }

        // Check username availability
        const usernameResponse = await axios.post('api/check-username/', { username: this.user.username });
        if (!usernameResponse.data.available) {
          this.errors.username = 'Username is already taken.';
        }

        // Check phone availability (new check)
        const phoneResponse = await axios.post('api/check-phone/', { phone: this.user.phone });
        if (!phoneResponse.data.available) {
          this.errors.phone = 'Phone number is already in use.';
        }

        // If there are any errors, stop the signup process
        if (Object.keys(this.errors).length > 0) {
          return;
        }

        // Proceed with signup
        const signupData = { ...this.user };
        const response = await axios.post('api/signup/', signupData);
        
        // Handle successful signup
        localStorage.setItem('access_token', response.data.access);
        localStorage.setItem('refresh_token', response.data.refresh);
        localStorage.setItem('user', JSON.stringify(response.data.user));
        this.$store.dispatch('login', response.data);
        this.$router.push('/select-plan');
      } catch (error) {
        console.error('Error:', error);
        if (error.response && error.response.data) {
          this.errors = { ...this.errors, ...error.response.data };
        } else {
          this.errors.general = 'An error occurred during signup. Please try again.';
        }
      }
    },
  },
  beforeUnmount() {
    clearTimeout(this.resendTimer);
  },
};
</script>