<template >
  <div v-if="showRecipeForm" class="w-full max-w-screen-2xl">
    <RecipeCreateEdit 
      :recipe="editingRecipe"
      :cuisines="cuisines"
      
      :availableIngredients="availableIngredients"
      :mealTypes="mealTypes"
      
      @close="showRecipeForm = false, editingRecipe = null;"
    />
  </div>
  <div v-else-if="showRecipe" class="w-full max-w-screen-2xl">
    <RecipeModel :recipe="selectedRecipe" :portions="4" @close="showRecipe = false" />
  </div>
  <div v-else class="pt-4 w-full mx-auto px-4 md:px-8  max-w-screen-2xl">
    <div class="flex flex justify-around md:justify-between items-center mb-4 md:mx-10">
      <h1 class="text-2xl font-semibold text-gray-800 mb-2 md:mb-0">Recipes</h1>
      <div v-if="authenticated">
        <button
          @click="showRecipeForm = true"
          :disabled="!isFormReady"
          :class="{'opacity-50 cursor-not-allowed': !isFormReady}"
          class="bg-interactive text-white px-3 py-2 rounded-md hover:bg-opacity-90 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
        >
          Create Recipe
        </button>
      </div>
    </div>
    <div class=" bg-white rounded-lg shadow-md" >
      <div class="px-2 py-2">
        <div class="flex flex-col lg:flex-row items-center justify-between gap-4">
          <div class="flex flex-col lg:flex-row items-center space-y-2 lg:space-y-0 lg:space-x-4 w-full lg:w-auto">
            <RecipeFilter @apply-filters="applyFilters" @cuisineTypes="populateCuisine" :mealTypes="mealTypes" class="w-full md:w-auto"/>
            
            <button @click="resetFilters" class="text-sm text-blue-600 hover:text-blue-800 hover:underline focus:outline-none transition duration-150 ease-in-out">
              Reset
            </button>
          </div>
          <div class="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-4 w-full lg:w-auto">
            <input 
              v-model="searchQuery" 
              @input="debounceSearch" 
              placeholder="Search recipes..." 
              class="w-full lg:w-64 border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
            />
            <select 
              v-model="sortOrder" 
              @change="fetchRecipes" 
              class="w-full lg:w-auto border border-gray-300 rounded-md px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition duration-150 ease-in-out"
            >
              <option value="">Sort by</option>
              <option value="name">Name (A-Z)</option>
              <option value="-name">Name (Z-A)</option>
              <option value="-created_at">Newest</option>
              <option value="created_at">Oldest</option>
              <option value="avg_rating">Rating (High to Low)</option>
            </select>
          </div>
        </div>
      </div>
    </div>
    
    <!-- Recipe Grid -->
    <div v-if="recipes.length" class="space-y-8 mt-4">
      <div>
        <div class="flex w-full justify-center md:justify-start items-center ">
          <!-- Toggle for All Recipes / My Recipes -->
        <div class="flex  rounded-lg p-1 m-1">
          <button 
            @click="setActiveFilter('all')" 
            class="px-4 py-2 rounded-md text-sm font-medium focus:outline-none transition-colors duration-200 ease-in-out"
            :class="{ 'bg-white text-gray-800 shadow': activeFilter === 'all', 'text-gray-600 hover:text-gray-800': activeFilter !== 'all' }"
          >
            All Recipes
          </button>
          <button 
            v-if="authenticated"
            @click="setActiveFilter('user')" 
            class="px-4 py-2 rounded-md text-sm font-medium focus:outline-none transition-colors duration-200 ease-in-out"
            :class="{ 'bg-white text-gray-800 shadow': activeFilter === 'user', 'text-gray-600 hover:text-gray-800': activeFilter !== 'user' }"
          >
            My Recipes
          </button>
        </div>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 xl:grid-cols-4 md:gap-6">
          <div v-for="recipe in recipes" :key="recipe.id" class="w-full bg-white rounded-lg flex md:flex-col shadow-md overflow-hidden border border-gray-200 transition duration-300 ease-in-out hover:shadow-lg relative">
            <!-- Edit button (blue pencil icon) -->
            <button v-if="recipe.is_owner" :disabled="!isFormReady"  @click="editRecipe(recipe)" :class="{'opacity-50 cursor-not-allowed': !isFormReady}" class="absolute top-2 right-2 bg-blue-600 hover:bg-blue-700 text-white rounded-full p-2 transition duration-300 ease-in-out z-10">
              <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"></path>
              </svg>
            </button>
            <button v-if="isStaff"  @click="changePhoto(recipe)"  class="absolute top-2 left-2 bg-pink-600 hover:bg-pink-700 text-white rounded-full p-2 transition duration-300 ease-in-out z-10">
              <svg class="w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"></path>
              </svg>
            </button>
            <div class="w-1/5 h-auto md:w-auto md:h-40">
              <img :src="getOptimizedImageUrl(recipe.image)" :alt="recipe.name" class="w-full h-full object-cover"/>
            </div>
            <div class="p-4 w-4/5 md:w-full">
              <h3 class="font-semibold text-md text-gray-800 mb-1 md:mb-2 truncate">{{ recipe.name }}</h3>
              <div class="flex items-center mb-2">
                <div class="flex items-center">
                  <template v-for="n in 5" :key="n">
                    <svg class="w-4 h-4 mr-1" :class="{'text-yellow-400': n <= Math.round(recipe.average_rating), 'text-gray-300': n > Math.round(recipe.average_rating)}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor">
                      <path d="M9.049 2.927c.3-.921 1.603-.921 1.902 0l1.07 3.292a1 1 0 00.95.69h3.462c.969 0 1.371 1.24.588 1.81l-2.8 2.034a1 1 0 00-.364 1.118l1.07 3.292c.3.921-.755 1.688-1.54 1.118l-2.8-2.034a1 1 0 00-1.175 0l-2.8 2.034c-.784.57-1.838-.197-1.539-1.118l1.07-3.292a1 1 0 00-.364-1.118L2.98 8.72c-.783-.57-.38-1.81.588-1.81h3.461a1 1 0 00.951-.69l1.07-3.292z" />
                    </svg>
                  </template>
                </div>
                <span class="text-sm font-medium text-gray-600 ml-1">
                  {{ recipe.average_rating.toFixed(1) }} ({{ recipe.ratings.length }})
                </span>
              </div>
              <div class="flex flex-wrap text-sm text-gray-600 mb-3">
                <span class="mr-3 mb-1"><i class="fas fa-utensils mr-1"></i>{{ recipe.meal_types[0].name }}</span>
                <span class="mr-3 mb-1"><i class="fas fa-globe-americas mr-1"></i>{{ recipe.cuisine.name }}</span>
                <span class="hidden md:block mr-3 mb-1"><i class="fas fa-clock mr-1"></i>{{ recipe.prep_time + recipe.cook_time }} mins</span>
                <span class="hidden md:block"><i class="fas fa-signal mr-1"></i>{{ recipe.complexity }}</span>
              </div>
              <div class="flex space-x-2">
                <button @click="viewRecipe(recipe)" class="flex-1 bg-white border border-gray-300 hover:bg-gray-50 text-gray-700 font-medium py-2 px-4 rounded-md transition duration-300 ease-in-out flex items-center justify-center group">
                  <svg class="w-5 h-5 mr-2 text-gray-500 group-hover:text-gray-700" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6.253v13m0-13C10.832 5.477 9.246 5 7.5 5S4.168 5.477 3 6.253v13C4.168 18.477 5.754 18 7.5 18s3.332.477 4.5 1.253m0-13C13.168 5.477 14.754 5 16.5 5c1.747 0 3.332.477 4.5 1.253v13C19.832 18.477 18.247 18 16.5 18c-1.746 0-3.332.477-4.5 1.253"></path>
                  </svg>
                  View Recipe
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- No Recipes Available -->
    <p v-else-if="!loading" class="text-center text-stone-500 mt-10 font-serif italic">No recipes available at the moment.</p>

    <!-- Loading Indicator -->
    <div v-if="loading" class="text-center mt-10">
      <p class="text-stone-500 font-serif">Preparing the Recipes...</p>
    </div>

    <!-- Load More Button -->
    <div v-if="hasMoreRecipes && !loading" class="text-center mt-8 pb-4">
      <button @click="loadMoreRecipes" class="bg-interactive hover:bg-interactive-hover text-white font-semibold py-3 px-6 rounded-md shadow transition duration-300 ease-in-out">
        Discover More Recipes
      </button>
    </div>
    
    <!-- No More Recipes Indicator -->
    <p v-if="!hasMoreRecipes && recipes.length > 0" class="text-center text-stone-500 mt-10 font-serif italic">You've explored all our culinary creations.</p>

    <div v-if="isStaff" class="fixed bottom-24 right-8 space-y-4">
      <button 
        @click="generateStats"
        class="bg-interactive hover:bg-interactive-hover text-white font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out block"
      >
        Generate Stats
      </button>
      <button 
        @click="toggleIngredientsModal"
        class="bg-interactive hover:bg-interactive-hover text-white font-semibold py-2 px-4 rounded-full transition duration-300 ease-in-out block"
      >
        Manage Ingredients
      </button>
    </div>
    <IngredientsModal 
      v-if="showIngredientsModal"
      :ingredients="availableIngredients"
      @close="toggleIngredientsModal"
      class="z-30 pt-20"
    />
    <!-- Stats Modal -->
    <div v-if="showStatsModal" class="fixed inset-0 bg-black bg-opacity-50 overflow-y-auto h-full w-full z-50 flex items-center justify-center">
      <div class="bg-white rounded-lg shadow-2xl w-full max-w-4xl max-h-[90vh] overflow-y-auto flex flex-col p-6">
        <h2 class="text-2xl font-serif font-bold mb-4 text-stone-800">Recipe Statistics</h2>
        <div v-if="recipeStats">
          <div class="mb-6">
            <h3 class="text-xl font-semibold mb-2">Cuisine Stats</h3>
            <ul>
              <li v-for="stat in recipeStats.cuisine_stats" :key="stat.cuisine__name" class="mb-1">
                {{ stat.cuisine__name }}: {{ stat.count }} recipes
              </li>
            </ul>
          </div>
          <div class="mb-6">
            <h3 class="text-xl font-semibold mb-2">Complexity Stats</h3>
            <ul>
              <li v-for="stat in recipeStats.complexity_stats" :key="stat.complexity" class="mb-1">
                {{ stat.complexity }}: {{ stat.count }} recipes
              </li>
            </ul>
          </div>
          <div>
            <h3 class="text-xl font-semibold mb-2">Ingredient Stats</h3>
            <ul>
              <li v-for="stat in recipeStats.ingredient_stats" :key="stat.ingredient__name" class="mb-1">
                {{ stat.ingredient__name }}: used in {{ stat.count }} recipes
              </li>
            </ul>
          </div>
        </div>
        <button @click="closeStatsModal" class="mt-6 bg-interactive hover:bg-interactive-hover text-white font-semibold py-2 px-4 rounded-md transition duration-300 ease-in-out">
          Close
        </button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, watch, computed  } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';
import RecipeFilter from './RecipeFilter.vue';
import debounce from 'lodash/debounce';
import IngredientsModal from '../manager_stuff/IngredientList.vue';
import RecipeCreateEdit from './RecipeCreateEdit.vue';
import RecipeModel from './RecipeModel.vue';
const authenticated = computed(() => store.state.authenticated);
const showRecipeForm = ref(false);
const showRecipe = ref(false);
const editingRecipe = ref(null);
const showIngredientsModal = ref(false);
const isStaff = computed(() => store.state.user && store.state.user.is_staff);
const store = useStore();
const recipes = ref([]);
const activeFilter = ref('all');

const filters = ref({ mealTypes: [], cuisine: [] });
const page = ref(1);
const loading = ref(false);
const hasMoreRecipes = ref(true);
const itemsPerPage = 12;
const searchQuery = ref('');
const sortOrder = ref('');
const selectedRecipe = ref(null);
const viewRecipe = (recipe) => {
  selectedRecipe.value = recipe;
  console.log('Selected Recipe:', recipe);
  showRecipe.value = true;
};
const toggleIngredientsModal = () => {
  showIngredientsModal.value = !showIngredientsModal.value;
};

const availableIngredients = ref([]); 
const mealTypes = [
  { id: 1, name: 'Breakfast' },
  { id: 2, name: 'Lunch' },
  { id: 3, name: 'Dinner' },
  { id: 4, name: 'Snack' },
  { id: 5, name: 'Dessert' }
];
const cuisines = ref([]);
const populateCuisine = (cuisineTypes) => {
  cuisines.value = cuisineTypes;
};
const isFormReady = computed(() => {
  return cuisines.value.length > 0 && availableIngredients.value.length > 0;
});
const recipeStats = ref(null);

const generateStats = async () => {
  try {
    const response = await axios.get('/api/recipe-stats/', {
      headers: {
        'Authorization': `Bearer ${store.state.token}`
      }
    });
    recipeStats.value = response.data;
    showStatsModal.value = true;
  } catch (error) {
    console.error('Error generating stats:', error);
    // Handle error (e.g., show error message to user)
  }
};


const setActiveFilter = (filter) => {
  activeFilter.value = filter;
  fetchRecipes(true);
};
const showStatsModal = ref(false);

const closeStatsModal = () => {
  showStatsModal.value = false;
};
const fetchRecipes = async (reset = false) => {
  if (loading.value || (!hasMoreRecipes.value && !reset)) return;
  loading.value = true;
  if (reset) {
    page.value = 1;
    recipes.value = [];
  }
  try {
    const response = await axios.get('api/recipes/', {
      params: {
        page: page.value,
        per_page: itemsPerPage,
        meal_types: filters.value.mealTypes.join(','),
        cuisine: filters.value.cuisine.join(','),
        search: searchQuery.value,
        ordering: sortOrder.value,
        user_recipes: activeFilter.value === 'user' ? 'true' : undefined,

      },
      headers: {
        'Authorization': `Bearer ${store.state.token}`
      }
    });
    if (response.status === 200) {
      const newRecipes = response.data.results;
      console.log('Fetched recipes:', newRecipes);
      recipes.value = reset ? newRecipes : [...recipes.value, ...newRecipes];
      page.value++;
      hasMoreRecipes.value = !!response.data.next;
    } else {
      console.error('Failed to fetch recipes');
    }
  } catch (error) {
    console.error('Error fetching recipes:', error);
  } finally {
    loading.value = false;
  }
};

const loadMoreRecipes = () => {
  fetchRecipes();
};
const editRecipe = (recipe) => {
  editingRecipe.value = recipe;
  showRecipeForm.value = true;
};

onMounted(async () => {
  window.scrollTo(0, 0);
  if (true) {
    try {
    const response = await axios.get('/api/ingredients/all');
    console.log(response)
    availableIngredients.value = response.data.map(ingredient => ({
      ...ingredient,
      measurements: ingredient.measurements.map(m => ({ id: m.id, name: m.name }))
    }));
    // sort ingreditns by name
    availableIngredients.value.sort((a, b) => a.name.localeCompare(b.name));
    } catch (error) {
      console.error('Error loading ingredients:', error);
    }
  }
});
const changePhoto = (recipe) => {

  fetch(`api/recipes/${recipe.id}/change-photo/`, {

    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${store.state.token}`
    },
  }).then(response => {
    if (!response.ok) {
      throw new Error('Failed to update recipe status');
    }
    return response.json();
  }).then(data => {
    console.log('Updated recipe status:', data);
  }).catch(error => {
    console.error('Error updating recipe status:', error);
    alert('Failed to update recipe status. Please try again.');
  });
};

const applyFilters = (newFilters) => {
  filters.value = newFilters;
  fetchRecipes(true);
};
const resetFilters = () => {
  filters.value = { mealTypes: [], cuisine: [] };
  fetchRecipes(true);
};
const debounceSearch = debounce(() => {
  fetchRecipes(true);
}, 300);

const getOptimizedImageUrl = (url) => {
  return `${url}?w=300&h=200&fit=crop`;
};

watch(sortOrder, () => {
  fetchRecipes(true);
});

onMounted(() => {
  fetchRecipes();
});
</script>