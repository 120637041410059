<template>
    <div class="bg-white rounded-lg shadow-2xl w-full h-screen overflow-y-auto flex flex-col">
      <div class="p-8 flex-grow overflow-y-auto">
        <div class="w-full flex justify-between">
          <h2 class="text-2xl font-main font-semibold mb-6 text-stone-800">
            {{ recipeForm.name.length > 0 ? recipeForm.name : 'New Recipe' }}
          </h2>
          <button @click="closeRecipeForm" class="hidden md:block absolute top-4 right-4 text-gray-400 hover:text-gray-600 transition duration-300">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
              <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
            </svg>
          </button>
        </div>
        
        <div v-if="errors.non_field_errors" class="mb-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
          {{ errors.non_field_errors.join(', ') }}
        </div>
  
        <form @submit.prevent="saveRecipe" class="space-y-8">
          <!-- Basic Information -->
          <div class="grid grid-cols-1 md:grid-cols-2 gap-6">
            <div class="space-y-2">
              <label for="name" class="block text-sm font-medium text-gray-700">Recipe Name</label>
              <input
                id="name"
                v-model.trim="recipeForm.name"
                type="text"
                required
                :class="['w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', 
                         {'border-red-500': errors.name, 'border-gray-300': !errors.name}]"
                @input="clearError('name')"
              >
              <p v-if="errors.name" class="mt-1 text-sm text-red-600">{{ errors.name.join(', ') }}</p>
            </div>
            <div class="space-y-2">
              <label for="cuisine" class="block text-sm font-medium text-gray-700">Cuisine</label>
              <select
                id="cuisine"
                v-model="recipeForm.cuisine"
                required
                :class="['w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', 
                         {'border-red-500': errors.cuisine, 'border-gray-300': !errors.cuisine}]"
                @change="clearError('cuisine')"
              >
                <option value="">Select Cuisine</option>
                <option v-for="cuisine in cuisines" :key="cuisine.id" :value="cuisine">{{ cuisine.name }}</option>
              </select>
              <p v-if="errors.cuisine" class="mt-1 text-sm text-red-600">{{ errors.cuisine }}</p>
            </div>
          </div>
  
          <div class="space-y-2">
            <label for="description" class="block text-sm font-medium text-gray-700">Description</label>
            <textarea
              id="description"
              v-model="recipeForm.description"
              rows="3"
              :class="['w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', 
                       {'border-red-500': errors.description, 'border-gray-300': !errors.description}]"
              @input="clearError('description')"
            ></textarea>
            <p v-if="errors.description" class="mt-1 text-sm text-red-600">{{ errors.description.join(', ') }}</p>
          </div>
  
          <!-- Time and Complexity -->
          <div class="grid grid-cols-1 md:grid-cols-3 gap-6">
            <div class="space-y-2">
              <label for="prep_time" class="block text-sm font-medium text-gray-700">Prep Time (minutes)</label>
              <input
                id="prep_time"
                v-model.number="recipeForm.prep_time"
                type="number"
                min="0"
                required
                :class="['w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', 
                         {'border-red-500': errors.prep_time, 'border-gray-300': !errors.prep_time}]"
                @input="clearError('prep_time')"
              >
              <p v-if="errors.prep_time" class="mt-1 text-sm text-red-600">{{ errors.prep_time.join(', ') }}</p>
            </div>
            <div class="space-y-2">
              <label for="cook_time" class="block text-sm font-medium text-gray-700">Cook Time (minutes)</label>
              <input
                id="cook_time"
                v-model.number="recipeForm.cook_time"
                type="number"
                min="0"
                required
                :class="['w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', 
                         {'border-red-500': errors.cook_time, 'border-gray-300': !errors.cook_time}]"
                @input="clearError('cook_time')"
              >
              <p v-if="errors.cook_time" class="mt-1 text-sm text-red-600">{{ errors.cook_time.join(', ') }}</p>
            </div>
            <div class="space-y-2">
              <label for="complexity" class="block text-sm font-medium text-gray-700">Complexity</label>
              <select
                id="complexity"
                v-model="recipeForm.complexity"
                required
                :class="['w-full px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500', 
                         {'border-red-500': errors.complexity, 'border-gray-300': !errors.complexity}]"
                @change="clearError('complexity')"
              >
                <option value="simple">Simple</option>
                <option value="intermediate">Intermediate</option>
                <option value="advanced">Advanced</option>
              </select>
              <p v-if="errors.complexity" class="mt-1 text-sm text-red-600">{{ errors.complexity.join(', ') }}</p>
            </div>
          </div>
  
          <!-- Meal Types -->
          <div class="space-y-2 w-full">
            <label class="block text-sm font-medium text-gray-700">Meal Types</label>
            <div class="flex flex-wrap justify-around gap-2">
              <div 
                v-for="mealType in mealTypes"
                :key="mealType.id"
                @click="toggleMealType(mealType)"
                class="cursor-pointer p-2 rounded-md text-sm transition-colors duration-200 ease-in-out"
                :class="isMealTypeSelected(mealType) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'"
              >
                {{ mealType.name }}
              </div>
            </div>
            <p v-if="errors.meal_types" class="mt-1 text-sm text-red-600">{{ errors.meal_types.join(', ') }}</p>
          </div>
  
          <!-- Ingredients -->
          <div class="space-y-4">
            <h3 class="text-lg font-medium text-gray-700">Ingredients</h3>
            
            <table class="w-full border-collapse">
              <thead v-if="recipeForm.ingredients.length > 0">
                <tr class="text-gray-700 text-sm">
                  <th class="p-2"></th>
                  <th class="p-2 text-left">Ingredient</th>
                  <th class="p-2 text-left">Quantity</th>
                  <th class="p-2 text-left">Unit</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(ingredient, index) in recipeForm.ingredients" :key="index" class="border-b">
                  <td class="p-2">
                    <button @click.prevent="removeIngredient(index)" class="text-red-600 hover:text-red-800">
                      <span class="sr-only">Remove</span>
                      <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
                    </button>
                  </td>
                  <td class="p-2">
                    <select 
                      v-model="ingredient.ingredient.id"
                      @change="updateIngredientMeasurements(index)"
                      required 
                      :class="['w-full px-2 py-1 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500',
                               {'border-red-500': errors.ingredients && errors.ingredients[index]?.ingredient, 'border-gray-300': !errors.ingredients || !errors.ingredients[index]?.ingredient}]"
                    >
                      <option value="">Select Ingredient</option>
                      <option v-for="ing in availableIngredients" :key="ing.id" :value="ing.id">{{ ing.name }}</option>
                    </select>
                    <p v-if="errors.ingredients && errors.ingredients[index]?.ingredient" class="mt-1 text-sm text-red-600">
                      {{ errors.ingredients[index].ingredient.join(', ') }}
                    </p>
                  </td>
                  <td class="p-2">
                    <input 
                      v-model.number="ingredient.quantity"
                      type="number"
                      min="0"
                      step="0.1"
                      required 
                      placeholder="Quantity"
                      :class="['w-full px-2 py-1 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500',
                               {'border-red-500': errors.ingredients && errors.ingredients[index]?.quantity, 'border-gray-300': !errors.ingredients || !errors.ingredients[index]?.quantity}]"
                      @input="clearIngredientError(index, 'quantity')"
                    >
                    <p v-if="errors.ingredients && errors.ingredients[index]?.quantity" class="mt-1 text-sm text-red-600">
                      {{ errors.ingredients[index].quantity.join(', ') }}
                    </p>
                  </td>
                  <td class="p-2">
                    <select 
                      v-model="ingredient.unit.id"
                      required 
                      :class="['w-full px-2 py-1 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500',
                               {'border-red-500': errors.ingredients && errors.ingredients[index]?.unit, 'border-gray-300': !errors.ingredients || !errors.ingredients[index]?.unit}]"
                      @change="clearIngredientError(index, 'unit')"
                    >
                      <option value="">Select Unit</option>
                      <option v-for="measurement in ingredient.availableMeasurements" :key="measurement.id" :value="measurement.id">
                        {{ measurement.name }}
                      </option>
                    </select>
                    <p v-if="errors.ingredients && errors.ingredients[index]?.unit" class="mt-1 text-sm text-red-600">
                      {{ errors.ingredients[index].unit.join(', ') }}
                    </p>
                  </td>
                </tr>
              </tbody>
            </table>
            <p v-if="errors.ingredients" class="mt-1 text-sm text-red-600">{{ errors.ingredients.join(', ') }}</p>
            <button @click.prevent="addIngredient" class="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              + Add Ingredient
            </button>
          </div>
          
          <!-- Instructions -->
          <div class="space-y-4">
            <h3 class="text-lg font-medium text-gray-700">Instructions</h3>
            <div v-for="(instruction, index) in recipeForm.instructions" :key="index" class="flex items-start space-x-2">
              <button @click.prevent="removeInstruction(index)" class="mt-2 text-red-600 hover:text-red-800">
                <span class="sr-only">Remove</span>
                <svg class="h-5 w-5" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12"></path></svg>
              </button>
              <textarea 
                v-model="instruction.text"
                required 
                :placeholder="`Step ${index + 1}`"
                rows="2"
                :class="['flex-grow px-3 py-2 border rounded-md shadow-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 resize-y',
                         {'border-red-500': errors.instructions && errors.instructions[index], 'border-gray-300': !errors.instructions || !errors.instructions[index]}]"
                @input="clearInstructionError(index)"
              ></textarea>
            </div>
            <p v-if="errors.instructions" class="mt-1 text-sm text-red-600">{{ errors.instructions.join(', ') }}</p>
            <button @click.prevent="addInstruction" class="px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500">
              Add Instruction
            </button>
          </div>
        </form>
      </div>
      <!-- Fixed bottom buttons -->
      <div class="sticky bottom-0 left-0 right-0 border-t bg-slate-50 border-stone-200 flex justify-around items-center py-4">
      <button @click="closeRecipeForm" type="button" class="px-4 py-2 border border-gray-300 rounded-md shadow-sm text-md font-medium text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 transition duration-300 ease-in-out">
        Cancel
      </button>
      <div class="flex space-x-4">

        <button @click="saveRecipe" type="submit" class="px-4 py-2 border border-transparent text-md font-medium rounded-md text-white bg-amber-600 hover:bg-amber-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-amber-500 transition duration-300 ease-in-out">
          {{ editingRecipe ? 'Update Recipe' : 'Add Recipe' }}
        </button>
      </div>
    </div>
    </div>
  </template>
<script setup>
import { ref, computed, watch, onMounted } from 'vue';
import { useStore } from 'vuex';
import axios from 'axios';

const store = useStore();

const props = defineProps(['recipe', 'cuisines', 'availableIngredients', 'mealTypes']);
const emit = defineEmits(['close']);

const availableIngredients = ref(props.availableIngredients);
const editingRecipe = ref(null);
const availableMeasurements = ref([]);
const errors = ref({});

const recipeForm = ref({
  name: '',
  description: '',
  ingredients: [],
  instructions: [],
  meal_types: [],
  cuisine: { id: null, name: '' },
  complexity: 'simple',
  prep_time: 0,
  cook_time: 0
});

onMounted(() => {
    window.scrollTo(0, 0);
  if (props.recipe) {
    editRecipe(props.recipe);
  }
});

const editRecipe = (recipe) => {
  editingRecipe.value = recipe;
  recipeForm.value = {
    ...recipe,
    cuisine: { id: recipe.cuisine.id, name: recipe.cuisine.name },
    meal_types: recipe.meal_types.map(mt => ({ id: mt.id, name: mt.name })),
    ingredients: recipe.ingredients.map(ing => {
      const availableIng = availableIngredients.value.find(ai => ai.id === ing.ingredient.id);
      const measurement = availableIng ? availableIng.measurements.find(m => m.id === ing.unit.id) : null;
      return {
        ...ing,
        unitName: measurement ? measurement.name : '',
        availableMeasurements: availableIng ? availableIng.measurements : [],
        group: ing.group || '',
        multipliers: ing.multipliers || [1] 
      };
    })
  };
};

const toggleMealType = (mealType) => {
  const index = recipeForm.value.meal_types.findIndex(mt => mt.id === mealType.id);
  if (index === -1) {
    recipeForm.value.meal_types.push(mealType);
  } else {
    recipeForm.value.meal_types.splice(index, 1);
  }
  clearError('meal_types');
};

const isMealTypeSelected = (mealType) => {
  return recipeForm.value.meal_types.some(mt => mt.id === mealType.id);
};

const updateIngredientMeasurements = (index) => {
  const ingredient = recipeForm.value.ingredients[index];
  const availableIng = availableIngredients.value.find(ai => ai.id === ingredient.ingredient.id);
  if (availableIng) {
    ingredient.availableMeasurements = availableIng.measurements;
    if (!availableIng.measurements.some(m => m.id === ingredient.unit.id)) {
      ingredient.unit.id = availableIng.measurements[0].id;
      ingredient.unit.name = availableIng.measurements[0].name;
    }
  } else {
    ingredient.availableMeasurements = [];
  }
  clearIngredientError(index, 'ingredient');
};

const addIngredient = () => {
  recipeForm.value.ingredients.push({
    ingredient: { id: null, name: '' },
    quantity: 0,
    unit: { id: null, name: '' },
    multipliers: [1],
    group: ''
  });
  availableMeasurements.value.push([]);
};

const addInstruction = () => {
  recipeForm.value.instructions.push({ text: '' });
};

const removeInstruction = (index) => {
  recipeForm.value.instructions.splice(index, 1);
};

const removeIngredient = (index) => {
  recipeForm.value.ingredients.splice(index, 1);
};

const closeRecipeForm = () => {
  emit('close');
};

const resetRecipeForm = () => {
  recipeForm.value = {
    name: '',
    description: '',
    ingredients: [],
    instructions: [],
    meal_types: [],
    cuisine: { id: null, name: '' },
    complexity: 'simple',
    prep_time: 0,
    cook_time: 0
  };
  errors.value = {};
};

const clearError = (field) => {
  if (errors.value[field]) {
    delete errors.value[field];
  }
};

const clearIngredientError = (index, field) => {
  if (errors.value.ingredients && errors.value.ingredients[index] && errors.value.ingredients[index][field]) {
    delete errors.value.ingredients[index][field];
  }
};

const clearInstructionError = (index) => {
  if (errors.value.instructions && errors.value.instructions[index]) {
    delete errors.value.instructions[index];
  }
};

const saveRecipe = async () => {
  try {
    let formData = {
      name: recipeForm.value.name,
      description: recipeForm.value.description,
      complexity: recipeForm.value.complexity,
      prep_time: recipeForm.value.prep_time,
      cook_time: recipeForm.value.cook_time,
      ingredients: recipeForm.value.ingredients.map(ingredient => ({
        ingredient: ingredient.ingredient,
        quantity: ingredient.quantity,
        unit: ingredient.unit,
        multipliers: ingredient.multipliers,
        group: ingredient.group
      })),
      instructions: recipeForm.value.instructions.map((instruction, index) => ({
        step_number: index + 1,
        text: instruction.text
      })),
      meal_types: recipeForm.value.meal_types.map(mt => ({
        id: mt.id,
        name: mt.name
      })),
      cuisine: recipeForm.value.cuisine
    };

    if (editingRecipe.value) {
      formData.id = editingRecipe.value.id;
    }

    const config = {
      headers: {
        'Authorization': `Bearer ${store.state.token}`,
        'Content-Type': 'application/json'
      }
    };

    let response;
    if (editingRecipe.value) {
      response = await axios.put(`/api/recipe/${editingRecipe.value.id}/`, formData, config);
    } else {
      response = await axios.post('/api/recipe/', formData, config);
    }

    if (response.status === 200 || response.status === 201) {
      emit('close');
      editingRecipe.value = null;
      resetRecipeForm();
      // You might want to emit an event to refresh the recipe list in the parent component
      emit('recipe-saved', response.data);
    }
  } catch (error) {
    console.error('Error saving recipe:', error);
    if (error.response && error.response.data) {
      errors.value = error.response.data;
      console.error('Server error details:', error.response.data);
    } else {
      errors.value = { non_field_errors: ['An unexpected error occurred. Please try again.'] };
    }
  }
};
</script>