<template>
    <div class="max-w-4xl mx-auto px-4 py-8 pt-4">
      <h1 class="text-3xl font-semibold text-gray-800 mb-6">User Profile</h1>
      
      <div v-if="!authenticated" class="bg-white shadow-md rounded-lg p-8 text-center">
        <h2 class="text-2xl font-semibold mb-4">Welcome to the Meal Planner</h2>
        <p class="text-gray-600 mb-6">To get started, please log in or sign up</p>
        <div class="space-x-4">
          <router-link to="/login" class="bg-blue-600 text-white font-medium rounded-md px-6 py-2 hover:bg-blue-700 transition duration-300">Sign In</router-link>
          <router-link to="/signup" class="bg-green-600 text-white font-medium rounded-md px-6 py-2 hover:bg-green-700 transition duration-300">Sign Up</router-link>
        </div>
      </div>
  
      <div v-else class="bg-white shadow-md rounded-lg overflow-hidden">
        <div class="bg-gray-50 px-6 py-4 border-b border-gray-200">
          <h2 class="text-xl font-semibold text-gray-800">Account Overview</h2>
        </div>
        
        <div class="p-6">
          <div class="grid md:grid-cols-2 gap-8">
            <div>
              <h3 class="text-lg font-semibold text-gray-700 mb-4">Personal Information</h3>
              <div class="space-y-2">
                <p><span class="font-medium">Username:</span> {{ user.username }}</p>
                <p><span class="font-medium">Name:</span> {{ user.name || 'Not provided' }}</p>
                <p><span class="font-medium">Email:</span> {{ user.email }}</p>
                <p><span class="font-medium">Phone:</span> {{ user.phone || 'Not provided' }}</p>
              </div>
            </div>
            
            <div>
              <h3 class="text-lg font-semibold text-gray-700 mb-4">Account Details</h3>
              <div class="space-y-2">
                <p><span class="font-medium">Account Type: </span> 
                  <span :class="user.is_premium ? 'text-green-600' : 'text-blue-600'">
                    {{ user.is_premium ? 'Premium' : 'Standard' }}
                  </span>
                </p>
                <p><span class="font-medium">Meal Tokens:</span> {{ user.meal_tokens }}</p>
                <p><span class="font-medium">Plans Generated:</span> {{ totalMealPlans }}</p>
                <p><span class="font-medium">Member Since:</span> {{ formatDate(user.date_joined) }}</p>
              </div>
            </div>
          </div>
          
          <div class="mt-8 pt-6 border-t border-gray-200">
            <h3 class="text-lg font-semibold text-gray-700 mb-4">Subscription Status</h3>
            <p class="mb-4">
              <span class="font-medium">Current Status:</span>
              <span :class="subscriptionStatusClass">{{ subscriptionStatus }}</span>
            </p>
            <button 
              @click="manageSubscription" 
              class="bg-blue-600 text-white font-medium px-6 py-2 rounded-md hover:bg-blue-700 transition duration-300"
            >
              Manage Subscription
            </button>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, computed } from 'vue';
  import { useStore } from 'vuex';
  import { useRouter } from 'vue-router';
    import axios from 'axios';
  
  const store = useStore();
  const router = useRouter();
  const authenticated = computed(() => store.state.authenticated);
  const subscriton = computed(() => store.getters.isSubscribed);

  const user = ref({});
  const totalMealPlans = ref(0);
  
  onMounted(async () => {
      // Fetch user data from Vuex store or localStorage
      const storedUser = JSON.parse(localStorage.getItem('user'));
      if (storedUser) {
        user.value = storedUser;
      }
  });
  
  const subscriptionStatus = computed(() => {
    if (user.value.subscription_status === 'active') {
      return 'Active';
    } else if (user.value.subscription_status === 'trialing') {
      return 'Trialing';
    } else if (user.value.subscription_status === 'cancelled') {
      return 'Cancelled';
    } else if (user.value.is_premium) {
      return 'Premium (No active subscription)';
    } else {
      return 'Free Plan';
    }
  });
  
  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };
  
  const manageSubscription = async () => {
    try {
      const response = await axios.post('/api/create-customer-portal-session/');
      window.location.href = response.data.url;
    } catch (error) {
      console.error('Error creating customer portal session:', error);
    }
  };
  </script>